import { FC, PropsWithChildren, useRef, useState, useCallback, useEffect, useMemo } from "react";
import { cn } from "@/lib/utils";
import IconSidebar from "../_icons/IconSidebar";
import { useLayout } from "@/hooks/useLayout";

interface SidebarProps extends PropsWithChildren {
  offsetTop?: string;
}

export const Sidebar: FC<SidebarProps> = ({ children, ...props }) => {
  const offsetTop = props.offsetTop ?? "0";
  const defaultWidth = 268;
  const minWidth = 220;
  const maxWidth = 360;
  const breakpoint = 1024;
  const sidebarRef = useRef<HTMLElement>(null);
  const [isHidden, setIsHidden] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(defaultWidth);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { setSidebarState } = useLayout();

  const sidebarCanBeFixed = useMemo(() => {
    return screenWidth >= breakpoint;
  }, [screenWidth]);

  const sidebarStyle = useMemo(() => {
    // sidebar is hidden and should not be visible
    if (isHidden && !isHover) {
      return {
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: sidebarWidth,
        left: -defaultWidth,
        top: 64,
        height: "calc(100dvh - 82px)",
        borderRadius: "0.25rem",
        overflow: "hidden",
        transition: "0.155s cubic-bezier(.36,-0.01,0,.77)",
      };
    }
    // sidebar is hidden and should show as overlay
    if (isHidden && isHover) {
      return {
        minWidth: minWidth,
        maxWidth: maxWidth,
        width: defaultWidth,
        left: 8,
        top: 64,
        height: "calc(100dvh - 82px)",
        borderRadius: "0.25rem",
        overflow: "hidden",
        transition: "0.155s cubic-bezier(.36,-0.01,0,.77)",
      };
    }

    // sidebar is permanently visible
    return {
      minWidth: minWidth,
      maxWidth: maxWidth,
      width: sidebarWidth,
      left: 0,
      top: 0,
      transition: "0s cubic-bezier(.36,-0.01,0,.77)",
    };
  }, [isHidden, isHover, sidebarWidth]);

  function hideSidebar() {
    setIsHidden(true);
    setIsHover(false);
    setSidebarWidth(0);
  }

  function showSidebar() {
    setIsHidden(false);
    setIsHover(false);
    setSidebarWidth(defaultWidth);
  }

  function showSidebarOverlay() {
    setIsHover(true);
  }

  function hideSidebarOverlay() {
    setIsHover(false);
  }

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (event: MouseEvent) => {
      if (!isResizing) {
        return;
      }
      if (!sidebarRef.current) {
        return;
      }

      const translatedWidth = event.clientX - sidebarRef.current.getBoundingClientRect().left;

      // Hide sidebar if we are getting close to the edge
      if (translatedWidth < 50) {
        setIsResizing(false);
        hideSidebar();
        return;
      }

      // otherwise just minimize until minWidth
      const sideBarWidth = Math.min(maxWidth, Math.max(minWidth, translatedWidth));
      setSidebarWidth(sideBarWidth);
    },
    [isResizing],
  );

  const handleWindowResize = () => {
    const { innerWidth: width } = window;

    if (width < breakpoint) {
      hideSidebar();
    }
    setScreenWidth(width);
  };

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    handleWindowResize(); // call initially on first component load
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setSidebarState({
      sidebarWidth: sidebarWidth,
      isFixed: !isHidden,
      isVisible: !isHidden || isHover,
    });
  }, [sidebarWidth, isHidden, isHover]);

  return (
    <div>
      <div style={{ width: isHidden ? 0 : sidebarWidth }}></div>
      {isHidden && isHover && (
        <div
          className="backdrop-blur-sm fixed bottom-0 left-0 right-0 top-0 z-50 bg-white/80 dark:bg-slate-950/80"
          style={{ zIndex: 8 }}
          onMouseEnter={hideSidebarOverlay}
        ></div>
      )}
      <aside
        ref={sidebarRef}
        onMouseDown={(e) => e.preventDefault()}
        className={`fixed right-0 z-10 h-screen border-r border-solid ${isHover ? "shadow-lg border" : ""} `}
        style={sidebarStyle}
      >
        <nav
          className="relative flex h-full min-h-screen shrink-0 grow flex-col bg-background"
          style={{ minHeight: isHidden ? "calc(100dvh - 64px - 18px)" : "100dvh", paddingTop: offsetTop }}
        >
          {children}
        </nav>
        <div
          onMouseDown={startResizing}
          onClick={hideSidebar}
          className={cn([
            "absolute bottom-0 top-0 w-2 cursor-col-resize resize-x hover:border-r-2 hover:border-foreground",
            isResizing ? "border-r-2 border-foreground" : "",
          ])}
          style={{ right: -2 }}
        />
      </aside>
      {isHidden && sidebarCanBeFixed && (
        <div
          className={`fixed left-0 top-0 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onMouseEnter={showSidebarOverlay}
          onClick={showSidebar}
          style={{ top: offsetTop }}
        >
          <IconSidebar className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {isHidden && !sidebarCanBeFixed && (
        <div
          className={`fixed left-0 top-0 z-10 ml-3 flex min-h-[56px] w-6 cursor-pointer flex-row items-center justify-center`}
          onClick={() => (!isHover ? showSidebarOverlay() : hideSidebarOverlay())}
          style={{ top: offsetTop }}
        >
          <IconSidebar className="text-muted-foreground hover:text-foreground" />
        </div>
      )}
      {isHidden && (
        <div
          className={`fixed bottom-0 left-0 top-0 flex h-full flex-col justify-center`}
          style={{ width: isHover ? defaultWidth : 10, zIndex: 8 }}
          onMouseEnter={showSidebarOverlay}
        />
      )}
    </div>
  );
};

export default Sidebar;
