import { FC } from "react";

import { Sheet, SheetMainContent, SheetTopContent } from "@/components/sheet";
import { Button } from "@/components/_ui/button";
import { Header } from "@/components/header";
import { toast } from "@/components/_ui/use-toast";

import IconLinkCopy from "@/components/_icons/IconLinkCopy";

import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";
import { useCurrentUser, useGetObjective } from "@/hooks/api/common";

import { ObjectiveLabelView } from "./label";

import { ObjectiveKeyResultView } from "./key-result";
import { ObjectiveAssigneeView } from "./assignee";
import { ObjectiveStatusView } from "./status";
import { ObjectivePriorityView } from "./priority";

interface ObjectiveSheetProps {
  objective: {
    id: string;
  };
}
export const ObjectiveSheet: FC<ObjectiveSheetProps> = (props) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { currentUser } = useCurrentUser();
  const { objective } = useGetObjective({ id: props.objective.id });

  async function handleCopyObjectiveLink() {
    if (!objective) {
      return;
    }

    const url = `${location.origin}/${currentWorkspace.workspaceUrl}/goals/${objective.id}`;
    await navigator.clipboard.writeText(url);

    if ((await navigator.clipboard.readText()) !== url) {
      toast({
        title: "Fehler",
        description: "Leider ist ein Fehler aufgetreten. Der Link konnte nicht kopiert werden",
        variant: "error",
      });
    }
    toast({
      description: "Der Link zu diesem Ziel wurde erfolgreich kopiert",
      variant: "default",
    });
  }

  if (!currentUser) {
    return <></>;
  }

  return (
    <Sheet userCanResize={true}>
      {/* Top Section of the Sheet */}
      <SheetTopContent>
        <Header className="w-full pr-12">
          <div className="flex flex-row items-center">
            <Button variant="ghost" size="icon" onClick={handleCopyObjectiveLink}>
              <IconLinkCopy className="text-muted-foreground" />
            </Button>
          </div>
          <div className="flex flex-row items-center"></div>
        </Header>
      </SheetTopContent>

      {/* Middle Section of the Sheet */}
      {/* Note: offset for calc(): 56px top, 13px padding */}
      <SheetMainContent className="max-h-[calc(100vh-69px)] overflow-y-scroll">
        {objective && (
          <>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ObjectiveStatusView objective={objective} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ObjectivePriorityView objective={objective} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ObjectiveAssigneeView objective={objective} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ObjectiveKeyResultView objective={objective} workspace={currentWorkspace} />
            </div>
            <div className="flex flex-col pl-2 text-sm text-muted-foreground">
              <ObjectiveLabelView
                user={currentUser}
                workspace={currentWorkspace}
                team={{ id: objective.teamId }}
                objective={objective}
              />
            </div>
          </>
        )}
        {/* <hr/>
        <div className="flex flex-col pl-2 text-muted-foreground">
            <div className="my-5">Wichtige Dokumente und Links</div>
            <div className="h-64"></div>
        </div>
        <hr/>
        <div className="flex flex-col pl-2 text-muted-foreground">
            <div className="my-5">Wichtige Dokumente und Links</div>
            <div className="h-64 bg-red-100"></div>
        </div>
        <hr/>
        <div className="flex flex-col pl-2 text-muted-foreground">
            <div className="my-5">Wichtige Dokumente und Links</div>
            <div className="h-64 bg-red-100"></div>
        </div>  */}
      </SheetMainContent>
    </Sheet>
  );
};

export default ObjectiveSheet;
