import { FC, useCallback, useRef } from "react";

import { useHotkeys } from "react-hotkeys-hook";
import { isMacOs } from "react-device-detect";

import { Input } from "@/components/_ui/input";

import { useObjectiveListState } from "../../hooks";
import IconXCircleFilled from "@/components/_icons/IconXCircleFilled";

export interface ObjectiveListQuickSearchProps {}

export const ObjectiveListQuickSearch: FC<ObjectiveListQuickSearchProps> = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { globalFilterState, fn } = useObjectiveListState();

  const placeholderText = `Suchen (${isMacOs ? "⌘" : "Ctrl"} + F)`;

  const focusInput = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  useHotkeys("mod+f", focusInput, { preventDefault: true });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    fn.setGlobalFilter(e.currentTarget.value);
  };

  const clearInput = () => {
    fn.setGlobalFilter("");
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="group relative">
        <Input
          ref={inputRef}
          value={globalFilterState}
          onChange={handleOnChange}
          placeholder={placeholderText}
          className="w-[200px]rounded-md h-7 border"
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              e.preventDefault();
              clearInput();
            }
          }}
        />
        {globalFilterState !== "" && (
          <div
            className="invisible absolute right-2 top-0.5 translate-y-1/2 rounded-full hover:cursor-pointer group-hover:visible"
            onClick={clearInput}
          >
            <IconXCircleFilled className="h-3 w-3 text-foreground/60" />
          </div>
        )}
      </div>
    </div>
  );
};
