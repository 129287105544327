import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { ButtonHelp } from "@/components/button-help";
import { ButtonTheme } from "@/components/button-theme";
import { AvatarUser } from "../_domain/user/avatar-user";
import { AvatarWorkspace } from "../_domain/workspace/avatar-workspace";
import { Sidebar, SidebarBottomContent, SidebarMainContent, SidebarNavElement, SidebarTopContent } from "../sidebar";

import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";
import { signout } from "@/services/auth.service";

import IconCheckLine from "@/components/_icons/IconCheckLine";
import IconMyGoals from "@/components/_icons/IconMyGoals";

import WorkspaceSelect from "@/routes/WorkspaceSelect";
import { Dialog, DialogContent } from "../_ui/dialog";
import { SidebarAppAllTeamsNavElement } from "./components/sidebar-app-all-teams-nav-element";

import { TeamBrowseAllDialog } from "../_domain/team/team-browse-all-dialog";

import { useCurrentUser } from "@/hooks/api/common";
import { MagnifyingGlassPlusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { SidebarAppFavorites } from "./components/sidebar-app-favorites";

export interface SidebarAppProps {
  workspace: {
    id: string;
  };
}

const SidebarApp = ({ workspace }: SidebarAppProps) => {
  const [dialogisOpen, setDialogisOpen] = useState(false);
  const { currentUser, isSuperUser } = useCurrentUser();
  const { currentWorkspace, hasEditRights } = useCurrentWorkspace();

  const workspaceMembershipList = useMemo(() => {
    if (!currentUser?.workspaceMembershipList) {
      return [];
    }
    return currentUser.workspaceMembershipList;
  }, [currentUser]);

  const makeSettingsUrl = useCallback(
    (path: string) => {
      if (!currentWorkspace) {
        return "";
      }

      return `/${currentWorkspace.workspaceUrl}/settings/${path}`;
    },
    [currentWorkspace],
  );

  function createWorkspaceUrl(path: string) {
    return `/${currentWorkspace.workspaceUrl}/${path}`;
  }

  function handleSignout() {
    signout();
  }

  function hasHiddenWorkspaces() {
    return workspaceMembershipList.filter((w) => w.status === "LEFT").length > 0;
  }

  function handleSelectWorkspace(workspaceUrl?: string) {
    if (!workspaceUrl) {
      return;
    }
    window.location.replace(new URL(`${window.location.origin}/${workspaceUrl}`));
  }

  function handleOnDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  return (
    <Sidebar>
      {/* Top Section of the Sidebar */}
      <SidebarTopContent>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="items-left flex flex-initial select-none flex-row justify-start gap-2 text-left text-xs"
              variant="ghost"
              size="xs"
              style={{ maxWidth: "150px" }}
            >
              <AvatarWorkspace workspace={currentWorkspace} className="h-6 w-6" />
              <span className="max-w-min overflow-hidden text-ellipsis">{currentWorkspace.name}</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {currentUser && (
              <DropdownMenuItem asChild disabled>
                <Link to="" target="_blank">
                  {currentUser.email}
                </Link>
              </DropdownMenuItem>
            )}
            {workspaceMembershipList
              .filter((w) => w.status === "ACTIVE")
              .map((workspaceMembership) => {
                if (!workspaceMembership.workspace) {
                  return <></>;
                }

                return (
                  <DropdownMenuItem
                    className="cursor-pointer"
                    key={workspaceMembership.workspaceId}
                    onClick={() => handleSelectWorkspace(workspaceMembership.workspace?.workspaceUrl)}
                  >
                    <div>
                      <span
                        className="items-left flex flex-initial select-none flex-row items-center justify-start gap-2 pl-4 text-left text-xs"
                        style={{ width: "180px" }}
                      >
                        <AvatarWorkspace className="text-2xs h-6 w-6" workspace={workspaceMembership.workspace} />
                        <span className="overflow-hidden text-ellipsis">{workspaceMembership.workspace.name}</span>
                        <span className="">
                          {workspaceMembership.workspaceId === currentWorkspace.id && (
                            <IconCheckLine className="ml-4" />
                          )}
                        </span>
                      </span>
                    </div>
                  </DropdownMenuItem>
                );
              })}
            {hasHiddenWorkspaces() && (
              <DropdownMenuItem className="cursor-pointer" onClick={() => setDialogisOpen(true)}>
                <span className="ml-4 cursor-pointer text-xxs">Alle Anzeigen</span>
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <Link to={`/${currentWorkspace.workspaceUrl}/settings/workspace/general`}>Workspace Einstellungen</Link>
            </DropdownMenuItem>
            {hasEditRights && (
              <DropdownMenuItem>
                <Link to={`/${currentWorkspace.workspaceUrl}/settings/workspace/members`}>Mitglieder verwalten</Link>
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => {
                handleSignout();
              }}
            >
              Log Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="flex flex-initial grow flex-row text-xs" style={{ minWidth: "8px" }}></div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="flex flex-initial select-none flex-row items-center gap-2 text-xs"
              variant="ghost"
              size="xs"
            >
              <AvatarUser />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {isSuperUser && (
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link to={`/admin`}>Admin Panel</Link>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem className="cursor-pointer" asChild>
              <Link to={`/${currentWorkspace.workspaceUrl}/settings/account/profile`}>Einstellungen</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link to="https://ark-climate.productlane.com/changelog" target="_blank">
                Change Log
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild disabled>
              <Link to="">Dokumentation</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => {
                handleSignout();
              }}
            >
              Log Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        {hasHiddenWorkspaces() && (
          <Dialog open={dialogisOpen} onOpenChange={handleOnDialogOpenChange}>
            <DialogContent className="h-screen w-screen max-w-none">
              <WorkspaceSelect />
            </DialogContent>
          </Dialog>
        )}
      </SidebarTopContent>

      {/* Middle Section of the Sidebar */}
      <SidebarMainContent>
        <SidebarNavElement to={createWorkspaceUrl("my-goals")}>
          <IconMyGoals className="mr-2 h-3 w-3" />
          Meine Ziele
        </SidebarNavElement>
        <div className="mt-4" />

        <SidebarAppFavorites />
        <div className="mt-4" />

        {currentUser && (
          <>
            <SidebarAppAllTeamsNavElement workspace={workspace} user={currentUser} />

            <div className="mt-4" />

            <TeamBrowseAllDialog asChild={true} workspace={workspace} user={currentUser} className="w-full">
              <div className="margin-1 hover: relative cursor-pointer rounded">
                <div className="select-none text-sm">
                  <div className="relative flex h-8 items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0 hover:bg-accent hover:text-accent-foreground ">
                    <MagnifyingGlassPlusIcon className="mr-2 h-3.5 w-3.5" />
                    Team beitreten
                  </div>
                </div>
              </div>
            </TeamBrowseAllDialog>

            <div className="mt-1" />

            <SidebarNavElement to={makeSettingsUrl("new-team")}>
              <PlusIcon className="mr-2 h-3.5 w-3.5" />
              Team hinzufügen
            </SidebarNavElement>
          </>
        )}
      </SidebarMainContent>

      {/* Bottom Section of the Sidebar */}
      <SidebarBottomContent>
        <ButtonHelp />
        <ButtonTheme />
      </SidebarBottomContent>
    </Sidebar>
  );
};

export default SidebarApp;
