import { useMutation } from "@apollo/client";
import {
  createPriorityInstanceMutationDocument,
  getObjectiveQueryDocument,
  getPriorityInstanceQueryDocument,
  getPriorityInstancebyOwnerQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

export interface UseCreatePriorityInstanceProps {
  ownerId: string;
  ownerType: "OBJECTIVE";
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreatePriorityInstance = ({
  ownerId,
  ownerType,
  onCompleted,
  onError,
}: UseCreatePriorityInstanceProps) => {
  const ownerQueryDocument = useMemo(() => {
    if (ownerType === "OBJECTIVE") {
      return {
        query: getObjectiveQueryDocument,
        variables: { input: { id: ownerId } },
      };
    }
    return null;
  }, [ownerId, ownerType]);

  const refetchQueries = useMemo(() => {
    if (ownerQueryDocument) {
      return [ownerQueryDocument];
    }
    return [];
  }, [ownerQueryDocument]);

  const [createPriorityInstance] = useMutation(createPriorityInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    update: (cache, { data }) => {
      const priorityInstance = data?.createPriorityInstance;
      if (!priorityInstance) {
        return;
      }
      cache.writeQuery({
        query: getPriorityInstanceQueryDocument,
        variables: {
          input: { id: priorityInstance.id },
        },
        data: {
          priorityInstance: priorityInstance,
        },
      });
      cache.writeQuery({
        query: getPriorityInstancebyOwnerQueryDocument,
        variables: {
          input: { ownerId: priorityInstance.ownerId },
        },
        data: {
          priorityInstanceByOwner: priorityInstance,
        },
      });
    },
    refetchQueries: refetchQueries,
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createPriorityInstance,
  };
};
