import { useContext } from "react";
import { ThemeContext, ThemeAppearance } from "@/hooks/useTheme";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Button } from "@/components/_ui/button";

import IconSunSolid from "@/components/_icons/IconSunSolid";
import IconMoonSolid from "./_icons/IconMoonSolid";
import IconCircleHalf from "./_icons/IconCircleHalf";

export function ButtonTheme() {
  const { themeAppearance, setThemeAppearance } = useContext(ThemeContext);

  function selectedIcon() {
    if (themeAppearance === ThemeAppearance.light) {
      return <IconSunSolid className="h-3 w-3" />;
    }
    if (themeAppearance === ThemeAppearance.dark) {
      return <IconMoonSolid className="h-3 w-3" />;
    }
    return <IconCircleHalf className="h-3 w-3" />;
  }

  function handleClick(selectedTheme: ThemeAppearance) {
    setThemeAppearance(selectedTheme);
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex flex-initial flex-row items-center gap-2 text-xs">
          <Button variant="outline" size="icon" className="select-none rounded-full">
            {selectedIcon()}
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-popover">
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => {
            handleClick(ThemeAppearance.light);
          }}
        >
          <a className="relative flex items-center overflow-hidden text-ellipsis hover:bg-accent hover:text-accent-foreground">
            <IconSunSolid className="mr-2 h-3 w-3" />
            Light Mode
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => {
            handleClick(ThemeAppearance.dark);
          }}
        >
          <a className="relative flex items-center overflow-hidden text-ellipsis hover:bg-accent hover:text-accent-foreground">
            <IconMoonSolid className="mr-2 h-3 w-3" />
            Dark Mode
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => {
            handleClick(ThemeAppearance.auto);
          }}
        >
          <a className="relative flex items-center overflow-hidden text-ellipsis hover:bg-accent hover:text-accent-foreground">
            <IconCircleHalf className="mr-2 h-3 w-3" />
            Automatisch
          </a>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
