import { FC } from "react";

import { Button } from "@/components/_ui/button";
import {
  useCreatePriorityInstance,
  useDeletePriorityInstance,
  useGetObjective,
  useGetPriorityInstance,
  useUpdatePriorityInstance,
} from "@/hooks/api/common";
import { PrioritySelectDropdown, PriorityViewOptional } from "@/components/_domain/priority";

interface ObjectivePriorityViewProps {
  objective: {
    id: string;
  };
}

export const ObjectivePriorityView: FC<ObjectivePriorityViewProps> = ({ ...props }) => {
  const { objective } = useGetObjective(props.objective);
  const { priorityInstance } = useGetPriorityInstance({ id: objective?.priorityInstance?.id });
  const { updatePriorityInstance } = useUpdatePriorityInstance({}); // always prefer this one
  const { createPriorityInstance } = useCreatePriorityInstance({ ownerId: props.objective.id, ownerType: "OBJECTIVE" });
  const { deletePriorityInstance } = useDeletePriorityInstance({ ownerId: props.objective.id, ownerType: "OBJECTIVE" });

  function assignPriority(priority: { id: string }) {
    if (!objective) {
      return;
    }

    if (priorityInstance) {
      updatePriorityInstance({
        variables: {
          input: {
            id: priorityInstance.id,
            priorityId: priority.id,
          },
        },
      });
    } else {
      createPriorityInstance({
        variables: {
          input: {
            priorityId: priority.id,
            ownerId: props.objective.id,
            ownerType: "OBJECTIVE",
            teamId: objective.teamId,
          },
        },
      });
    }
  }

  function removePriority() {
    if (!priorityInstance) {
      return;
    }
    deletePriorityInstance({
      variables: { input: { id: priorityInstance.id } },
    });
  }

  const handleOnPrioritySelect = (priority: { id: string } | null) => {
    if (!priority) {
      return removePriority();
    }
    assignPriority(priority);
  };

  if (!objective) {
    return <></>;
  }

  return (
    <div className="mb-5 flex w-full flex-row items-start gap-2 overflow-x-hidden">
      <div className="mt-2 w-24 shrink-0 font-medium">Priorität</div>
      <div className="grow">
        <PrioritySelectDropdown
          team={{ id: objective.teamId }}
          onSelect={handleOnPrioritySelect}
          selected={priorityInstance}
          asChild
        >
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start overflow-x-hidden font-light ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          >
            <PriorityViewOptional priority={priorityInstance ? { id: priorityInstance.priorityId } : null} />
          </Button>
        </PrioritySelectDropdown>
      </div>
    </div>
  );
};

export default ObjectivePriorityView;
