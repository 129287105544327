import { FC } from "react";
import { ObjectiveListDisplayOptionsButton } from "./objective-list-display-options-button";
import { ObjectiveListFilterButton } from "./objective-list-filter-button";

export interface ObjectiveListConfigurationViewProps {}

export const ObjectiveListConfigurationView: FC<ObjectiveListConfigurationViewProps> = () => {
  return (
    <div className="max-w-screen relative flex h-[45px] shrink-0 items-center justify-end gap-2 border-b border-solid px-6 text-sm">
      <div className="flex flex-row items-center">
        <ObjectiveListFilterButton />
      </div>
      <div className="flex flex-row items-center  gap-2">
        <ObjectiveListDisplayOptionsButton />
      </div>
    </div>
  );
};
