import { FC, useEffect, useMemo, useState } from "react";

import { cn } from "@/lib/utils";

import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { Checkbox } from "@/components/_ui/checkbox";

import {
  FilterByIdList,
  FilterByIdPayload,
  FilterByIdPayloadSchema,
} from "@/components/_domain/objective/objective-list-overview/filter-functions";
import { useObjectiveListState } from "@/components/_domain/objective/hooks";

import { LabelView } from "@/components/_domain/labels";

import { useGetLabelListForTeamsInWorkspace } from "@/hooks/api/common";

export interface FilterByLabelGroupConfigurationViewProps {
  workspace: {
    id: string;
  };
  labelGroup: {
    id: string;
  };
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByLabelGroupConfigurationView: FC<FilterByLabelGroupConfigurationViewProps> = ({
  workspace,
  labelGroup,
  onFinished,
  ...props
}) => {
  const { table, columnFilters, config } = useObjectiveListState();
  const { labelListGroupedByGroupId } = useGetLabelListForTeamsInWorkspace({
    workspaceId: workspace.id,
    userId: config.user.id,
    teamList: config.teamList,
  });

  const labelList = useMemo(() => {
    return labelListGroupedByGroupId.get(labelGroup.id) ?? [];
  }, [labelListGroupedByGroupId]);

  const [selectedIds, setSelectedIds] = useState<FilterByIdList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(props.column.id);
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByIdPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedIds(filterValue.idList);
  }, []);

  useEffect(() => {
    if (!selectedIds) {
      return;
    }

    if (selectedIds.length === 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_id",
      mode: filterValue?.mode ?? "included",
      idList: selectedIds,
    });
  }, [selectedIds]);

  const setFilter = (payload: FilterByIdPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleSelect = (labelId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== labelId);
    setSelectedIds([...updatedIdList, labelId]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (labelId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== labelId);

    setSelectedIds(updatedIdList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command>
      <CommandInput placeholder={"Label"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedIds && !!selectedIds.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <LabelView label={null} />
              </CommandItem>
            );
          })}

          {labelList.map((label) => {
            const isSelected = !!selectedIds && !!selectedIds.find((id) => id === label.id);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={label.id}
                value={label.id}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(label.id, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(label.id);
                  }}
                />
                <LabelView label={label} />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
