import { gql, useMutation, useQuery, ApolloError } from "@apollo/client";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactNode,
  FC,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";

import { EMPTY_FILTERS_DATA } from "@/lib/consts/emptyFiltersData";

const CREATE_SAVED_VIEW = gql(`
  mutation CreateSavedView($createSavedViewInput: CreateSavedViewInput!) {
    createSavedView(createSavedViewInput: $createSavedViewInput) {
      id
      name
      filterData
      isDefault
      url
    }
  }
`);

const UPDATE_SAVED_VIEW = gql(`
  mutation UpdateSavedView($updateSavedViewInput: UpdateSavedViewInput!) {
    updateSavedView(updateSavedViewInput: $updateSavedViewInput) {
      id
      name
      filterData
      isDefault
      url
    }
  }
`);

const DELETE_SAVED_VIEW = gql(`
  mutation DeleteSavedView($input: DeleteSavedViewInput!) {
    deleteSavedView(input: $input)
  }
`);

const GET_SAVED_VIEWS_BY_URL = gql(`
  query GetSavedViewsByUrl($getSavedViewsByUrlInput: GetSavedViewsByUrlInput!) {
    savedViews(getSavedViewsByUrlInput: $getSavedViewsByUrlInput) {
      id
      name
      filterData
      isDefault
      url
      userId
      isBaseView
    }
  }
`);

export interface SavedView {
  id: string;
  name: string;
  filterData: {
    objectives: {
      columnFilters: never[];
      columnVisibility: Record<string, boolean>;
      columnOrder: never[];
      sorting: never[];
      expanded: Record<string, boolean>;
      rowSelection: Record<string, boolean>;
      globalFilter: string;
    };
  };
  isDefault: boolean;
  url: string;
  isBaseView: boolean;
}

export interface SavedViewInput {
  name: string;
  filterData: {
    objectives: {
      columnFilters: never[];
      columnVisibility: Record<string, boolean>;
      columnOrder: never[];
      sorting: never[];
      expanded: Record<string, boolean>;
      rowSelection: Record<string, boolean>;
      globalFilter: string;
    };
  };
  isDefault?: boolean;
  url: string;
}

export const EMPTY_SAVED_VIEW: SavedView = {
  id: "",
  name: "",
  filterData: EMPTY_FILTERS_DATA,
  isDefault: false,
  url: "",
  isBaseView: false,
};

interface SavedViewsContextType {
  savedViews: SavedView[];
  loading: boolean;
  error: ApolloError | undefined;
  createSavedView: (input: SavedViewInput) => Promise<SavedView>;
  updateSavedView: (id: string, input: Partial<SavedViewInput>) => Promise<SavedView>;
  deleteSavedView: (id: string) => Promise<boolean>;
  selectedView: SavedView;
  setSelectedView: (view: SavedView) => void;
  isDirty: boolean;
  saveCurrentView: () => Promise<void>;
  resetCurrentView: () => void;
  currentView: SavedView;
  setCurrentView: Dispatch<SetStateAction<SavedView>>;
  handleSwitchView: (view: SavedView) => void;
}

const SavedViewsContext = createContext<SavedViewsContextType | undefined>(undefined);

interface SavedViewsProviderProps {
  children: ReactNode;
}

interface RawSavedView {
  id: string;
  name: string;
  filterData: string;
  isDefault: boolean;
  url: string;
  isBaseView: boolean;
}

const getParsedView = (view: RawSavedView): SavedView => {
  return {
    ...view,
    filterData: JSON.parse(view.filterData),
  };
};

export const SavedViewsProvider: FC<SavedViewsProviderProps> = ({ children }) => {
  const { data, loading, error, refetch } = useQuery(GET_SAVED_VIEWS_BY_URL, {
    variables: { getSavedViewsByUrlInput: { url: window.location.pathname } },
  });
  const [selectedView, setSelectedView] = useState<SavedView>(EMPTY_SAVED_VIEW);
  const [currentView, setCurrentView] = useState<SavedView>(EMPTY_SAVED_VIEW);
  const isDirty = useMemo(() => {
    return JSON.stringify(selectedView) !== JSON.stringify(currentView);
  }, [selectedView, currentView]);

  const [createSavedViewMutation] = useMutation(CREATE_SAVED_VIEW);
  const [updateSavedViewMutation] = useMutation(UPDATE_SAVED_VIEW);
  const [deleteSavedViewMutation] = useMutation(DELETE_SAVED_VIEW);

  const handleSwitchView = useCallback((view: SavedView) => {
    let newView = view;
    if (typeof view.filterData === "string") {
      newView = getParsedView(view as unknown as RawSavedView);
    }
    setSelectedView(newView);
    setCurrentView(newView);
  }, []);

  // Update views when URL changes or data loads
  useEffect(() => {
    if (!data?.savedViews) return;

    const currentUrl = window.location.pathname;
    const viewsForCurrentUrl = data.savedViews.filter((view: SavedView) => view.url === currentUrl);
    const defaultView = viewsForCurrentUrl.find((view: SavedView) => view.isDefault);
    const firstView = viewsForCurrentUrl[0];

    if (defaultView) {
      handleSwitchView(defaultView);
    } else if (firstView) {
      handleSwitchView(firstView);
    } else {
      handleSwitchView(EMPTY_SAVED_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.savedViews, window.location.pathname]);

  const createSavedView = useCallback(
    async (input: SavedViewInput) => {
      const result = await createSavedViewMutation({
        variables: { createSavedViewInput: { ...input, filterData: JSON.stringify(input.filterData) } },
      });
      await refetch();
      const newView = result.data?.createSavedView;
      if (newView) {
        handleSwitchView(newView);
      }
      return newView;
    },
    [createSavedViewMutation, refetch, handleSwitchView],
  );

  const updateSavedView = useCallback(
    async (id: string, input: Partial<SavedViewInput>) => {
      const result = await updateSavedViewMutation({
        variables: { updateSavedViewInput: { id, ...input, filterData: JSON.stringify(input.filterData) } },
      });
      await refetch();
      return result.data?.updateSavedView;
    },
    [updateSavedViewMutation, refetch],
  );

  const deleteSavedView = useCallback(
    async (id: string) => {
      // If the deleted view is the default view, set the first view as the default
      let newView = data?.savedViews.find((view: SavedView) => view.isBaseView);

      const defaultView = data?.savedViews.find((view: SavedView) => view.isDefault);
      if (defaultView) {
        newView = defaultView;
      }

      if (newView) {
        await updateSavedView(newView.id, { isDefault: true });
      }

      const result = await deleteSavedViewMutation({
        variables: { input: { id } },
      });
      await refetch();
      return result.data?.deleteSavedView;
    },
    [data?.savedViews, deleteSavedViewMutation, refetch, updateSavedView],
  );

  const saveCurrentView = useCallback(async () => {
    if (!selectedView?.id) return;

    try {
      const result = await updateSavedView(selectedView.id, {
        filterData: currentView.filterData,
      });

      if (result) {
        const parsedResult = getParsedView(result);
        setCurrentView(parsedResult);
        setSelectedView(parsedResult);
      }
    } catch (error) {
      console.error("Error saving view:", error);
      throw error;
    }
  }, [selectedView, updateSavedView, setSelectedView, currentView]);

  const resetCurrentView = useCallback(() => {
    if (!selectedView?.id || !currentView) return;

    const restoredView = {
      ...selectedView,
      filterData: selectedView.filterData,
    };

    setSelectedView(restoredView);
    setCurrentView(restoredView);
  }, [currentView, selectedView]);

  const value = {
    savedViews: data?.savedViews || [],
    loading,
    error,
    createSavedView,
    updateSavedView,
    deleteSavedView,
    selectedView,
    setSelectedView,
    isDirty,
    saveCurrentView,
    resetCurrentView,
    currentView,
    setCurrentView,
    handleSwitchView,
  };

  return <SavedViewsContext.Provider value={value}>{children}</SavedViewsContext.Provider>;
};

export const useSavedViews = () => {
  const context = useContext(SavedViewsContext);
  if (context === undefined) {
    throw new Error("useSavedViews must be used within a SavedViewsProvider");
  }
  return context;
};
