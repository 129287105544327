import { useState } from "react";
import { cn } from "@/lib/utils";
import { useUserFavorites } from "@/hooks/api/common/useUserFavorites";
import { SidebarNavElement } from "@/components/sidebar";
import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";
import { Button } from "@/components/_ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/_ui/dialog";
import { Input } from "@/components/_ui/input";
import { MoreVertical, Star } from "lucide-react";

interface RenameDialogProps {
  isOpen: boolean;
  onClose: () => void;
  favorite: { id: string; pageName: string } | null;
  onRename: (id: string, newName: string) => void;
}

const RenameDialog = ({ isOpen, onClose, favorite, onRename }: RenameDialogProps) => {
  const [newName, setNewName] = useState(favorite?.pageName || "");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (favorite && newName.trim()) {
      onRename(favorite.id, newName.trim());
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Favorit umbenennen</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <Input value={newName} onChange={(e) => setNewName(e.target.value)} placeholder="Neuer Name" autoFocus />
          <div className="flex justify-end">
            <Button type="submit">Speichern</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const SidebarAppFavorites = () => {
  const [expanded, setExpanded] = useState(true);
  const [selectedFavorite, setSelectedFavorite] = useState<{ id: string; pageName: string } | null>(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const { favorites, deleteFavorite, renameFavorite } = useUserFavorites();

  const handleRename = async (id: string, newName: string) => {
    await renameFavorite(id, newName);
  };

  if (favorites.length === 0) {
    return null;
  }

  return (
    <>
      <div className="relative mb-0.5 cursor-pointer rounded">
        <div
          className={cn(
            "my-0.5 flex select-none flex-row items-center justify-between overflow-hidden text-ellipsis rounded text-sm hover:bg-accent hover:text-accent-foreground",
          )}
        >
          <div
            className="flex flex-1 items-center"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <span className="pointer-events-none relative flex h-6 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0 text-xs text-muted-foreground">
              Favoriten
            </span>
            {expanded ? (
              <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 rotate-90 text-muted-foreground" />
            ) : (
              <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 text-muted-foreground" />
            )}
          </div>
        </div>
      </div>
      {expanded && favorites.length > 0 && (
        <>
          {favorites.map((favorite) => (
            <div key={favorite.id} className="group relative h-8 w-full">
              <SidebarNavElement to={favorite.pageUrl} className="h-8 w-full pr-8">
                <Star className="mr-2 h-3 w-3" />
                {favorite.pageName}
              </SidebarNavElement>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="absolute right-1 top-1/2 h-6 w-6 -translate-y-1/2 opacity-0 hover:bg-accent hover:text-accent-foreground group-hover:opacity-100"
                  >
                    <MoreVertical className="h-3 w-3" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => {
                      setSelectedFavorite(favorite);
                      setIsRenameDialogOpen(true);
                    }}
                  >
                    Umbenennen
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => deleteFavorite(favorite.id)}>Entfernen</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ))}
        </>
      )}
      <RenameDialog
        isOpen={isRenameDialogOpen}
        onClose={() => {
          setIsRenameDialogOpen(false);
          setSelectedFavorite(null);
        }}
        favorite={selectedFavorite}
        onRename={handleRename}
      />
    </>
  );
};
