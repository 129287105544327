import { useCallback, useEffect, useMemo, useState } from "react";

import { SidebarNavElement } from "@/components/sidebar";

import { cn } from "@/lib/utils";

import { useCurrentUser, useTeamById, useWorkspaceGetById } from "@/hooks/api/common";
import { useParams } from "react-router-dom";

import IconInvisible from "@/components/_icons/IconInvisible";
import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";
import { TeamIcon } from "@/components/_domain/team/team-icon";

export interface SidebarSettingsTeamNavElementProps {
  team: {
    id: string;
  };
  expanded?: boolean | undefined;
}

export const SidebarSettingsTeamNavElement = ({ ...props }: SidebarSettingsTeamNavElementProps) => {
  const [expanded, setExpanded] = useState(props.expanded !== undefined ? props.expanded : false);

  const { teamId } = useParams();
  const { currentUser } = useCurrentUser();

  const { team, userHasEditRights } = useTeamById({ id: props.team.id });
  const { workspace } = useWorkspaceGetById({ id: team?.workspaceId });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  const makeTeamSettingsUrl = useCallback(
    (path: string) => {
      if (!workspace) {
        return "";
      }
      if (!team) {
        return "";
      }

      return `/${workspace.workspaceUrl}/settings/teams/${team.id}/${path}`;
    },
    [workspace, team],
  );

  useEffect(() => {
    if (teamId === props.team.id) {
      setExpanded(true);
    }
  }, [teamId, props.team]);

  if (!team) {
    return <></>;
  }

  return (
    <>
      <div className="relative m-1 cursor-pointer rounded">
        <div
          className={cn(
            "flex select-none flex-row items-center overflow-hidden text-ellipsis text-sm hover:bg-accent hover:text-accent-foreground",
          )}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <span className="rounded bg-muted p-1">
            <TeamIcon team={team} />
          </span>
          <span className="pointer-events-none relative flex h-8 cursor-none items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
            {team.name}
          </span>
          {!expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 text-foreground" />}
          {expanded && <IconArrowTinyRight className="ml-1 mr-1 h-4 w-4 rotate-90 text-foreground" />}
        </div>
      </div>
      {expanded && (
        <>
          <SidebarNavElement to={makeTeamSettingsUrl("general")}>
            <IconInvisible className="mr-2 h-3 w-3" />
            Allgemein
          </SidebarNavElement>

          <SidebarNavElement to={makeTeamSettingsUrl("members")}>
            <IconInvisible className="mr-2 h-3 w-3" />
            Mitglieder
          </SidebarNavElement>
          {hasEditRights && (
            <SidebarNavElement to={makeTeamSettingsUrl("labels")}>
              <IconInvisible className="mr-2 h-3 w-3" />
              Kategorien
            </SidebarNavElement>
          )}
          {hasEditRights && (
            <SidebarNavElement to={makeTeamSettingsUrl("status")}>
              <IconInvisible className="mr-2 h-3 w-3" />
              Status
            </SidebarNavElement>
          )}
          {hasEditRights && (
            <SidebarNavElement to={makeTeamSettingsUrl("priority")}>
              <IconInvisible className="mr-2 h-3 w-3" />
              Priorität
            </SidebarNavElement>
          )}
          {hasEditRights && (
            <SidebarNavElement to={makeTeamSettingsUrl("units")}>
              <IconInvisible className="mr-2 h-3 w-3" />
              Einheiten
            </SidebarNavElement>
          )}
        </>
      )}
    </>
  );
};
