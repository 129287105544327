import { FC, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";

import IconDisplayOptions from "@/components/_icons/IconDisplayOptions";
import { ObjectiveListColumnToggleView } from "./objective-list-column-toggle-view";
import { useObjectiveListState } from "../../../hooks";
import { ObjectiveListOrderingSelect } from "./objective-list-ordering-select";
import { Switch } from "@/components/_ui/switch";
import { Label } from "@/components/_ui/label";
import { DEFAULT_EXPANDED_STATE } from "@/lib/consts/defaultExpandedState";

export interface ObjectiveListDisplayOptionsButtonProps {}

export const ObjectiveListDisplayOptionsButton: FC<ObjectiveListDisplayOptionsButtonProps> = () => {
  const { table, fn } = useObjectiveListState();
  const [open, setOpen] = useState(false);

  const areSubrowsExpanded = table.getState().expanded === DEFAULT_EXPANDED_STATE;

  const handleSubrowsExpandedChange = (checked: boolean) => {
    if (checked) {
      table.setExpanded(DEFAULT_EXPANDED_STATE);
    } else {
      table.setExpanded({});
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild={true}>
        <Button
          variant="ghost"
          size="xs"
          className="h-6 w-full justify-start overflow-x-hidden border text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
        >
          <IconDisplayOptions className="mr-2 h-3.5 w-3.5 text-foreground/60" />
          Anzeige
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-2" side="bottom" align="end">
        <div className="">
          <div className="my-2 flex flex-row items-center">
            <div className="mb-2 mr-4 text-xs font-medium text-muted-foreground">Sortierung</div>
            <ObjectiveListOrderingSelect table={table} getDisplayValueForColumn={fn.getDisplayValueForColumn} />
          </div>
          <hr />
          <div className="my-2 flex flex-col gap-4">
            <div className="text-xs font-medium text-muted-foreground">Anzeige Einstellungen</div>
            <div className="flex flex-row items-center justify-between gap-2">
              <Label htmlFor="show-sub-issues" className="text-xs font-medium">
                Unterziele anzeigen
              </Label>
              <Switch id="show-sub-issues" checked={areSubrowsExpanded} onCheckedChange={handleSubrowsExpandedChange} />
            </div>
            <ObjectiveListColumnToggleView table={table} getDisplayValueForColumn={fn.getDisplayValueForColumn} />
          </div>
          {/* <hr />
          <div className="mt-2 flex grow flex-row items-center justify-end">
            <Button variant="ghost" size="xs" className="text-xs text-muted-foreground">
              Reset to Default
            </Button>
            <Button variant="ghost" size="xs" className="text-xs text-primary">
              Set as Default
            </Button>
          </div> */}
        </div>
      </PopoverContent>
    </Popover>
  );
};
