import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop";
import Compressor from "compressorjs";

import { useToast } from "@/components/_ui/use-toast";
import { Slider } from "@/components/_ui/slider";
import { Button } from "@/components/_ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/_ui/dialog";

import { cn } from "@/lib/utils";
import getCroppedImg from "@/lib/cropImage.js";

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  borderWidth: 1,
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  width: "120px",
  height: "120px",
  cursor: "pointer",
  overflow: "hidden",
};

const focusedStyle = {};

const acceptStyle = {};

const rejectStyle = {};

export interface InputImageProps extends PropsWithChildren {
  className?: string;
  readOnly?: boolean;
  onChange: (croppedImage: File) => void;
}

export const InputImage: FC<InputImageProps> = ({ className, onChange, children, ...props }) => {
  const [readOnly] = useState(!!props.readOnly);
  const [dialogisOpen, setDialogisOpen] = useState(false);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({ width: 0, height: 0, x: 0, y: 0 });

  const { toast } = useToast();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0]) {
      openFileCropper(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  function openFileCropper(acceptedFile: File) {
    new Compressor(acceptedFile, {
      maxWidth: 1000,
      maxHeight: 1000,
      success(normalizedFile) {
        const file = new FileReader();

        file.onload = () => {
          setPreview(file.result);
          setDialogisOpen(true);
        };
        file.readAsDataURL(normalizedFile);
      },
      error(_error) {
        // console.log(error);
      },
    });
  }

  function onDialogOpenChange(open: boolean) {
    setDialogisOpen(open);
  }

  function onCropComplete(_: Area, croppedAreaPixels: Area) {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  function errorToast(description: string) {
    toast({
      title: "Fehler",
      description: description,
      variant: "error",
    });
  }

  async function onCropSave() {
    // 1. Get cropped image
    if (!preview) {
      return errorToast(
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      );
    }
    const createdImage = await getCroppedImg(preview, croppedAreaPixels);

    // // Uncomment to download cropped picture from browser
    // const anchor = document.createElement("a");
    // anchor.download = "my-file-name.jpg"; // optional, but you can give the file a name
    // anchor.href = URL.createObjectURL(createdImage);
    // anchor.click(); // ✨ magic!
    // URL.revokeObjectURL(anchor.href);

    onChange(createdImage);
    setDialogisOpen(false);
  }

  return (
    <>
      {!readOnly && (
        <div id="1" className={className} {...getRootProps({ style })}>
          <input {...getInputProps()}></input>
          {children}
        </div>
      )}
      {readOnly && (
        <div className={className} style={{ ...style, cursor: "default" }}>
          {children}
        </div>
      )}

      <Dialog open={dialogisOpen} onOpenChange={onDialogOpenChange}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>
              <span className="text-base font-normal">Passe dein Bild an</span>
            </DialogTitle>
            <DialogDescription asChild>
              <div className="h-4 w-full border-b border-solid" />
            </DialogDescription>
          </DialogHeader>
          <div className="relative max-w-full">
            <div className="h-64 w-64">
              <Cropper
                image={preview as string}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape="rect"
                restrictPosition={false}
              />
            </div>
          </div>
          <DialogFooter className="flex flex-row flex-wrap">
            <div className="controls mt-4 flex w-full flex-row justify-center self-center">
              <Slider
                defaultValue={[1]}
                min={0.5}
                max={3}
                step={0.1}
                className={cn("w-[60%]", className)}
                onValueChange={(value) => {
                  setZoom(Number(value));
                }}
              />
            </div>
            <div className="h-4 w-full border-b border-solid" />
            <div className="flex w-full flex-row justify-center pt-4">
              <Button type="button" variant="secondary" className="w-full" onClick={onCropSave}>
                Zuschneiden und Speichern
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
