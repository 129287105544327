import { FC, useEffect, useMemo, useState } from "react";

import { cn } from "@/lib/utils";

import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";

import { useGetStatusListForTeamsInWorkspace } from "@/hooks/api/common";
import { StatusView } from "@/components/_domain/status";
import { Checkbox } from "@/components/_ui/checkbox";

import { useObjectiveListState } from "../../../../hooks";

import {
  FilterByTextList,
  FilterByTextListPayload,
  FilterByTextListPayloadSchema,
} from "../../../filter-functions/filter-by-text-list";

export interface FilterByStatusConfigurationViewProps {
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByStatusConfigurationView: FC<FilterByStatusConfigurationViewProps> = ({ onFinished, ...props }) => {
  const columnId = props.column.id;
  const { table, columnFilters, config } = useObjectiveListState();
  const { statusListOfUniqueNames: statusList } = useGetStatusListForTeamsInWorkspace({
    workspaceId: config.workspace.id,
    userId: config.user.id,
    teamList: config.teamList,
  });

  const [selectedStatusNameList, setSelectedStatusNameList] = useState<FilterByTextList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(columnId);
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByTextListPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedStatusNameList(filterValue.textList);
  }, []);

  useEffect(() => {
    if (!selectedStatusNameList) {
      return;
    }
    if (selectedStatusNameList.length === 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_text_list",
      mode: filterValue?.mode ?? "included",
      textList: selectedStatusNameList,
    });
  }, [selectedStatusNameList]);

  const setFilter = (payload: FilterByTextListPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleSelect = (statusName: string | null, shouldFinish?: boolean) => {
    const statusIdList = selectedStatusNameList ?? [];
    const updatedSelectedStatusIds = statusIdList.slice().filter((name) => name !== statusName);
    setSelectedStatusNameList([...updatedSelectedStatusIds, statusName]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (statusId: string | null, shouldFinish?: boolean) => {
    const statusIdList = selectedStatusNameList ?? [];
    const updatedSelectedStatusIds = statusIdList.slice().filter((id) => id !== statusId);
    setSelectedStatusNameList(updatedSelectedStatusIds);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command>
      <CommandInput placeholder={"Status"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedStatusNameList && !!selectedStatusNameList.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <StatusView status={null} className="h-3.5 w-3.5 rounded-full" />
              </CommandItem>
            );
          })}

          {statusList.map((status) => {
            const isSelected =
              !!selectedStatusNameList && !!selectedStatusNameList.find((name) => name === status.name);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={status.id}
                value={status.name}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(status.name, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(status.name);
                  }}
                />
                <StatusView status={status} className="h-3.5 w-3.5" />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
