import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { ColorPicker } from "@/components/color-picker";

import IconCircleSolid from "@/components/_icons/IconCircleSolid";
import { toast } from "@/components/_ui/use-toast";
import UpdateLabelAction from "./actions/update-label-action";

export interface WorkspaceLabelEditFormProps extends PropsWithChildren {
  label: {
    id: string;
    title: string;
    color: string;
  };
  className?: string;
  onSuccess?: () => void;
  onCanceled?: () => void;
}

export const WorkspaceLabelEditForm: FC<WorkspaceLabelEditFormProps> = ({ label, onSuccess, onCanceled }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [labelName, setLabelName] = useState(label.title);
  const [labelColor, setLabelColor] = useState(label.color);

  const updatedLabel = useMemo(() => {
    return {
      id: label.id,
      title: labelName,
      color: labelColor,
    };
  }, [label, labelName, labelColor]);

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className="flex w-full flex-row items-center justify-between gap-2 p-1">
      <div className="w-9">
        <ColorPicker
          asChild
          value={labelColor}
          onSelect={(color) => {
            setLabelColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <IconCircleSolid className="h-3 w-3" style={{ color: labelColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={labelName}
          onChange={(e) => setLabelName(e.currentTarget.value)}
          placeholder="Name des Labels"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <UpdateLabelAction label={updatedLabel} onSuccess={onUpdateCompleted} onError={onUpdateError} asChild>
          <Button variant="default" size="sm" className="cursor-pointer" disabled={labelName === ""}>
            Speichern
          </Button>
        </UpdateLabelAction>
      </div>
    </div>
  );
};

export default WorkspaceLabelEditForm;
