import { ColumnDef } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";

import { ObjectiveListCellHealth, ObjectiveListColumnHeader } from "../..";
import { sortHealth } from "@/components/_domain/progress-snapshot";
import { ObjectiveColumnConfiguration } from "../column-definition-type";
import { FilterByHealthConfigurationView, FilterByHealthDisplayView, FilterByHealthFilterPaneView } from "./components";
import { filterByIdWithUnknownPayload } from "../../filter-functions";

interface HealthColumnDefinitionProps {
  workspaceId: string;
  columnId: string;
  orderValue: number;
}

export const HealthColumnDefinition = ({
  columnId,
  orderValue,
}: HealthColumnDefinitionProps): ObjectiveColumnConfiguration => {
  return {
    columnId: columnId,
    getDefaultVisibilityState: () => true,
    getDisplayValue: () => "Health",
    getOrderValue: () => orderValue, // prefix with 100, 200, 300 to assign to sections
    getColumnDef: (): ColumnDef<Objective> => {
      return {
        id: columnId,
        accessorKey: columnId, // key needed to enable sorting / filtering
        enableHiding: true,
        enableSorting: true,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB) => {
          if (rowA.original.progressSnapshotList.length > 0 && rowB.original.progressSnapshotList.length === 0) {
            return 1;
          }
          if (rowB.original.progressSnapshotList.length > 0 && rowA.original.progressSnapshotList.length === 0) {
            return -1;
          }

          if (rowA.original.progressSnapshotList.length === 0) {
            return 0;
          }
          if (rowB.original.progressSnapshotList.length === 0) {
            return 0;
          }

          return sortHealth(rowA.original.progressSnapshotList[0].health, rowB.original.progressSnapshotList[0].health);
        },
        filterFn: (row, _columnId, value, _addMeta) => {
          if (row.original.progressSnapshotList.length === 0) {
            return filterByIdWithUnknownPayload(value, null);
          }
          // Todo: problem – the progress Snapshot at the start of the array is not always the most recent one
          return filterByIdWithUnknownPayload(value, row.original.progressSnapshotList[0].health);
        },
        size: 1, // set to 1 instead to 0 for it to work in Safari
        header: ({ column }) => (
          <ObjectiveListColumnHeader column={column} title="" className="px-1 text-xs font-normal" />
        ),
        cell: ({ row }) => <ObjectiveListCellHealth row={row} className="mx-2" />,
      };
    },
    getDisplayView: () => <FilterByHealthDisplayView />,
    getConfigurationView: (onFinished: () => void) => (
      <FilterByHealthConfigurationView column={{ id: columnId }} onFinished={onFinished} />
    ),
    getConfigurationViewType: () => "popover",
    getFilterPaneView: () => <FilterByHealthFilterPaneView column={{ id: columnId }} />,
  };
};
