import { FC } from "react";

import { useGetUnit } from "@/hooks/api/common";

interface ValueWithUnitProps {
  value: number;
  unit?: {
    id: string;
  } | null;
}

export const ValueWithUnit: FC<ValueWithUnitProps> = ({ value, ...props }) => {
  const { unit } = useGetUnit({ id: props.unit?.id ?? undefined });

  if (!unit) {
    return new Intl.NumberFormat("de-DE", { style: "decimal", maximumFractionDigits: 2 }).format(value);
  }

  return `${new Intl.NumberFormat("de-DE", { style: "decimal", maximumFractionDigits: 2 }).format(
    value,
  )} ${unit?.symbol}`;
};

export default ValueWithUnit;
