import { useContext } from "react";

import { WorkspaceContext } from "@/hooks/useCurrentWorkspace";

import Header from "@/components/header";

import { WorkspaceEditForm } from "@/components/_domain/workspace/workspace-edit-form";
import { WorkspaceDeleteDialogWithEmailButton } from "@/components/_domain/workspace/workspace-delete-dialog";

import { UpdatedWorkspace, useCurrentUser } from "@/hooks/api/common";

export default function Page() {
  const { currentWorkspace, setCurrentWorkspace, hasEditRights } = useContext(WorkspaceContext);
  const { currentUser } = useCurrentUser();

  const handleWorkspaceUpdated = (updatedWorkspace: UpdatedWorkspace) => {
    const wsm = updatedWorkspace.workspaceMembershipList.find((wsm) => wsm.userId === currentUser?.id);

    const workspace = {
      id: updatedWorkspace.id,
      name: updatedWorkspace.name,
      workspaceUrl: updatedWorkspace.workspaceUrl,
      logo: updatedWorkspace.logo,
      workspaceMembership: {
        role: wsm?.role ?? "USER",
        status: wsm?.status ?? "SUSPENDED",
      },
    };

    setCurrentWorkspace(workspace);
  };

  return (
    <main className="relative place-items-stretch overflow-auto">
      <Header className="border-none"></Header>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Workspace</div>
              <div className="text-sm text-muted-foreground">Verwalten Sie Ihren Workspace</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <WorkspaceEditForm
                workspace={currentWorkspace}
                readOnly={!hasEditRights}
                onUpdateComplete={handleWorkspaceUpdated}
                className="px-px"
              />
            </div>

            {hasEditRights && (
              <div>
                <div className="my-12 w-full border-t border-solid" />
                <h2 className="mb-4 text-lg">Workspace Löschen</h2>
                <p className="mb-4 text-sm text-muted-foreground">
                  Wenn Sie den Workspace und alle damit assozierten Daten löschen wollen, können Sie das hier tun.
                </p>
                <WorkspaceDeleteDialogWithEmailButton workspace={currentWorkspace} redirectAfterDelete={true} />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
