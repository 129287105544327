import { FC, useMemo } from "react";

import { useGetKeyResult, useGetProgressSnapshot, useGetProgressSnapshotList } from "@/hooks/api/common";
import { ValueWithUnit } from "@/components/_domain/unit/value-with-unit";

interface QuantValueUpdateProps {
  progressSnapshot: {
    id: string;
  };
}

export const QuantValueUpdate: FC<QuantValueUpdateProps> = ({ ...props }) => {
  const { quantitativeProgressSnapshot } = useGetProgressSnapshot({
    id: props.progressSnapshot.id,
  });
  const { progressSnapshotList } = useGetProgressSnapshotList({
    objectiveId: quantitativeProgressSnapshot?.objectiveId,
  });

  const { keyResult } = useGetKeyResult({ id: quantitativeProgressSnapshot?.keyResultId ?? undefined });

  const value = useMemo(() => {
    if (!quantitativeProgressSnapshot) {
      return null;
    }
    return quantitativeProgressSnapshot.value;
  }, [quantitativeProgressSnapshot]);

  const previousProgressSnapshot = useMemo(() => {
    if (!quantitativeProgressSnapshot) {
      return null;
    }

    let ignore = true;
    for (const ps of progressSnapshotList) {
      // they come sorted; pick the progressSnapshot right after the current one
      if (ps.id === quantitativeProgressSnapshot.id) {
        ignore = false;
        continue;
      }

      if (ignore) {
        continue;
      }

      if (ps.value !== undefined && ps.value !== null) {
        return ps;
      }
    }
    return null;
  }, [quantitativeProgressSnapshot, progressSnapshotList]);

  if (!quantitativeProgressSnapshot) {
    return <></>;
  }

  if (!keyResult) {
    return <></>;
  }

  if (!value) {
    return <></>;
  }

  return (
    <>
      <span>den Indikator</span>
      <span className="text-bold text-foreground">{keyResult.title}</span>
      <span>von</span>
      {previousProgressSnapshot && previousProgressSnapshot.value && (
        <span className="text-bold text-foreground">
          <ValueWithUnit
            value={previousProgressSnapshot.value}
            unit={keyResult.unitId ? { id: keyResult.unitId } : undefined}
          />
          <span className="ml-0.5 px-0.5">→</span>
          <ValueWithUnit value={value} unit={keyResult.unitId ? { id: keyResult.unitId } : undefined} />
        </span>
      )}
      {(!previousProgressSnapshot || !previousProgressSnapshot.value) && (
        <span className="text-bold text-foreground">
          <ValueWithUnit
            value={keyResult.initialValue}
            unit={keyResult.unitId ? { id: keyResult.unitId } : undefined}
          />
          <span className="ml-0.5 px-0.5">→</span>
          <ValueWithUnit value={value} unit={keyResult.unitId ? { id: keyResult.unitId } : undefined} />
        </span>
      )}
      <span>aktualisiert</span>
    </>
  );
};
