import { useMutation } from "@apollo/client";
import { teamMembershipsForTeamQueryDocument, acceptTeamInviteMutationDocument } from "@/graphql/common";

export interface UseAcceptTeamMembershipInviteProps {
  team: {
    id: string;
  };
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useTeamInviteAccept = ({ onCompleted, onError, ...props }: UseAcceptTeamMembershipInviteProps) => {
  const [acceptInvitation, queryResult] = useMutation(acceptTeamInviteMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: teamMembershipsForTeamQueryDocument,
        variables: {
          input: props.team.id,
        },
      },
    ],
  });

   
  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    queryResult,
    acceptTeamInvitation: acceptInvitation,
  };
};
