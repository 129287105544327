import { useMutation } from "@apollo/client";
import {
  deleteAssigneeMutationDocument,
  getAssigneeByOwnerQueryDocument,
  getObjectiveQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

export interface UseDeleteAssigneeProps {
  ownerId: string;
  ownerType: "OBJECTIVE";
  oldUserId?: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useDeleteAssignee = ({ ownerId, ownerType, oldUserId, onCompleted, onError }: UseDeleteAssigneeProps) => {
  const ownerQueryDocument = useMemo(() => {
    if (ownerType === "OBJECTIVE") {
      return {
        query: getObjectiveQueryDocument,
        variables: { input: { id: ownerId } },
      };
    }
    return null;
  }, [ownerId, ownerType]);

  const refetchQueries = useMemo(() => {
    const refetchQueries = [];

    refetchQueries.push({
      query: getAssigneeByOwnerQueryDocument,
      variables: { input: { ownerId } },
    });

    if (ownerQueryDocument) {
      refetchQueries.push(ownerQueryDocument);
    }

    return refetchQueries;
  }, [ownerId, oldUserId, ownerQueryDocument]);

  const [deleteAssignee] = useMutation(deleteAssigneeMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deleteAssignee,
  };
};
