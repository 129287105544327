export const PriorityTypes = {
  low: "Low",
  medium: "Medium",
  high: "High",
  critical: "Critical",
} as const;

export const ALLOWED_PRIORITY_TYPES = Object.values(PriorityTypes);
export type PriorityType = (typeof ALLOWED_PRIORITY_TYPES)[number];

export const sortPriority = (a?: string, b?: string): 0 | 1 | -1 => {
  const numberFromType = (p?: string) => {
    if (p === PriorityTypes.low) return 0;
    if (p === PriorityTypes.medium) return 1;
    if (p === PriorityTypes.high) return 2;
    if (p === PriorityTypes.critical) return 3;
    return -1;
  };

  const numA = numberFromType(a);
  const numB = numberFromType(b);

  if (numA - numB > 0) {
    return 1;
  }
  if (numA - numB < 0) {
    return -1;
  }

  return 0;
};
