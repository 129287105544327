import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";
import { useMemo } from "react";

import { useGetLabelGroupListForTeamsInWorkspace } from "@/hooks/api/common";
import {
  AssigneeColumnDefinition,
  HealthColumnDefinition,
  LabelGroupColumnListDefinition,
  LabelListColumnDefinition,
  ObjectiveTitleColumnDefinition,
  PriorityColumnDefinition,
  ProgressColumnDefinition,
  StartDateColumnDefinition,
  StatusColumnDefinition,
  TargetDateColumnDefinition,
} from "@/components/_domain/objective/objective-list-overview/columns";
import { ObjectiveColumnConfiguration } from "@/components/_domain/objective/objective-list-overview/columns/column-definition-type";
import { DEFAULT_EXPANDED_STATE } from "@/lib/consts/defaultExpandedState";

interface UseTeamObjectiveListColumnConfigurationProps {
  workspace: {
    id: string;
  };
  user: {
    id: string;
  };
  teamList: { id: string }[];
}

export const useTeamObjectiveListColumnConfiguration = ({
  workspace,
  user,
  teamList,
}: UseTeamObjectiveListColumnConfigurationProps) => {
  const { labelGroupList } = useGetLabelGroupListForTeamsInWorkspace({
    workspaceId: workspace.id,
    userId: user.id,
    teamList: teamList,
  });
  const columnConfigurationList: ObjectiveColumnConfiguration[] = useMemo(() => {
    return [
      PriorityColumnDefinition({ columnId: "priority", orderValue: 101 }),
      StatusColumnDefinition({ columnId: "status", orderValue: 102 }),
      ObjectiveTitleColumnDefinition({
        workspaceId: workspace.id,
        columnId: "objective_title",
        orderValue: 103,
      }),
      AssigneeColumnDefinition({ workspaceId: workspace.id, columnId: "assignee", orderValue: 301 }),
      StartDateColumnDefinition({
        workspaceId: workspace.id,
        columnId: "start_date",
        orderValue: 302,
      }),
      TargetDateColumnDefinition({
        workspaceId: workspace.id,
        columnId: "target_date",
        orderValue: 303,
      }),
      HealthColumnDefinition({ workspaceId: workspace.id, columnId: "health", orderValue: 304 }),
      ProgressColumnDefinition({ workspaceId: workspace.id, columnId: "progress", orderValue: 305 }),
      LabelListColumnDefinition({
        workspaceId: workspace.id,
        columnId: "label_list",
        orderValue: 201,
      }),
      ...LabelGroupColumnListDefinition({
        workspaceId: workspace.id,
        startOrderValue: 201,
        labelGroupList: labelGroupList,
      }),
    ];
  }, [workspace, labelGroupList]);

  const defaultVisibilityState = useMemo(() => {
    const visibilityState: VisibilityState = {};
    for (const { columnId, getDefaultVisibilityState } of columnConfigurationList) {
      visibilityState[columnId] = getDefaultVisibilityState();
    }
    return visibilityState;
  }, [columnConfigurationList]);

  const defaultColumnOrderState: ColumnOrderState = useMemo(() => {
    return columnConfigurationList
      .slice()
      .sort((a, b) => {
        const diff = a.getOrderValue() - b.getOrderValue();

        if (diff > 0) {
          return 1;
        }
        if (diff < 0) {
          return -1;
        }
        return 0;
      })
      .map((c) => c.columnId);
  }, [columnConfigurationList]);

  return {
    columnConfigurationList,
    defaultVisibilityState,
    defaultColumnOrderState,
    defaultExpandedState: DEFAULT_EXPANDED_STATE,
  };
};
