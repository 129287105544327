import { DPMonth, DPOffsetValue, useDatePicker } from "@rehookify/datepicker";
import { FC, useEffect, useMemo, useState } from "react";

import { Button } from "./button";
import { Calendar } from "./calendar";
import { SectionHeader } from "./section-header";
import { Section } from "./section";

import { getDayClassName, getMonthClassName, getYearsClassName } from "./classnames-utils";

import IconChevronLeft from "@/components/_icons/IconChevronLeft";
import IconChevronRight from "@/components/_icons/IconChevronRight";

import { cn } from "@/lib/utils";
import { useDate } from "@/hooks/useDate";

type ViewMode = "DAY" | "MONTH" | "YEAR";

export interface DatepickerProps {
  onValueChange?: (value: Date | null) => void;
  defaultValue?: Date;
  minDate?: Date;
  maxDate?: Date;
}

// Currently only built for selecting a single date
export const Datepicker: FC<DatepickerProps> = ({ onValueChange, defaultValue, minDate, maxDate }) => {
  const { TODAY } = useDate();
  const [viewMode, setViewMode] = useState<ViewMode>("DAY");

  const [selectedDate, setSelectedDate] = useState<Date | null>(defaultValue ? defaultValue : null);
  const [selectedDateInternal, setSelectedDateInternal] = useState<Date | null>(defaultValue ? defaultValue : null);
  // the offsetDate is the that the calendar displays, not neccessary the selected date.
  const [offsetDate, setOffsetDate] = useState<Date>(defaultValue ? defaultValue : TODAY);

  const selectedDates = useMemo(() => {
    if (!selectedDateInternal) {
      return [];
    }
    return [selectedDateInternal];
  }, [selectedDateInternal]);

  useEffect(() => {
    if (!onValueChange) {
      return;
    }
    onValueChange(selectedDate);
  }, [selectedDate]);

  const handleDateChange = () => {
    // callback needs to be set;
    // however we handle the selected Date state internally
  };

  const isValidDate = (date: Date) => {
    if (minDate && date.getTime() < minDate.getTime()) {
      return false;
    }
    if (maxDate && date.getTime() > maxDate.getTime()) {
      return false;
    }
    return true;
  };

  const dateWithOffset = (date: Date, offsetValue: DPOffsetValue) => {
    let newDate = new Date(date);
    if (offsetValue.days) {
      newDate = new Date(offsetDate.setDate(offsetDate.getDate() + offsetValue.days));
    }
    if (offsetValue.months) {
      newDate = new Date(offsetDate.setMonth(offsetDate.getMonth() + offsetValue.months));
    }
    if (offsetValue.years) {
      newDate = new Date(offsetDate.setFullYear(offsetDate.getFullYear() + offsetValue.years));
    }

    return newDate;
  };

  const getGermanMonthString = (monthString: string) => {
    const mapping = new Map<string, string>([
      ["January", "Januar"],
      ["February", "Februar"],
      ["March", "März"],
      ["April", "April"],
      ["May", "Mai"],
      ["June", "Juni"],
      ["July", "Juli"],
      ["August", "August"],
      ["September", "September"],
      ["October", "Oktober"],
      ["November", "November"],
      ["December", "Dezember"],
    ]);

    // fallback
    return mapping.get(monthString) ?? monthString;
  };

  const handleDaySelected = (day: number) => {
    setSelectedDateInternal(new Date(offsetDate.getFullYear(), offsetDate.getMonth(), day));
    setOffsetDate(new Date(offsetDate.getFullYear(), offsetDate.getMonth(), day));
    setSelectedDate(new Date(offsetDate.getFullYear(), offsetDate.getMonth(), day));
  };

  const handleMonthSelected = (month: number) => {
    setSelectedDateInternal(new Date(offsetDate.getFullYear(), month, 1));
    setOffsetDate(new Date(offsetDate.getFullYear(), month, 1));

    setViewMode("DAY");
  };

  const handleYearSelected = (year: number) => {
    setSelectedDateInternal(new Date(year, 0, 1));
    setOffsetDate(new Date(year, 0, 1));
    setViewMode("MONTH");
  };

  const handleOnOffsetChange = (offsetValue: DPOffsetValue) => {
    const newDate = dateWithOffset(offsetDate, offsetValue);
    setOffsetDate(newDate);
  };

  const wrappedMonth = (month: DPMonth) => {
    const isDisabled = !selectedDateInternal
      ? month.disabled
      : !isValidDate(
          new Date(selectedDateInternal.getFullYear(), month.$date.getMonth(), selectedDateInternal.getDate()),
        );
    const wrappedMonth = { ...month, disabled: isDisabled };

    return wrappedMonth;
  };

  const {
    data: { calendars, weekDays, months, years },
    propGetters: { dayButton, monthButton, nextYearsButton, previousYearsButton, yearButton },
  } = useDatePicker({
    selectedDates,
    offsetDate: offsetDate ?? undefined,
    onDatesChange: handleDateChange,
    calendar: {
      startDay: 1,
    },
    dates: {
      mode: "single",
      minDate: minDate,
      maxDate: maxDate,
    },
  });

  const { month, year, days } = calendars[0];

  return (
    <div className="shadow-xs shadow block rounded border border-slate-300 p-4 shadow-slate-300">
      {/* <h1 className="text-md w-full text-center mb-6">{formattedDates[0]}</h1> */}
      <main className="flex flex-row">
        <Section>
          <SectionHeader>
            <div className="flex w-[70%] flex-row items-center justify-between">
              <Button
                className={cn(["w-8", viewMode !== "DAY" ? "disabled cursor-not-allowed text-muted-foreground" : ""])}
                disabled={viewMode !== "DAY"}
                onClick={() => {
                  handleOnOffsetChange({ months: -1 });
                }}
              >
                <IconChevronLeft className="h-3 w-3" />
              </Button>
              <Button
                className="px-3 text-center text-sm"
                onClick={() => {
                  setViewMode("MONTH");
                }}
              >
                {getGermanMonthString(month)}
              </Button>
              <Button
                className={cn(["w-8", viewMode !== "DAY" ? "disabled cursor-not-allowed text-muted-foreground" : ""])}
                disabled={viewMode !== "DAY"}
                onClick={() => {
                  handleOnOffsetChange({ months: 1 });
                }}
              >
                <IconChevronRight className="h-3 w-3" />
              </Button>
            </div>
            <div className="w-1/4">
              <Button
                className="px-3 text-sm"
                onClick={() => {
                  setViewMode("YEAR");
                }}
              >
                {offsetDate.getFullYear()}
              </Button>
            </div>
          </SectionHeader>

          {viewMode === "DAY" && (
            <>
              <Calendar className="mb-2 h-8 items-center">
                {weekDays.map((d) => (
                  <p key={d} className="text-center text-xs">
                    {d}
                  </p>
                ))}
              </Calendar>
              <Calendar>
                {days.map((d) => (
                  <Button
                    key={d.$date.toString()}
                    className={getDayClassName("w-8 text-xs", d)}
                    {...dayButton(d)}
                    onClick={() => handleDaySelected(d.$date.getDate())}
                  >
                    {d.day}
                  </Button>
                ))}
              </Calendar>
            </>
          )}

          {viewMode === "MONTH" && (
            <div className="grid grid-cols-3 items-center gap-x-2 gap-y-2">
              {months.map((_m) => {
                // Note: There is a bug where disabled isn't calculated correctly for the month view,
                // making it neccessary to calculate the disabled state manually and overwritting
                // the button props

                const m = wrappedMonth(_m);
                return (
                  <Button
                    key={m.month + year}
                    {...monthButton(m)}
                    onClick={() => handleMonthSelected(m.$date.getMonth())}
                    className={getMonthClassName("text-xs", m)}
                  >
                    {m.month}
                  </Button>
                );
              })}
            </div>
          )}

          {viewMode === "YEAR" && (
            <div className="mx-2 grid grid-cols-3 items-center gap-x-2 gap-y-2">
              {years.map((y) => (
                <Button
                  key={y.$date.toString()}
                  className={getYearsClassName("text-xs", y)}
                  {...yearButton(y)}
                  onClick={() => {
                    handleYearSelected(y.$date.getFullYear());
                  }}
                >
                  {y.year}
                </Button>
              ))}
              <div
                className="absolute bottom-0 left-0 top-0 flex flex-col justify-center pl-2 pt-2"
                {...previousYearsButton()}
              >
                <Button className="w-4" {...previousYearsButton()}>
                  <IconChevronLeft className="h-3 w-3" />
                </Button>
              </div>
              <div
                className="absolute bottom-0 right-0 top-0 flex flex-col justify-center pr-2 pt-2"
                {...nextYearsButton()}
              >
                <Button className="w-4" {...nextYearsButton()}>
                  <IconChevronRight className="h-3 w-3" />
                </Button>
              </div>
            </div>
          )}
        </Section>
      </main>
    </div>
  );
};

export default Datepicker;
