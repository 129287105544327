import { useCallback, useMemo } from "react";

import { useQuery } from "@apollo/client";
import { userInWorkspaceQueryDocument } from "@/graphql/common";

export interface UseGetUserProps {
  workspaceId: string;
  userId?: string | null;
}

export const useUserInWorkspace = ({ workspaceId, userId }: UseGetUserProps) => {
  const queryResult = useQuery(userInWorkspaceQueryDocument, {
    variables: {
      input: {
        workspaceId: workspaceId,
        userId: userId ?? "",
      },
    },
    skip: !userId,
  });

  const user = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }

    return queryResult.data.userInWorkspace;
  }, [queryResult.data]);

  const getWorkspaceMembershipForWorkspace = useCallback(
    (workspaceId: string) => {
      if (!user) {
        return null;
      }

      if (!user.workspaceMembershipList) {
        return null;
      }

      return user.workspaceMembershipList.find((wms) => wms.workspaceId === workspaceId);
    },
    [user],
  );

  return {
    queryResult,
    user,
    getWorkspaceMembershipForWorkspace,
  };
};
