import { FC, PropsWithChildren, useState } from "react";

import { cn } from "@/lib/utils";

import { API_ENDPOINT, apolloClient } from "@/services/apollo.service";
import { createDownloadLinkMutationDocument } from "@/graphql/common";
import { useToast } from "@/components/_ui/use-toast";

interface DownloadLink {
  id: string;
}

export interface FileDownloaderProps extends PropsWithChildren {
  className?: string;
  file: {
    id: string;
    name: string;
  };
  mode: "DOWNLOAD" | "OPEN";
}

export const FileDownloader: FC<FileDownloaderProps> = ({ className, children, file, mode = "OPEN" }) => {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  function showErrorToast() {
    toast({
      title: "Fehler",
      description: "Leider ist ein Fehler aufgetreten. Der Download konnte nicht gestartet werden.",
      variant: "error",
    });
  }

  async function getDownloadLink(): Promise<DownloadLink | null> {
    const { data } = await apolloClient.mutate({
      mutation: createDownloadLinkMutationDocument,
      fetchPolicy: "no-cache",
      variables: {
        input: {
          fileId: file.id,
        },
      },
    });

    if (!data) {
      return null;
    }

    return data.createDownloadLink;
  }

  function triggerDownload(downloadLinkId: string) {
    const aTag = document.createElement("a");
    aTag.href = `${API_ENDPOINT}/download/${downloadLinkId}${mode === "DOWNLOAD" ? "?download=1" : ""}`;
    aTag.setAttribute("target", "_blank");
    aTag.setAttribute("download", file.name);
    document.body.append(aTag);
    aTag.click();
    aTag.remove();
  }

  async function downloadFile() {
    if (loading) {
      return;
    }

    setLoading(true);
    const downloadLink = await getDownloadLink();

    if (!downloadLink) {
      showErrorToast();
      setLoading(false);
      return;
    }

    triggerDownload(downloadLink.id);
    setLoading(false);
  }

  return (
    <div
      className={cn(["cursor-pointer", className])}
      onClick={() => {
        downloadFile();
      }}
    >
      {children}
    </div>
  );
};
