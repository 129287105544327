import { useMutation } from "@apollo/client";
import {
  createLabelInstanceMutationDocument,
  getLabelInstancesQueryDocument,
  getObjectiveQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

export interface UseCreateLabelInstanceProps {
  ownerId: string;
  ownerType: "OBJECTIVE";
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreateLabelInstance = ({ ownerId, ownerType, onCompleted, onError }: UseCreateLabelInstanceProps) => {
  const ownerQueryDocumentList = useMemo(() => {
    if (ownerType === "OBJECTIVE") {
      return [
        {
          query: getObjectiveQueryDocument,
          variables: { input: { id: ownerId } },
        },
        {
          query: getLabelInstancesQueryDocument,
          variables: { input: { ownerId: ownerId } },
        },
      ];
    }
    return [];
  }, [ownerId, ownerType]);

  const [createLabelInstance] = useMutation(createLabelInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [...ownerQueryDocumentList],
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createLabelInstance,
  };
};
