import { useEffect, useMemo } from "react";

import { toast } from "@/components/_ui/use-toast.ts";
import { Slot, type AsChildProps } from "@/components/slot.tsx";

import {
  getLabelGroupQueryDocument,
  deleteLabelMutationDocument,
  getLabelListForTeamQueryDocument,
  teamsForUserInWorkspaceQueryDocument,
} from "@/graphql/common";
import { useMutation } from "@apollo/client";

type DeletLabelActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
    labelGroupId?: string | null;
  };
  team: {
    id: string;
    workspaceId: string;
  };
  forceDelete?: boolean;
};

export const DeletLabelAction = ({ label, team, asChild, forceDelete, ...props }: DeletLabelActionProps) => {
  const Comp = asChild ? Slot : "button";
  const refetchQueries = useMemo(() => {
    if (!label.labelGroupId) {
      return [
        {
          query: getLabelListForTeamQueryDocument,
          variables: { input: { teamId: team.id } },
        },
      ];
    }

    return [
      {
        query: getLabelListForTeamQueryDocument,
        variables: { input: { teamId: team.id } },
      },
      {
        query: getLabelGroupQueryDocument,
        variables: { input: { id: label.labelGroupId } },
      },
      // ISSUE: The useGetLabelListForTeamsInWorkspace hook get the labels by filtering from
      // useTeamListForUserInWorkspace({ workspaceId, userId }). The advantage of this is that
      // we have to essentially refetch all the teams whenever the user adds or removes a label.
      {
        query: teamsForUserInWorkspaceQueryDocument,
        variables: { input: team.workspaceId },
      },
    ];
  }, [label]);

  const [deleteLabel] = useMutation(deleteLabelMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Kategorie gelöscht",
        description: "Wir haben die Kategorie erfolgreich gelöscht.",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler beim Löschen der Kategorie aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
    },
    refetchQueries: refetchQueries,
  });

  useEffect(() => {
    if (forceDelete) {
      triggerDeleteLabel();
    }
  }, [forceDelete]);

  async function triggerDeleteLabel() {
    await deleteLabel({ variables: { input: { id: label.id } } });
  }

  function handleClick() {
    triggerDeleteLabel();
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default DeletLabelAction;
