import { ColumnDef } from "@tanstack/react-table";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
import { Workspace } from "./model";

import { DatatableColumnHeader } from "@/components/datatable-column-header";
import { AvatarWorkspace } from "..";
import { DatatableRowActions } from "./row-actions";
import { format } from "date-fns";
import { Link } from "react-router-dom";

export const columns: ColumnDef<Workspace>[] = [
  {
    id: "logo",
    accessorKey: "logo",
    enableHiding: false,
    enableSorting: false,
    header: ({ column }) => <DatatableColumnHeader column={column} title="Logo" className="pl-6" />,
    cell: ({ row }) => <AvatarWorkspace workspace={row.original} className="ml-6 h-6 w-6 text-xxs" />,
  },
  {
    id: "name",
    accessorKey: "name",
    enableHiding: false,
    header: ({ column }) => <DatatableColumnHeader column={column} title="Name" />,
    cell: ({ row }) => <Link to={`/admin/workspaces/${row.original.id}`}>{row.original.name}</Link>,
  },
  {
    id: "workspaceUrl",
    accessorKey: "workspaceUrl",
    header: ({ column }) => <DatatableColumnHeader column={column} title="URL" />,
  },
  {
    id: "members",
    accessorKey: "workspaceMembershipList",
    accessorFn: (original) => {
      return `${original.workspaceMembershipList.filter((wsm) => wsm.status === "ACTIVE").length}`;
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Members" />,
  },
  {
    id: "invitations",
    accessorKey: "workspaceMembershipList",
    accessorFn: (original) => {
      return `${original.workspaceMembershipList.filter((wsm) => wsm.status === "PENDING").length}`;
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Invitations" />,
  },
  {
    id: "updatedAt",
    accessorKey: "updatedAt",
    accessorFn: (original) => {
      return format(new Date(original.updatedAt), "yyyy-MM-dd HH:mm:ss");
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Updated At" />,
  },
  {
    id: "createdAt",
    accessorKey: "createdAt",
    accessorFn: (original) => {
      return format(new Date(original.createdAt), "yyyy-MM-dd HH:mm:ss");
    },
    header: ({ column }) => <DatatableColumnHeader column={column} title="Created At" />,
  },
  {
    id: "actions",
    cell: ({ row }) => <DatatableRowActions row={row} />,
  },
];
