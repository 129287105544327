import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { ColorPicker } from "@/components/color-picker";

import { toast } from "@/components/_ui/use-toast";
import UpdateLabelGroupAction from "./actions/update-label-group-action";
import IconCircleGroup from "@/components/_icons/IconCircleGroup";
import { Switch } from "@/components/_ui/switch";

export interface WorkspaceLabelGroupEditFormProps extends PropsWithChildren {
  labelGroup: {
    id: string;
    title: string;
    color: string;
    allowMultiSelect: boolean;
  };
  className?: string;
  onSuccess?: () => void;
  onCanceled?: () => void;
}

export const WorkspaceLabelGroupEditForm: FC<WorkspaceLabelGroupEditFormProps> = ({
  labelGroup,
  onSuccess,
  onCanceled,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [labelGroupName, setLabelGroupName] = useState(labelGroup.title);
  const [labelGroupColor, setLabelGroupColor] = useState(labelGroup.color);
  const [allowMultiSelect, setAllowMultiSelect] = useState(labelGroup.allowMultiSelect);

  const updatedLabelGroup = useMemo(() => {
    return {
      id: labelGroup.id,
      title: labelGroupName,
      color: labelGroupColor,
      allowMultiSelect: allowMultiSelect,
    };
  }, [labelGroup, labelGroupName, labelGroupColor, allowMultiSelect]);

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className=" p-1">
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="w-9">
          <ColorPicker
            asChild
            value={labelGroupColor}
            onSelect={(color) => {
              setLabelGroupColor(color);
            }}
          >
            <Button variant="outline" size="sm">
              <IconCircleGroup className="h-3 w-3" style={{ color: labelGroupColor }} />
            </Button>
          </ColorPicker>
        </div>
        <div className="grow">
          <Input
            ref={ref}
            autoFocus
            value={labelGroupName}
            onChange={(e) => setLabelGroupName(e.currentTarget.value)}
            placeholder="Name des Labels"
            className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
            style={{
              boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            }}
          />
        </div>
        <div>
          <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
            Abbrechen
          </Button>
        </div>
        <div>
          <UpdateLabelGroupAction
            labelGroup={updatedLabelGroup}
            onSuccess={onUpdateCompleted}
            onError={onUpdateError}
            asChild
          >
            <Button variant="default" size="sm" className="cursor-pointer" disabled={labelGroupName === ""}>
              Speichern
            </Button>
          </UpdateLabelGroupAction>
        </div>
      </div>
      {/* Second row for switch  */}
      <div className="ml-12 mt-2">
        <div className="flex items-center space-x-1 text-sm text-muted-foreground">
          <Switch id="airplane-mode" checked={allowMultiSelect} onCheckedChange={setAllowMultiSelect} />
          <span>Mehrfachauswahl zulassen</span>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceLabelGroupEditForm;
