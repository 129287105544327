import { FC, useMemo } from "react";

import { RichTextEditor } from "@/components/rich-text-editor";

import { useGetProgressSnapshot } from "@/hooks/api/common";

export interface ProgressSnapshotDescriptionViewProps {
  workspace: {
    id: string;
  };
  progressSnapshot: {
    id: string;
  };
}
export const ProgressSnapshotDescriptionView: FC<ProgressSnapshotDescriptionViewProps> = ({ ...props }) => {
  const { progressSnapshot } = useGetProgressSnapshot({ id: props.progressSnapshot.id });

  const initialEditorState = useMemo(() => {
    if (!progressSnapshot) {
      return undefined;
    }

    if (!progressSnapshot.description) {
      return undefined;
    }
    if (progressSnapshot.description === "") {
      return undefined;
    }
    return progressSnapshot.description;
  }, [progressSnapshot]);

  if (!progressSnapshot) {
    return <></>;
  }

  if (!progressSnapshot.description || progressSnapshot.description === "") {
    return "";
  }

  return (
    <div className="">
      <RichTextEditor readOnly={true} initialValue={initialEditorState} placeholder={""} className="px-0" />
    </div>
  );
};

export default ProgressSnapshotDescriptionView;
