import { FC, PropsWithChildren } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";

import LoadingDots from "@/components/loading-dots";
import { useToast } from "@/components/_ui/use-toast";

import { ApolloError, useMutation } from "@apollo/client";
import { updateUserPasswordDocument } from "@/graphql/common";

const formSchema = z
  .object({
    password: z.string(),
    newpassword: z.string().min(8, {
      message: "Das neue Passwort muss mindestens 8 Zeichen lang sein.",
    }),
    confirmation: z.string(),
  })
  .refine((data) => data.newpassword === data.confirmation, {
    message: "Die Passwörter stimmen nicht überein.",
    path: ["confirmation"],
  });

export interface PasswordFormPropsWithChildren extends PropsWithChildren {
  id: string;
  email: string;
  className?: string;
}
export const PasswordForm: FC<PasswordFormPropsWithChildren> = ({ email, className }) => {
  const { toast } = useToast();
  const [updatePassword, { loading }] = useMutation(updateUserPasswordDocument, {
    // Todo: doesn't work yet // wrong mutation
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      newpassword: "",
      confirmation: "",
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    updatePassword({
      variables: {
        input: {
          email: email,
          password: values.password,
          newPassword: values.newpassword,
        },
      },
    });
  }

  async function onUpdateCompleted() {
    toast({
      title: "Gespeichert",
      description: "Dein Passwort wurde erfolgreich aktualisert.",
      variant: "success",
    });
  }

  async function onUpdateError(error: ApolloError) {
    if (error.message === "Invalid password") {
      form.setError("password", { message: "Das Passwort stimmt nicht mit dem am Server überein." });
      return;
    }

    if (error.message === "The new password needs to be at least 8 characters long.") {
      form.setError("newpassword", { message: "Das neue Passwort muss mindestens 8 Zeichen lang sein." });
      return;
    }

    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={cn("space-y-4", className)}>
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Passwort</FormLabel>
              <FormDescription>Ihr aktuelles Passwort.</FormDescription>
              <FormControl>
                <Input type="password" {...field} autoComplete="off" />
              </FormControl>
              <FormMessage></FormMessage>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="newpassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Neues Passwort</FormLabel>
              <FormDescription>Bitte geben Sie Ihr neues Passwort ein.</FormDescription>
              <FormControl>
                <Input type="password" {...field} autoComplete="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmation"
          render={({ field }) => (
            <FormItem>
              <FormDescription>Bitte bestätigen Sie Ihr neues Passwort.</FormDescription>
              <FormControl>
                <Input type="password" {...field} autoComplete="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          disabled={loading}
          className={`${
            loading ? "cursor-not-allowed bg-muted" : "bg-primary text-primary-foreground hover:bg-primary/90"
          } flex h-10 w-32 items-center justify-center rounded-md border text-sm transition-all focus:outline-none`}
        >
          {loading ? <LoadingDots color="#808080" /> : <p>Speichern</p>}
        </Button>
      </form>
    </Form>
  );
};

export default PasswordForm;
