import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useCurrentUser } from "./useUser/useCurrentUser";
import {
  createUserFavoriteMutationDocument,
  deleteUserFavoriteMutationDocument,
  renameUserFavoriteMutationDocument,
} from "@/graphql/common/queries/user-favorites.queries";

interface UserFavorite {
  id: string;
  pageUrl: string;
  pageName: string;
}

export const useUserFavorites = () => {
  const { currentUser, refetch: refetchUser } = useCurrentUser();
  const [createFavoriteMutation] = useMutation(createUserFavoriteMutationDocument);
  const [deleteFavoriteMutation] = useMutation(deleteUserFavoriteMutationDocument);
  const [renameFavoriteMutation] = useMutation(renameUserFavoriteMutationDocument);

  const favorites = (currentUser?.favorites || []) as UserFavorite[];

  const createFavorite = useCallback(
    async (pageUrl: string, pageName: string) => {
      await createFavoriteMutation({
        variables: {
          userFavoriteCreateInput: {
            pageUrl,
            pageName,
          },
        },
      });
      refetchUser();
    },
    [createFavoriteMutation, refetchUser],
  );

  const deleteFavorite = useCallback(
    async (id: string) => {
      await deleteFavoriteMutation({
        variables: {
          userFavoriteDeleteInput: {
            id,
          },
        },
      });
      refetchUser();
    },
    [deleteFavoriteMutation, refetchUser],
  );

  const renameFavorite = useCallback(
    async (id: string, pageName: string) => {
      await renameFavoriteMutation({
        variables: {
          userFavoriteRenameInput: {
            id,
            pageName,
          },
        },
      });
      refetchUser();
    },
    [renameFavoriteMutation, refetchUser],
  );

  const isFavorite = useCallback(
    (pageUrl: string) => {
      return favorites.some((favorite) => favorite.pageUrl === pageUrl);
    },
    [favorites],
  );

  const getFavoriteId = useCallback(
    (pageUrl: string) => {
      return favorites.find((favorite) => favorite.pageUrl === pageUrl)?.id;
    },
    [favorites],
  );

  return {
    favorites,
    createFavorite,
    deleteFavorite,
    renameFavorite,
    isFavorite,
    getFavoriteId,
  };
};
