import { graphql } from "../../generated/gql";

export const currentUserQueryDocument = graphql(/* GraphQL */ `
  query currentUser {
    currentUser {
      id
      email
      fullname
      username
      profilePicture
      superUser
      favorites {
        id
        pageUrl
        pageName
      }
      workspaceMembershipList {
        userId
        workspaceId
        role
        status
        workspace {
          id
          name
          workspaceUrl
          logo
        }
      }
    }
  }
`);

export const userInWorkspaceQueryDocument = graphql(/* GraphQL */ `
  query userInWorkspace($input: UserGetInput!) {
    userInWorkspace(userGetInput: $input) {
      id
      email
      fullname
      username
      profilePicture
      workspaceMembershipList {
        userId
        workspaceId
        role
        status
        workspace {
          id
          name
          workspaceUrl
          logo
        }
      }
    }
  }
`);
