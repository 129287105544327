const EMPTY_OBJECTIVE_FILTERS = {
  columnFilters: [],
  columnVisibility: {},
  columnOrder: [],
  sorting: [],
  expanded: {},
  rowSelection: {},
  globalFilter: "",
};

export const EMPTY_FILTERS_DATA = {
  objectives: EMPTY_OBJECTIVE_FILTERS,
};
