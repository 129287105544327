import { toast } from "@/components/_ui/use-toast.ts";
import { Slot, type AsChildProps } from "@/components/slot.tsx";

import { useMutation } from "@apollo/client";
import {
  getLabelQueryDocument,
  getLabelGroupQueryDocument,
  updateLabelMutationDocument,
  getLabelListForTeamQueryDocument,
  teamsForUserInWorkspaceQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

type RemoveLabelFromGroupActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  label: {
    id: string;
    labelGroupId?: string | null;
  };
  team: {
    id: string;
    workspaceId: string;
  };
};

export const RemoveLabelFromGroupAction = ({ label, team, asChild, ...props }: RemoveLabelFromGroupActionProps) => {
  const Comp = asChild ? Slot : "button";

  const refetchQueries = useMemo(() => {
    if (!label.labelGroupId) {
      return [
        {
          query: getLabelListForTeamQueryDocument,
          variables: { input: { teamId: team.id } },
        },
        {
          query: getLabelQueryDocument,
          variables: { input: { id: label.id } },
        },
      ];
    }

    return [
      {
        query: getLabelListForTeamQueryDocument,
        variables: { input: { teamId: team.id } },
      },
      {
        query: getLabelGroupQueryDocument,
        variables: { input: { id: label.labelGroupId } },
      },
      {
        query: getLabelQueryDocument,
        variables: { input: { id: label.id } },
      },
      // ISSUE: The useGetLabelListForTeamsInWorkspace hook get the labels by filtering from
      // useTeamListForUserInWorkspace({ workspaceId, userId }). The advantage of this is that
      // we have to essentially refetch all the teams whenever the user adds or removes a label.
      {
        query: teamsForUserInWorkspaceQueryDocument,
        variables: { input: team.workspaceId },
      },
    ];
  }, [label]);

  const [updateLabel] = useMutation(updateLabelMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Kategorie aus Gruppe entfernt",
        description: `Wir haben die Kategorie erfolgreich aus der Gruppe entfernt.`,
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler beim Entfernen der Gruppe aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
    },
    refetchQueries: refetchQueries,
  });

  async function handleClick() {
    await updateLabel({ variables: { input: { id: label.id, labelGroupId: null } } });
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default RemoveLabelFromGroupAction;
