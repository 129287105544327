import { FC, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";

import { Cross2Icon } from "@radix-ui/react-icons";

import { useObjectiveListState } from "../../../../hooks";
import { FilterByIdMode, FilterByIdPayload, FilterByIdPayloadSchema } from "../../../filter-functions";

import { LabelView } from "@/components/_domain/labels";
import { FilterByLabelGroupConfigurationView, FilterByLabelGroupDisplayView } from ".";

export interface FilterByLabelGroupFilterPaneViewProps {
  column: {
    id: string;
  };
  labelGroup: {
    id: string;
  };
}

export const FilterByLabelGroupFilterPaneView: FC<FilterByLabelGroupFilterPaneViewProps> = ({ ...props }) => {
  const { config, table, columnFilters } = useObjectiveListState();
  const [showSelectItemPopover, setShowSelectItemPopover] = useState(false);

  const column = useMemo(() => {
    return table.getColumn(props.column.id);
  }, [table]);

  const filter = useMemo(() => {
    if (!column) {
      return null;
    }
    if (!column.getCanFilter()) {
      return null;
    }
    const filterValue = column.getFilterValue();
    if (!filterValue) {
      return null;
    }
    return filterValue;
  }, [column, columnFilters]);

  const filterByIdFilter = useMemo(() => {
    if (!filter) {
      return null;
    }

    const safePayload = FilterByIdPayloadSchema.safeParse(filter);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [filter]);

  const filterModes: FilterByIdMode[] = useMemo(() => {
    if (!filterByIdFilter) {
      return [];
    }
    return ["included", "not_included"];
  }, [filter]);

  const setFilter = (payload: FilterByIdPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const setFilterMode = (filterMode: FilterByIdMode) => {
    if (!filterByIdFilter) {
      return;
    }
    setFilter({
      ...filterByIdFilter,
      mode: filterMode,
    });
  };

  if (!column) {
    return <></>;
  }

  if (!filter) {
    return <></>;
  }

  if (filterByIdFilter) {
    return (
      <div className="flex flex-row flex-nowrap items-center gap-0 whitespace-nowrap rounded-md border p-0 text-xs font-light">
        <div className="flex flex-row items-center gap-1 pl-1 text-xs">
          <FilterByLabelGroupDisplayView labelGroup={props.labelGroup} />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
            >
              {filterByIdFilter.mode === "included" && filterByIdFilter.idList.length === 1 && <>ist</>}
              {filterByIdFilter.mode === "included" && filterByIdFilter.idList.length !== 1 && <>ist Teil von</>}
              {filterByIdFilter.mode === "not_included" && filterByIdFilter.idList.length === 1 && <>ist nicht</>}
              {filterByIdFilter.mode === "not_included" && filterByIdFilter.idList.length !== 1 && (
                <>ist nicht Teil von</>
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filterModes.map((mode) => {
              return (
                <DropdownMenuItem
                  key={mode}
                  onClick={() => {
                    setFilterMode(mode);
                  }}
                >
                  {mode === "included" && filterByIdFilter.idList.length === 1 && <>ist</>}
                  {mode === "included" && filterByIdFilter.idList.length !== 1 && <>ist Teil von</>}
                  {mode === "not_included" && filterByIdFilter.idList.length === 1 && <>ist nicht</>}
                  {mode === "not_included" && filterByIdFilter.idList.length !== 1 && <>ist nicht Teil von</>}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>

        <Popover open={showSelectItemPopover} onOpenChange={setShowSelectItemPopover}>
          <PopoverTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0  focus-visible:ring-offset-0"
            >
              {filterByIdFilter.idList.length > 1 && (
                <>
                  <div className="mr-1 flex flex-row items-center">
                    {filterByIdFilter.idList.map((id) => {
                      return (
                        <LabelView
                          key={id}
                          label={id ? { id: id } : null}
                          logoOnly={true}
                          className="-m-0.5 h-2.5 w-2.5 rounded-full bg-background"
                        />
                      );
                    })}
                  </div>
                  {`${filterByIdFilter.idList.length} Werten`}
                </>
              )}
              {filterByIdFilter.idList.length === 1 && (
                <LabelView label={filterByIdFilter.idList[0] ? { id: filterByIdFilter.idList[0] } : null} />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0" side={"bottom"} align={"start"}>
            <FilterByLabelGroupConfigurationView
              workspace={config.workspace}
              labelGroup={props.labelGroup}
              column={column}
              onFinished={() => {
                setShowSelectItemPopover(false);
              }}
            />
          </PopoverContent>
        </Popover>
        <Button
          variant="ghost"
          size="xs"
          className="h-6 w-full justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          onClick={() => {
            clearFilter();
          }}
        >
          <Cross2Icon className="h-3.5 w-3.5 text-foreground/60" />
        </Button>
      </div>
    );
  }

  return <></>;
};
