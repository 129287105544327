import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";

import { CurrentWorkspaceType } from "@/hooks/useCurrentWorkspace";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { ColorPicker } from "@/components/color-picker";
import IconCircleGroup from "@/components/_icons/IconCircleGroup";

import { useMutation } from "@apollo/client";
import { getLabelGroupListForWorkspaceQueryDocument, createLabelGroupMutationDocument } from "@/graphql/common";
import { Switch } from "@/components/_ui/switch";

export interface WorkspaceLabelGroupCreateForm extends PropsWithChildren {
  workspace: CurrentWorkspaceType;
  className?: string;
  onSuccess?: () => void;
  onCanceled?: () => void;
}

export const WorkspaceLabelGroupCreateForm: FC<WorkspaceLabelGroupCreateForm> = ({
  workspace,
  onSuccess,
  onCanceled,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [newLabelName, setNewLabelName] = useState("");
  const [newLabelColor, setNewLabelColor] = useState("#95999F");
  const [allowMultiSelect, setAllowMultiSelect] = useState(false);

  const [createLabel] = useMutation(createLabelGroupMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getLabelGroupListForWorkspaceQueryDocument,
        variables: { input: { workspaceId: workspace.id } },
      },
    ],
  });

  async function handleCreateNewLabel() {
    if (newLabelName === "" || newLabelColor === "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Titel und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await createLabel({
      variables: {
        input: {
          title: newLabelName,
          color: newLabelColor,
          workspaceId: workspace.id,
          allowMultiSelect: allowMultiSelect,
        },
      },
    });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <>
      <div className="my-1 rounded border bg-muted p-2">
        <div className="flex flex-row items-center justify-between gap-2">
          <div className="w-9">
            <ColorPicker
              asChild
              onSelect={(color) => {
                setNewLabelColor(color);
              }}
            >
              <Button variant="outline" size="sm">
                <IconCircleGroup className="h-3 w-3" style={{ color: newLabelColor }} />
              </Button>
            </ColorPicker>
          </div>
          <div className="grow">
            <Input
              ref={ref}
              autoFocus
              onChange={(e) => setNewLabelName(e.currentTarget.value)}
              placeholder="Name der Gruppe"
              className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
              style={{
                boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
              }}
            />
          </div>
          <div>
            <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
              Abbrechen
            </Button>
          </div>
          <div>
            <Button
              variant="default"
              size="sm"
              className="cursor-pointer"
              disabled={newLabelName === ""}
              onClick={handleCreateNewLabel}
            >
              Speichern
            </Button>
          </div>
        </div>

        {/* Second row for switch  */}
        <div className="ml-12 mt-2">
          <div className="flex items-center space-x-1 text-sm text-muted-foreground">
            <Switch id="airplane-mode" checked={allowMultiSelect} onCheckedChange={setAllowMultiSelect} />
            <span>Mehrfachauswahl zulassen</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspaceLabelGroupCreateForm;
