import { FC } from "react";

import { useGetKeyResult } from "../../../../../../hooks/api/common/useKeyResult/useGetKeyResult";

import KeyResultChartQuantitative from "./chart/key-result-chart-quantitative";
import KeyResultChartMilestone from "./chart/key-result-chart-milestone";
import IconSquare from "@/components/_icons/IconSquare";
import IconSquareCheck from "@/components/_icons/IconSquareCheck";
import ProgressChip from "./components/progress-chip";
import { useGetProgressSnapshotListForKeyResult } from "@/hooks/api/common";

interface KeyResultProgressViewProps {
  keyResult: {
    id: string;
  };
}

export const KeyResultProgressView: FC<KeyResultProgressViewProps> = (props) => {
  const { quantitativeKeyResult, milestoneKeyResult } = useGetKeyResult({ id: props.keyResult.id });
  const { progressSnapshotList } = useGetProgressSnapshotListForKeyResult({ keyResultId: props.keyResult.id });

  if (!quantitativeKeyResult && !milestoneKeyResult) {
    return <></>;
  }

  if (quantitativeKeyResult) {
    const keyResult = quantitativeKeyResult;

    const divisor = keyResult.targetValue - keyResult.initialValue;
    const progress = divisor === 0 ? 0 : (100 * (keyResult.currentValue - keyResult.initialValue)) / divisor;
    const progressString = progress.toFixed(0);

    return (
      <div className="">
        <div className="flex w-full flex-row flex-wrap">
          {divisor !== 0 && (
            <div className="my-1 -ml-1 rounded-full bg-muted px-2 text-xxs">
              <div>Aktueller Fortschritt: {`${progressString}%`}</div>
            </div>
          )}
        </div>
        <div className="mb-4 flex w-full flex-row items-center justify-between gap-2 text-xs font-medium text-foreground">
          <div>{keyResult.title}</div>
          {/* <div>{progressString}%</div> */}
        </div>
        <div className="h-40">
          <KeyResultChartQuantitative keyResult={keyResult} progressSnapshots={progressSnapshotList} />
        </div>
        <div className="m-1 flex w-full flex-row flex-wrap">
          <ProgressChip label="Startwert" value={keyResult.initialValue} unit={keyResult.unit} />
          <ProgressChip label="Zielwert" value={keyResult.targetValue} unit={keyResult.unit} />
          <ProgressChip label="Aktueller Wert" value={keyResult.currentValue} unit={keyResult.unit} />
        </div>
      </div>
    );
  }

  if (milestoneKeyResult) {
    const keyResult = milestoneKeyResult;

    const divisor = keyResult.targetValue - keyResult.initialValue;
    const progress = divisor === 0 ? 0 : (100 * (keyResult.currentValue - keyResult.initialValue)) / divisor;
    const progressString = progress.toFixed(0);

    return (
      <div className="">
        <div className="flex w-full flex-row flex-wrap">
          {divisor !== 0 && (
            <div className="my-1 -ml-1 rounded-full bg-muted px-2 text-xxs">
              <div>Aktueller Fortschritt: {`${progressString}%`}</div>
            </div>
          )}
        </div>
        <div className="mb-4 flex w-full flex-row items-center justify-between gap-2 text-xs font-medium text-foreground">
          <div>{keyResult.title}</div>
          {/* <div>{progressString}%</div> */}
        </div>
        <div className="h-40">
          <KeyResultChartMilestone keyResult={keyResult} progressSnapshots={progressSnapshotList} />
        </div>

        <div>
          <div className="font-medium text-foreground">
            Meilensteine: ({keyResult.currentValue}/{keyResult.targetValue})
          </div>
          <div className="mt-2 flex flex-col gap-1">
            {keyResult.properties.milestones.map((milestone) => {
              return (
                <div key={milestone.id} className="flex flex-row items-center gap-1 text-xs font-light text-foreground">
                  {!milestone.completed && <IconSquare width={10} height={10} />}
                  {milestone.completed && <IconSquareCheck width={10} height={10} />}
                  <span>{milestone.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default KeyResultProgressView;
