import { FC, useEffect, useMemo, useState } from "react";

import { cn } from "@/lib/utils";

import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/_ui/command";
import { Checkbox } from "@/components/_ui/checkbox";

import { FilterByIdList, FilterByIdPayload, FilterByIdPayloadSchema } from "../../../filter-functions";

import { useObjectiveListState } from "../../../../hooks";

import { ALLOWED_HEALTH_TYPES, HealthView } from "@/components/_domain/progress-snapshot";

export interface FilterByHealthConfigurationViewProps {
  column: {
    id: string;
  };
  onFinished: () => void;
}

export const FilterByHealthConfigurationView: FC<FilterByHealthConfigurationViewProps> = ({ onFinished, ...props }) => {
  const columnId = props.column.id;
  const { table, columnFilters } = useObjectiveListState();
  const healthList = ALLOWED_HEALTH_TYPES;

  const [selectedIds, setSelectedIds] = useState<FilterByIdList | null>(null);

  const column = useMemo(() => {
    return table.getColumn(columnId);
  }, [table]);

  const filterValue = useMemo(() => {
    const filterValue = column?.getFilterValue();

    const safePayload = FilterByIdPayloadSchema.safeParse(filterValue);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [column, columnFilters]);

  useEffect(() => {
    if (!filterValue) {
      return;
    }
    setSelectedIds(filterValue.idList);
  }, []);

  useEffect(() => {
    if (!selectedIds) {
      return;
    }

    if (selectedIds.length === 0) {
      clearFilter();
      return;
    }

    setFilter({
      type: "filter_by_id",
      mode: filterValue?.mode ?? "included",
      idList: selectedIds,
    });
  }, [selectedIds]);

  const setFilter = (payload: FilterByIdPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const handleSelect = (healthId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== healthId);
    setSelectedIds([...updatedIdList, healthId]);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  const handleDeselect = (healthId: string | null, shouldFinish?: boolean) => {
    const selectedIdList = selectedIds ?? [];
    const updatedIdList = selectedIdList.slice().filter((id) => id !== healthId);

    setSelectedIds(updatedIdList);

    if (shouldFinish) {
      setTimeout(function () {
        onFinished();
      }, 1);
    }
  };

  return (
    <Command>
      <CommandInput placeholder={"Health"} />
      <CommandList>
        <CommandGroup>
          {[null].map((_) => {
            const isSelected = !!selectedIds && !!selectedIds.includes(null);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={null}
                value={"Nicht zugewiesen"}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(null, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(null);
                  }}
                />
                <HealthView type={null} className="h-3.5 w-3.5 rounded-full" />
              </CommandItem>
            );
          })}

          {healthList.map((health) => {
            const isSelected = !!selectedIds && !!selectedIds.find((id) => id === health);
            const handleAction = isSelected ? handleDeselect : handleSelect;

            return (
              <CommandItem
                key={health}
                value={health}
                className="group flex flex-row items-center gap-1 p-1 py-1.5 hover:cursor-pointer"
                onSelect={() => {
                  handleAction(health, true);
                }}
              >
                <Checkbox
                  className={cn([
                    "rounded-md border border-gray-300 group-hover:opacity-100",
                    isSelected ? "" : " opacity-0",
                  ])}
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(health);
                  }}
                />
                <HealthView type={health} className="h-3.5 w-3.5" />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};
