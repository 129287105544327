import { useMutation } from "@apollo/client";
import {
  createAssigneeMutationDocument,
  getAssigneeByOwnerQueryDocument,
  getAssigneeQueryDocument,
  getObjectiveQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

export interface UseCreateAssigneeProps {
  ownerId: string;
  ownerType: "OBJECTIVE";
  newUserId?: string;
  oldUserId?: string;
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useCreateAssignee = ({
  ownerId,
  ownerType,
  newUserId,
  oldUserId,
  onCompleted,
  onError,
}: UseCreateAssigneeProps) => {
  const ownerQueryDocument = useMemo(() => {
    if (ownerType === "OBJECTIVE") {
      return {
        query: getObjectiveQueryDocument,
        variables: { input: { id: ownerId } },
      };
    }
    return null;
  }, [ownerId, ownerType]);

  const refetchQueries = useMemo(() => {
    const refetchQueries = [];

    if (ownerQueryDocument) {
      refetchQueries.push(ownerQueryDocument);
    }

    return refetchQueries;
  }, [ownerQueryDocument, newUserId, oldUserId]);

  const [createAssignee] = useMutation(createAssigneeMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
    update: (cache, { data }) => {
      const assignee = data?.createAssignee;
      if (!assignee) {
        return;
      }
      cache.writeQuery({
        query: getAssigneeQueryDocument,
        variables: {
          input: { id: assignee.id },
        },
        data: {
          assignee: assignee,
        },
      });
      cache.writeQuery({
        query: getAssigneeByOwnerQueryDocument,
        variables: {
          input: { ownerId: assignee.ownerId },
        },
        data: {
          assigneeByOwner: assignee,
        },
      });
    },
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    createAssignee,
  };
};
