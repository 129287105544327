import React from "react";
import ReactDOM from "react-dom/client";

import "tailwindcss/tailwind.css";
import "inter-ui/inter.css";
import "./index.css";

import { apolloClient } from "./services/apollo.service/apollo.service";
import { posthogService } from "./services/posthog.service";

import { ThemeProvider } from "./hooks/useTheme";
import { LayoutProvider } from "./hooks/useLayout";
import { AuthProvider } from "@/hooks/useAuth";
import { ApolloProvider } from "@apollo/client";
import Router from "@/routes/Router.tsx";

import "@/lib/sentry";
import { SavedViewsProvider } from "./hooks/useSavedViews";

posthogService.capture("APP_LOADED");

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider>
      <LayoutProvider>
        <AuthProvider>
          <ApolloProvider client={apolloClient}>
            <SavedViewsProvider>
              <Router />
            </SavedViewsProvider>
          </ApolloProvider>
        </AuthProvider>
      </LayoutProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
