import { FC, PropsWithChildren, useState } from "react";

import { Button } from "@/components/_ui/button";

import DeleteConfirmation from "@/components/delete-confirmation";

import { useDeleteStatus } from "@/hooks/api/common";
import { toast } from "@/components/_ui/use-toast";

import IconTrash from "@/components/_icons/IconTrash";

export interface StatusButtonDeleteProps extends PropsWithChildren {
  team: {
    id: string;
  };
  status: {
    id: string;
    instances: Array<unknown>;
  };
  onSuccess?: () => void;
}

export const StatusButtonDelete: FC<StatusButtonDeleteProps> = ({ team, status, onSuccess }) => {
  const [deleteConfirmationisOpen, setDeleteConfirmationisOpen] = useState(false);
  const { deleteStatus } = useDeleteStatus({
    teamId: team.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  function handleDeleteButtonPressed() {
    if (status.instances.length > 0) {
      const s = status.instances.length === 1 ? "ist" : "sind";
      const t = status.instances.length === 1 ? "Objekt" : "Objekten";
      toast({
        title: "Fehler",
        description: `Der Status ${s} ${status.instances.length} ${t} zugewiesen. Bitten entfernen Sie den Status von den zugewiesenen Objekten bevor Sie ihn löschen. `,
        variant: "error",
      });
      return;
    }

    setDeleteConfirmationisOpen(true);
  }

  async function handleDeleteStatusConfirmed() {
    await deleteStatus({ variables: { input: { id: status.id } } });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="text-muted-foreground/80 hover:text-foreground/80"
        onClick={handleDeleteButtonPressed}
      >
        <IconTrash className="h-2.5 w-2.5" />
      </Button>
      <DeleteConfirmation
        open={deleteConfirmationisOpen}
        onOpenChange={setDeleteConfirmationisOpen}
        onConfirm={handleDeleteStatusConfirmed}
        title={`Sind Sie sich sicher, dass Sie diesen Status löschen möchten?`}
        description={`Diese Aktion kann nicht rückgängig gemacht werden. Der Status wird unwideruflich gelöscht.`}
      />
    </>
  );
};

export default StatusButtonDelete;
