import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { workspaceByUrlQueryDocument } from "@/graphql/common";

export interface UseGetWorkspaceByUrlProps {
  workspaceUrl?: string;
}

export const useWorkspaceGetByUrl = (props: UseGetWorkspaceByUrlProps) => {
  const queryResult = useQuery(workspaceByUrlQueryDocument, {
    variables: { input: props.workspaceUrl ?? "" },
    skip: !props.workspaceUrl,
  });

  const workspace = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }
    return queryResult.data.workspaceByUrl;
  }, [queryResult.data]);

  return {
    queryResult,
    workspace,
  };
};
