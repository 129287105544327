import { FC, useEffect, useRef, useState } from "react";

import { apolloClient } from "@/services/apollo.service";
import { createSignedUploadUrlMutationDocument } from "@/graphql/common";
import { UploadUrl } from "@/components/input-image-upload";
import LoadingDots from "@/components/loading-dots";
import IconCheckCircleFilled from "@/components/_icons/IconCheckCircleFilled";
import IconXCircleFilled from "@/components/_icons/IconXCircleFilled";


interface UploadResult {
    file: File;
    url: string;
    error?: Error | undefined
}

export interface FileStorageUploaderProps {
    className?: string;
    file: File;
    onFileUploadFinished?: (result: UploadResult) => void;
}

export const FileStorageUploader: FC<FileStorageUploaderProps> = ({ file, onFileUploadFinished }) => {
    const effectTriggeredRef = useRef(false); // make sure it only runs once

    const [loading, setLoading] = useState<boolean>(false)
    const [completed, setCompleted] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        if (!effectTriggeredRef.current) {
            effectTriggeredRef.current = true;
            startFileUpload(file)
        }
    }, [])

    async function getSignedUploadUrl(): Promise<UploadUrl | null> {
        const fileExtension = file.name.split('.').pop() ?? ""

        const { data } = await apolloClient.mutate({
            mutation: createSignedUploadUrlMutationDocument,
            fetchPolicy: "no-cache",
            variables: {
                input: {
                    fileExtension: fileExtension,
                    type: file.type
                }
            }
        });

        if (!data) {
            return null;
        }

        return data.createSignedUploadUrl;
    }

    async function uploadFile(file: File, url: string) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", url, true);
            xhr.onload = () => {
                const status = xhr.status;
                if (status === 200) {
                    resolve(true);
                } else {
                    reject(new Error("Upload failed."));
                }
            };
            xhr.onerror = () => {
                reject(new Error("Upload failed."));
            };
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.setRequestHeader("Cache-Control", "public, max-age=604800, immutable");
            xhr.send(file);
        });
    }

    async function startFileUpload(file: File) {
        if (!file) {
            return;
        }
        if (loading) {
            return;
        }
        if (completed) {
            return;
        }

        setLoading(true);

        // 1. Get Upload Url
        const uploadUrl = await getSignedUploadUrl()

        if (!uploadUrl) {
            const error = new Error("Could not fetch a valid upload URL from server")
            setError(error);
            setLoading(false);
            setCompleted(true);


            if (onFileUploadFinished) {
                onFileUploadFinished({
                    file: file,
                    url: "",
                    error: error
                })
            }

            return;
        }

        // 3. Upload Image
        const success = await uploadFile(file, uploadUrl.signedUrl);
        const error = success ? null : new Error("Could not upload file to storage")

        setError(error)
        setCompleted(true)
        setLoading(false);


        if (onFileUploadFinished) {
            onFileUploadFinished({
                file: file,
                url: uploadUrl.permaUrl,// this is the unsigned URL
                error: error ?? undefined,
            })
        }

    }

    return (
        <div className="flex flex-row items-start justify-between py-2 pr-2 gap-4 w-64 w-max-64 ">
            <div className="text-xs break-words shrink w-max-[12rem] text-ellipsis overflow-hidden">{file.name}</div>
            <div className="">
                {!completed &&
                    <LoadingDots className="self-start" />
                }
                {completed && error &&
                    <IconXCircleFilled className="toast-error-icon text-rose-500" />
                }
                {completed && !error &&
                    <IconCheckCircleFilled className="toast-success-icon text-green-500" />
                }
            </div>
        </div>

    );
};
