import { FC, PropsWithChildren, useEffect, useMemo } from "react";

import { Outlet, useParams } from "react-router-dom";
import SidebarApp from "@/components/sidebar-app/sidebar-app";

import NotFound from "@/routes/NotFound.tsx";
import ProductlaneWidget from "@/components/productlane-widget.tsx";

// Make sure all child components have access to the workspace object
import { WorkspaceProvider } from "@/hooks/useCurrentWorkspace";
import { PreLoader } from "@/components/preloader";

import { useCurrentUser, useWorkspaceGetByUrl } from "@/hooks/api/common";
import { removeWorkspaceSettings } from "@/services/workspace.service";

export interface InnerPageProps extends PropsWithChildren {
  workspaceUrl: string;
}
const InnerPage: FC<InnerPageProps> = ({ workspaceUrl }) => {
  const { currentUser } = useCurrentUser();

  const { workspace, queryResult } = useWorkspaceGetByUrl({ workspaceUrl: workspaceUrl });

  const workspaceWithMembership = useMemo(() => {
    if (!workspace) {
      return null;
    }

    const wsm = workspace.workspaceMembershipList.find((wsm) => wsm.userId === currentUser?.id);

    return {
      id: workspace.id,
      name: workspace.name,
      workspaceUrl: workspace.workspaceUrl,
      logo: workspace.logo,
      workspaceMembership: {
        role: wsm?.role ?? "USER",
        status: wsm?.status ?? "SUSPENDED",
      },
    };
  }, [workspace]);

  useEffect(() => {
    if (queryResult.error) {
      removeWorkspaceSettings();
    }
  }, [queryResult]);

  if (!currentUser) {
    return <></>;
  }

  if (queryResult.loading) {
    return <></>;
  }

  if (queryResult.error) {
    return <NotFound />;
  }

  if (!workspaceWithMembership) {
    return <NotFound />;
  }

  return (
    <WorkspaceProvider workspace={workspaceWithMembership}>
      <PreLoader workspace={workspaceWithMembership} user={currentUser}>
        <div className="flex h-full min-h-screen w-full flex-row items-stretch overflow-hidden bg-background text-foreground">
          <SidebarApp workspace={workspaceWithMembership} />

          <div className="flex min-w-0 shrink grow basis-0 flex-col">
            <Outlet />
          </div>
        </div>
      </PreLoader>
      <ProductlaneWidget />
    </WorkspaceProvider>
  );
};

export default function Page() {
  const { workspaceUrl } = useParams();

  if (!workspaceUrl) {
    return <NotFound />;
  }

  return <InnerPage workspaceUrl={workspaceUrl} />;
}
