import { FC, useState } from "react";

import TextareaAutosize from "react-textarea-autosize";
import { RichTextEditor } from "@/components/rich-text-editor";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { toast } from "@/components/_ui/use-toast";
import { Button } from "@/components/_ui/button";
import AvatarWorkspace from "@/components/_domain/workspace/avatar-workspace";
import { Badge } from "@/components/_ui/badge";

import { Link } from "react-router-dom";
import { Switch } from "@/components/_ui/switch";
import { useCreateObjective } from "@/hooks/api/common";

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export interface ObjectiveCreateFormProps {
  className?: string;
  workspace: {
    id: string;
    name: string;
    workspaceUrl: string;
    profilePicture?: string;
  };
  team: {
    id: string;
  };
  triggerCloseDialog: () => void;
}

export const ObjectiveCreateForm: FC<ObjectiveCreateFormProps> = ({ workspace, team, triggerCloseDialog }) => {
  const [loading, setLoading] = useState(false);
  const [removedFromDom, setRemovedFromDom] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });
  const { createObjective } = useCreateObjective({
    teamId: team.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });
  const [createMore, setCreateMore] = useState(false);

  async function onRichTextEditorChange(value: string, json: string) {
    if (value === "") {
      form.setValue("description", "");
    } else {
      form.setValue("description", json);
    }
  }

  async function handleCreateObjective() {
    if (loading) {
      return;
    }
    const { title, description } = form.getValues();

    if (!title || title.length < 1) {
      toast({
        title: "Fehler",
        description: "Der Name des Ziels darf nicht leer sein. Bitte geben Sie einen Namen ein.",
        variant: "error",
      });
      return;
    }

    setLoading(true);
    await createObjective({
      variables: {
        input: {
          teamId: team.id,
          title: title,
          properties: description,
        },
      },
    });
  }

  function onUpdateCompleted(objectiveFragment: { id: string } | undefined) {
    setLoading(false);
    const { title } = form.getValues();

    toast({
      title: "Ziel erstellt",
      description: (
        <div className="gap-21 flex flex-col">
          <span>{title} wurde erstellt</span>
          {!!objectiveFragment && (
            <Link to={`${workspace.workspaceUrl}/${team.id}/strategic-goals/${objectiveFragment.id}`}>
              Jetzt anzeigen
            </Link>
          )}
        </div>
      ),
      variant: "success",
    });

    form.reset();
    forceRichTextEditorRerender();

    if (!createMore) {
      triggerCloseDialog();
    } else {
      form.setFocus("title");
    }
  }

  function onUpdateError() {
    setLoading(false);
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  function forceRichTextEditorRerender() {
    setRemovedFromDom(true);
    setTimeout(() => {
      setRemovedFromDom(false);
    }, 1);
  }

  return (
    <form>
      <div className="grid w-full">
        {/* Header */}
        <div className="flex flex-row items-center gap-1">
          <Badge variant="outline" className="py-2 opacity-40">
            <div className="flex flex-row items-center gap-1">
              <AvatarWorkspace workspace={workspace} className="h-5 w-5 text-xxs" />
              <span className="ml-1 overflow-hidden text-ellipsis font-normal">{workspace.name}</span>
            </div>
          </Badge>
          <span className="ml-1 mr-1">{"›"}</span>
          <div className="block w-32 overflow-hidden truncate text-ellipsis whitespace-nowrap text-xs">Neues Ziel</div>
        </div>
        {/* Main Part */}
        <div className="mx-auto max-h-[70vh] w-full overflow-y-scroll md:max-h-[40vh]">
          <TextareaAutosize
            autoFocus
            id="title"
            defaultValue={"Neues Ziel"}
            placeholder="Name des Ziels"
            className="font-base mt-3 w-full resize-none appearance-none overflow-hidden bg-transparent px-3 py-0 text-lg focus:outline-none"
            {...form.register("title")}
          />
          {!removedFromDom && <RichTextEditor onChange={onRichTextEditorChange} hideFloatingMenu={false} />}
        </div>
        <div className="border-b"></div>
        <div className="flex flex-row items-center justify-end gap-4 pt-2">
          <div>
            <div className="flex items-center space-x-1 text-sm text-muted-foreground">
              <Switch id="airplane-mode" checked={createMore} onCheckedChange={setCreateMore} />
              <span>Mehr erstellen</span>
            </div>
          </div>
          <Button type="button" size="sm" onClick={() => handleCreateObjective()}>
            Ziel erstellen
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ObjectiveCreateForm;
