import { useMemo } from "react";

import Header from "@/components/header";

import { UnitsDatatable, columns } from "@/components/_domain/unit/units-datatable";

import UnitCreateDialog from "./unit-create-dialog";
import { useTeamById, useGetUnitList, useCurrentUser } from "@/hooks/api/common";
import { useParams } from "react-router-dom";
import NotFound from "@/routes/NotFound";

interface InnerPageProps {
  team: {
    id: string;
  };
}

function InnerPage(props: InnerPageProps) {
  const { unitList } = useGetUnitList({ teamId: props.team.id });

  const { currentUser } = useCurrentUser();

  const { userHasEditRights } = useTeamById({ id: props.team.id });

  const hasEditRights = useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return userHasEditRights(currentUser.id);
  }, [currentUser, userHasEditRights]);

  return (
    <main className="relative max-h-screen place-items-stretch overflow-auto">
      <Header className="border-none"></Header>

      <div className="overflow-y-scroll pb-24" style={{ maxHeight: "calc(100vh - 56px)" }}>
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <div className="flex flex-col gap-1.5">
              <div className="text-2xl">Einheiten</div>
              <div className="text-sm text-muted-foreground">Verwalten Sie die Einheiten Ihres Teams</div>
            </div>

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <div>
                <p className="font-light text-muted-foreground">
                  Verwalten Sie die Einheiten, die innerhalb Ihres Teams verwendet werden können um den Fortschritt von
                  Zielen zu messen.
                </p>
              </div>
              <div className="my-8"></div>
              {hasEditRights && (
                <div className="mb-8 flex shrink grow flex-row items-center justify-end gap-3">
                  <div className="flex flex-row gap-6"></div>
                  <UnitCreateDialog team={props.team} />
                </div>
              )}

              <UnitsDatatable
                columns={hasEditRights ? columns : columns.filter((c) => c.id !== "actions")}
                data={unitList}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default function Page() {
  const { teamId } = useParams();

  if (!teamId) {
    return <NotFound />;
  }

  return <InnerPage team={{ id: teamId }} />;
}
