import { FC, useCallback, useMemo, useState } from "react";

import { useDate } from "@/hooks/useDate";
import { useGetKeyResult } from "@/hooks/api/common";
import { InputDateSmall } from "@/components/input-date-small";

export interface ProgressSnapshotDateInputProps {
  keyResult?: {
    id: string;
  } | null;
  value?: string;
  onValueChange?: (value: string | null) => void;
}

export const ProgressSnapshotDateInput: FC<ProgressSnapshotDateInputProps> = ({ onValueChange, ...props }) => {
  const { TODAY } = useDate();
  const [value, setValue] = useState<string>(props.value ?? TODAY.toISOString());
  const { keyResult } = useGetKeyResult({ id: props.keyResult?.id });

  const fieldWasModified = useCallback(() => {
    if (value !== TODAY.toISOString()) {
      return true;
    }
    return false;
  }, [TODAY, value]);

  const minDate = useMemo(() => {
    if (!keyResult) {
      return undefined;
    }

    return new Date(keyResult.startDate);
  }, [keyResult]);

  return (
    <InputDateSmall
      placeholder="Datum"
      modified={fieldWasModified()}
      defaultValue={value}
      onValueChange={(value) => {
        setValue(value ?? TODAY.toISOString());
        if (onValueChange) {
          onValueChange(value);
        }
      }}
      minDate={minDate}
      maxDate={TODAY}
    />
  );
};

export default ProgressSnapshotDateInput;
