import { toast } from "@/components/_ui/use-toast.ts";
import { Slot, type AsChildProps } from "@/components/slot.tsx";

import {
  deleteLabelGroupMutationDocument,
  getLabelGroupListForTeamQueryDocument,
  teamsForUserInWorkspaceQueryDocument,
} from "@/graphql/common";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";

type DeletLabelGroupActionProps = AsChildProps<React.ButtonHTMLAttributes<HTMLButtonElement>> & {
  style?: React.CSSProperties;
  className?: string;
  labelGroup: {
    id: string;
  };
  team: {
    id: string;
    workspaceId: string;
  };
  forceDelete?: boolean;
};

export const DeletLabelGroupAction = ({
  labelGroup,
  team,
  asChild,
  forceDelete,
  ...props
}: DeletLabelGroupActionProps) => {
  const Comp = asChild ? Slot : "button";
  const [deleteLabelGroup] = useMutation(deleteLabelGroupMutationDocument, {
    onCompleted: () => {
      toast({
        title: "Label gelöscht",
        description: "Wir haben das Label erfolgreich gelöscht.",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        title: "Fehler",
        description:
          "Leider ist ein Fehler beim Löschen des Labels aufgetreten. Versuchen Sie die Seite neu zu laden und probieren Sie es erneut.",
        variant: "error",
      });
    },
    refetchQueries: [
      {
        query: getLabelGroupListForTeamQueryDocument,
        variables: { input: { teamId: team.id } },
      },
      // ISSUE: The useGetLabelListForTeamsInWorkspace hook get the labels by filtering from
      // useTeamListForUserInWorkspace({ workspaceId, userId }). The advantage of this is that
      // we have to essentially refetch all the teams whenever the user adds or removes a label.
      {
        query: teamsForUserInWorkspaceQueryDocument,
        variables: { input: team.workspaceId },
      },
    ],
  });

  useEffect(() => {
    if (forceDelete) {
      triggerDeleteLabel();
    }
  }, [forceDelete]);

  async function triggerDeleteLabel() {
    await deleteLabelGroup({ variables: { input: { id: labelGroup.id } } });
  }

  function handleClick() {
    triggerDeleteLabel();
  }

  return <Comp {...props} onClick={handleClick} />;
};

export default DeletLabelGroupAction;
