import { FC, useCallback, useContext, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import Header from "@/components/header";
import NotFound from "@/routes/NotFound";

import { ObjectiveEditDropdown } from "./objective-edit-dropdown";
import { WorkspaceContext, useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";
import { ObjectiveSheet } from "./objective-sheet";

import { ObjectiveTabNavigationBar } from "./objective-tabs/objective-tab-navigation-bar";
import { ObjectiveTabView } from "./objective-tabs";
import { ObjectiveUIContextProvider, useObjectiveUIState } from "./hooks/useObjectiveUIState";
import { useGetObjective } from "../../../../hooks/api/common/useObjective/useGetObjective";
import { ObjectiveParentLabel } from "./objective-parent-label";
import { ProgressUpdateUIStateContextProvider } from "./hooks/useCreateProgressUpdateUIState";
import { ProgressSnapshotDialog } from "./progress-snapshot";
import { TeamView } from "@/components/_domain/team/team-view";
import { useLayout } from "@/hooks/useLayout";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";
interface InnerPageProps {
  workspaceUrl: string;
  objectiveId: string;
}

const InnerPage: FC<InnerPageProps> = ({ workspaceUrl, objectiveId }) => {
  const { sidebarState } = useLayout();

  const { objective } = useGetObjective({ id: objectiveId });
  const { currentWorkspace } = useContext(WorkspaceContext);

  const location = useLocation();
  const { setShowCreateChildObjectiveView, selectedTab, setSelectedTab } = useObjectiveUIState();

  useEffect(() => {
    hideCreateChildObjective();
  }, [location]);

  const makeTeamUrl = useCallback(
    (path: string) => {
      if (!objective) {
        return "";
      }

      return `/${workspaceUrl}/teams/${objective.teamId}/${path}`;
    },
    [objective, currentWorkspace],
  );

  function showCreateChildObjective() {
    if (selectedTab !== "details") {
      setSelectedTab("details");
    }
    setTimeout(function () {
      // use setTimeout to make sure the tab is switched before
      // setShowChildObjectiveView is called
      setShowCreateChildObjectiveView(true);
    }, 1);
  }

  function hideCreateChildObjective() {
    setShowCreateChildObjectiveView(false);
  }

  if (!objective) {
    return <></>;
  }

  return (
    <div className="flex h-full w-full flex-row">
      <div className="flex h-screen max-h-screen w-full flex-col overflow-y-hidden">
        <Header className={sidebarState?.isFixed ? "pl-6" : "pl-12"}>
          <div className="flex flex-row items-center gap-2">
            <Link to={makeTeamUrl("")}>
              <TeamView team={{ id: objective.teamId }} />
            </Link>
            <div>›</div>
            <Link to={makeTeamUrl("")}>
              <div className="flex max-w-[8rem] flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
                Ziele
              </div>
            </Link>
            <div>›</div>
            <div className="block max-w-[8rem] overflow-hidden truncate text-ellipsis whitespace-nowrap">
              {objective.title !== "" ? objective.title : <span className="text-muted-foreground">Kein Titel</span>}
            </div>
            <div>
              <ObjectiveEditDropdown
                objective={objective}
                workspace={currentWorkspace}
                callbacks={{ showCreateChildObjective }}
              />
            </div>
            <FavoriteButton pageName={objective.title} />
          </div>
          <div className="mr-4 flex flex-row items-center">
            <ProgressSnapshotDialog objective={objective} workspace={currentWorkspace} />
          </div>
        </Header>

        <div className="flex w-full flex-row flex-wrap items-center px-6 py-6 text-sm">
          {!!objective.parentId && (
            <div className="w-full">
              <div className="mb-4">
                <ObjectiveParentLabel objective={{ id: objective.parentId }} />
              </div>
            </div>
          )}
          <div className="mb-2 text-lg">
            {objective.title !== "" ? objective.title : <span className="text-muted-foreground">Kein Titel</span>}
          </div>
          <ObjectiveTabNavigationBar />
        </div>

        <div className="overflow-y-scroll pb-24">
          <div className="mx-auto flex grow flex-col items-stretch px-6">
            <ObjectiveTabView objective={objective} workspace={currentWorkspace} tabId={selectedTab} />
          </div>
        </div>
      </div>
      <ObjectiveSheet objective={objective} />
    </div>
  );
};

export const Page = () => {
  const { workspaceUrl, id, tab } = useParams();
  const { currentWorkspace } = useCurrentWorkspace();

  if (!workspaceUrl) {
    return <NotFound />;
  }

  if (!id) {
    return <NotFound />;
  }

  return (
    <ObjectiveUIContextProvider
      workspace={currentWorkspace}
      objective={{ id: id }}
      selectedTab={tab ? { id: tab } : undefined}
    >
      <ProgressUpdateUIStateContextProvider>
        <InnerPage workspaceUrl={workspaceUrl} objectiveId={id} />
      </ProgressUpdateUIStateContextProvider>
    </ObjectiveUIContextProvider>
  );
};

export default Page;
