import { useMutation } from "@apollo/client";
import {
  deletePriorityInstanceMutationDocument,
  getObjectiveQueryDocument,
  getPriorityInstancebyOwnerQueryDocument,
} from "@/graphql/common";
import { useMemo } from "react";

export interface UseDeletePriorityInstanceProps {
  ownerId: string;
  ownerType: "OBJECTIVE";
  onCompleted?: () => void;
  onError?: (e: Error) => void;
}

export const useDeletePriorityInstance = ({
  ownerId,
  ownerType,
  onCompleted,
  onError,
}: UseDeletePriorityInstanceProps) => {
  const ownerQueryDocument = useMemo(() => {
    if (ownerType === "OBJECTIVE") {
      return {
        query: getObjectiveQueryDocument,
        variables: { input: { id: ownerId } },
      };
    }
    return null;
  }, [ownerId, ownerType]);

  const refetchQueries = useMemo(() => {
    const queries = [
      {
        query: getPriorityInstancebyOwnerQueryDocument,
        variables: { input: { ownerId: ownerId } },
      },
    ];

    if (ownerQueryDocument) {
      return [...queries, ownerQueryDocument];
    }
    return [...queries];
  }, [ownerId, ownerQueryDocument]);

  const [deletePriorityInstance] = useMutation(deletePriorityInstanceMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: refetchQueries,
  });

  function onUpdateCompleted() {
    if (onCompleted) {
      onCompleted();
    }
  }
  function onUpdateError(e: Error) {
    if (onError) {
      onError(e);
    }
  }

  return {
    deletePriorityInstance,
  };
};
