import { useCallback, useMemo } from "react";

import { useQuery } from "@apollo/client";
import { teamByIdQueryDocument } from "@/graphql/common";

export interface UseGetTeamProps {
  id?: string;
}

export const useTeamById = (props: UseGetTeamProps) => {
  const queryResult = useQuery(teamByIdQueryDocument, { variables: { input: props.id ?? "" }, skip: !props.id });

  const team = useMemo(() => {
    if (!queryResult.data) {
      return null;
    }
    return queryResult.data.teamById;
  }, [queryResult.data]);

  const userHasEditRights = useCallback(
    (userId: string) => {
      if (!team) {
        return false;
      }

      const teamMembership = team.teamMembershipList.find((tm) => tm?.userId === userId);

      if (!teamMembership) {
        return false;
      }

      return teamMembership.status === "ACTIVE" && teamMembership.role === "ADMIN";
    },
    [team],
  );

  return {
    queryResult,
    team,
    userHasEditRights,
  };
};
