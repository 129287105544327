import React, { useState, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as Switch from "@radix-ui/react-switch";
import { Bookmark, Trash2, PlusIcon, RotateCcw, Edit, MoreHorizontal, Copy, Save } from "lucide-react";
import { SavedView, SavedViewInput, useSavedViews, EMPTY_SAVED_VIEW } from "../../hooks/useSavedViews";
import { Button } from "../_ui/button";
import { Input } from "../_ui/input";
import { Label } from "../_ui/label";
import { toast } from "../_ui/use-toast";
import { cn } from "../../lib/utils";
import { EMPTY_FILTERS_DATA } from "@/lib/consts/emptyFiltersData";
import * as Popover from "@radix-ui/react-popover";

interface SaveViewModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (data: SavedViewInput) => void;
  initialData?: Partial<SavedView>;
}

const SaveViewModal: React.FC<SaveViewModalProps> = ({ open, onOpenChange, onSave, initialData }) => {
  const [name, setName] = useState("");
  const [isDefault, setIsDefault] = useState(false);

  // Reset form when modal opens/closes or initialData changes
  useEffect(() => {
    if (open && initialData) {
      setName(initialData.name || "");
      setIsDefault(initialData.isDefault || false);
    } else if (!open) {
      setName("");
      setIsDefault(false);
    }
  }, [open, initialData]);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({
      name,
      isDefault,
      filterData: initialData?.filterData || EMPTY_FILTERS_DATA,
      url: window.location.pathname,
    });
    onOpenChange(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="backdrop-blur-sm fixed inset-0 bg-black/50" />
        <Dialog.Content className="shadow-lg fixed left-1/2 top-1/2 w-[400px] -translate-x-1/2 -translate-y-1/2 rounded-lg border bg-white">
          <div className="border-b p-4">
            <Dialog.Title className="text-lg font-semibold text-foreground">
              {initialData ? "Ansicht bearbeiten" : "Ansicht speichern"}
            </Dialog.Title>
            <Dialog.Description className="mt-1 text-sm text-muted-foreground">
              {initialData
                ? "Bearbeiten Sie die Details der gespeicherten Ansicht."
                : "Speichern Sie die aktuelle Ansicht für späteren Zugriff."}
            </Dialog.Description>
          </div>

          <form onSubmit={handleSave} className="space-y-4 p-4">
            <div className="space-y-2">
              <Label htmlFor="name" className="text-sm font-medium">
                Name
              </Label>
              <Input
                id="name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                className="w-full"
                placeholder="Geben Sie einen Namen für die Ansicht ein"
              />
            </div>

            <div className="flex items-center gap-2">
              <Switch.Root
                checked={isDefault}
                onCheckedChange={setIsDefault}
                className="relative h-5 w-10 cursor-pointer rounded-full bg-muted transition-colors data-[state=checked]:bg-primary"
              >
                <Switch.Thumb className="block h-4 w-4 translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[22px]" />
              </Switch.Root>
              <Label className="text-sm font-medium">Als Standardansicht festlegen</Label>
            </div>

            <div className="border-t pt-4">
              <div className="flex justify-end gap-2">
                <Button variant="outline" onClick={() => onOpenChange(false)} type="button" className="px-4">
                  Abbrechen
                </Button>
                <Button type="submit" disabled={!name} className="px-4">
                  {initialData ? "Aktualisieren" : "Speichern"}
                </Button>
              </div>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const SavedViewsMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [editingView, setEditingView] = useState<SavedView | undefined>(undefined);
  const [subMenuOpen, setSubMenuOpen] = useState<string | null>(null);
  const {
    savedViews,
    currentView,
    createSavedView,
    updateSavedView,
    deleteSavedView,
    saveCurrentView,
    resetCurrentView,
    isDirty,
    handleSwitchView,
  } = useSavedViews();

  // Filter views for current URL
  const currentUrl = window.location.pathname;
  const filteredViews = savedViews.filter((view) => view.url === currentUrl);

  // Set default view or first view when loading the page
  useEffect(() => {
    const defaultView = filteredViews.find((view) => view.isDefault);
    const firstView = filteredViews[0];
    if (defaultView) {
      handleSwitchView(defaultView);
    } else if (firstView) {
      handleSwitchView(firstView);
    } else {
      handleSwitchView(EMPTY_SAVED_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateView = async (data: SavedViewInput) => {
    try {
      const filterData = currentView?.filterData;

      const newView = await createSavedView({
        ...data,
        filterData,
        url: currentUrl,
      });

      if (newView) {
        toast({
          title: "Erfolg",
          description: "Ansicht gespeichert",
          variant: "success",
        });
      }
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Fehler beim Speichern der Ansicht",
        variant: "error",
      });
    }
  };

  const handleUpdateView = async (data: SavedViewInput) => {
    try {
      if (!editingView) return;

      const updatedView = await updateSavedView(editingView.id, {
        name: data.name,
        isDefault: data.isDefault,
      });

      // Update selected view if we're editing it
      if (currentView?.id === editingView.id && updatedView) {
        handleSwitchView(updatedView);
      }

      toast({
        title: "Erfolg",
        description: "Ansicht aktualisiert",
        variant: "success",
      });
      setEditingView(undefined);
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Fehler beim Aktualisieren der Ansicht",
        variant: "error",
      });
    }
  };

  const handleSaveCurrentView = async () => {
    try {
      await saveCurrentView();
      toast({
        title: "Erfolg",
        description: "Änderungen gespeichert",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Fehler beim Speichern der Änderungen",
        variant: "error",
      });
    }
  };

  const handleResetView = () => {
    resetCurrentView();
    toast({
      title: "Erfolg",
      description: "Ansicht zurückgesetzt",
      variant: "success",
    });
  };

  const handleDeleteView = async (view: SavedView) => {
    try {
      await deleteSavedView(view.id);

      toast({
        title: "Erfolg",
        description: "Ansicht gelöscht",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Fehler beim Löschen der Ansicht",
        variant: "error",
      });
    }
  };

  const handleDuplicateView = async (view: SavedView) => {
    try {
      const newView = await createSavedView({
        name: `${view.name} (Copy)`,
        filterData: view.filterData,
        url: view.url,
        isDefault: false,
      });
      if (newView) {
        toast({
          title: "Erfolg",
          description: "Ansicht dupliziert",
          variant: "success",
        });
        setSubMenuOpen(null);
      }
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Fehler beim Duplizieren der Ansicht",
        variant: "error",
      });
    }
  };

  const handleViewSelect = async (view: SavedView) => {
    try {
      handleSwitchView(view);
      toast({
        title: "Erfolg",
        description: "Ansicht angewendet",
        variant: "success",
      });
    } catch (error) {
      toast({
        title: "Fehler",
        description: "Fehler beim Anwenden der Ansicht",
        variant: "error",
      });
    }
  };

  return (
    <>
      <DropdownMenu.Root
        onOpenChange={(open) => {
          if (!open) {
            setSubMenuOpen(null); // Close submenu when main menu closes
          }
        }}
      >
        <DropdownMenu.Trigger asChild>
          <Button
            variant="outline"
            size="xs"
            className={cn(
              "shadow-sm h-7 rounded-md border px-2 text-xs text-foreground/75",
              isDirty && "border-orange-500",
            )}
          >
            <div className="flex items-center gap-2">
              <Bookmark className={cn("h-4 w-4", isDirty && "text-orange-500")} />
              <span className="max-w-[150px] truncate">{currentView?.name || "Alle Maßnahmen"}</span>
            </div>
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="shadow-lg z-50 w-[280px] rounded-md border bg-white p-2" align="end">
            <div className="mb-2 flex items-center justify-between border-b pb-2">
              <span className="text-sm font-medium">{filteredViews.length} Ansichten</span>
              <Button
                variant="outline"
                size="xs"
                className="h-7"
                onClick={() => {
                  setEditingView(undefined);
                  setSubMenuOpen(null);
                  setOpen(true);
                }}
              >
                <PlusIcon className="mr-1 h-4 w-4" />
                Neue Ansicht
              </Button>
            </div>

            {filteredViews.map((view: SavedView) => (
              <DropdownMenu.Item
                key={view.id}
                className={cn(
                  "group flex cursor-default items-center justify-between rounded px-3 py-2 text-sm hover:bg-gray-100",
                  currentView?.id === view.id && "bg-primary/5",
                )}
                onSelect={() => handleViewSelect(view)}
              >
                <div className="flex items-center gap-2">
                  <Bookmark className="h-4 w-4 text-muted-foreground" />
                  <span>{view.name}</span>
                  {view.isDefault && !view.isBaseView && (
                    <span className="text-xs text-muted-foreground">(Standard)</span>
                  )}
                  {view.isBaseView && <span className="text-xs text-muted-foreground">(Basis)</span>}
                </div>

                <div className="flex items-center gap-1">
                  {isDirty && currentView?.id === view.id && (
                    <>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSaveCurrentView();
                        }}
                        className="rounded p-1 text-orange-500 hover:bg-gray-200"
                        title="Änderungen speichern"
                      >
                        <Save className="h-4 w-4" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleResetView();
                        }}
                        className="rounded p-1 text-orange-500 hover:bg-gray-200"
                        title="Zurücksetzen"
                      >
                        <RotateCcw className="h-4 w-4" />
                      </button>
                    </>
                  )}
                  <Popover.Root
                    open={subMenuOpen === view.id}
                    onOpenChange={(open) => {
                      if (!open) {
                        setSubMenuOpen(null);
                      }
                    }}
                  >
                    <Popover.Trigger asChild>
                      <button
                        className="rounded p-1 hover:bg-gray-200"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSubMenuOpen(view.id);
                        }}
                      >
                        <MoreHorizontal className="h-4 w-4" />
                      </button>
                    </Popover.Trigger>
                    <Popover.Portal>
                      <Popover.Content
                        className="shadow-md z-[100] w-[160px] rounded-md border bg-white p-1"
                        sideOffset={5}
                        side="right"
                        align="start"
                        onInteractOutside={(e) => {
                          // Prevent closing when clicking outside
                          e.preventDefault();
                        }}
                      >
                        <button
                          className="flex w-full items-center gap-2 rounded px-2 py-1.5 text-sm hover:bg-gray-100"
                          onMouseDown={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSubMenuOpen(null);
                            setEditingView(view);
                            setOpen(true);
                          }}
                        >
                          <Edit className="h-4 w-4" />
                          Bearbeiten...
                        </button>
                        <button
                          className="flex w-full items-center gap-2 rounded px-2 py-1.5 text-sm hover:bg-gray-100"
                          onClickCapture={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDuplicateView(view);
                          }}
                        >
                          <Copy className="h-4 w-4" />
                          Duplizieren...
                        </button>
                        {!view.isBaseView && (
                          <>
                            <div className="my-1 h-px bg-gray-200" />
                            <button
                              className="flex w-full items-center gap-2 rounded px-2 py-1.5 text-sm text-red-600 hover:bg-gray-100"
                              onClickCapture={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSubMenuOpen(null);
                                await handleDeleteView(view);
                              }}
                            >
                              <Trash2 className="h-4 w-4" />
                              Löschen...
                            </button>
                          </>
                        )}
                      </Popover.Content>
                    </Popover.Portal>
                  </Popover.Root>
                </div>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      <SaveViewModal
        open={open}
        onOpenChange={(isOpen) => {
          setOpen(isOpen);
          if (!isOpen) {
            setEditingView(undefined);
          }
        }}
        onSave={editingView ? handleUpdateView : handleCreateView}
        initialData={editingView}
      />
    </>
  );
};
