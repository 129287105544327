import { FC, PropsWithChildren, useMemo } from "react";

import { apolloClient } from "@/services/apollo.service/apollo.service";
import { createSignedUploadUrlMutationDocument } from "@/graphql/common";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/_ui/form";
import { useToast } from "@/components/_ui/use-toast";

import LoadingDots from "@/components/loading-dots";

import { UpdatedWorkspace, useWorkspaceGetById, useWorkspaceUpdate } from "@/hooks/api/common";
import { InputImageUpload, UploadUrl } from "@/components/input-image-upload";
import IconUpload from "@/components/_icons/IconUpload";

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Der Name muss mindestens 1 Zeichen lang sein.",
  }),
  workspaceUrl: z
    .string()
    .min(6, {
      message: "Der Workspace ID muss mindestens 6 Zeichen lang sein.",
    })
    .max(24, {
      message: "Der Workspace ID kann höchstens 24 Zeichen lang sein.",
    })
    .refine(
      (value) => /^[a-z0-9-]+$/.test(value),
      "Die Workspace ID darf nur aus kleinen Buchstabe (a-z) und dem Zeichen '-' bestehen.",
    ),
});

export interface WorkspaceEditFormProps extends PropsWithChildren {
  workspace: {
    id: string;
    name: string;
    workspaceUrl: string;
  };
  readOnly?: boolean;
  className?: string;
  onUpdateComplete?: (data: UpdatedWorkspace) => void;
}
export const WorkspaceEditForm: FC<WorkspaceEditFormProps> = ({ className, ...props }) => {
  const { toast } = useToast();
  const { workspace } = useWorkspaceGetById({ id: props.workspace.id });

  const readOnly = useMemo(() => {
    return !!props.readOnly;
  }, [props.readOnly]);

  const logo = useMemo(() => {
    if (!workspace) {
      return null;
    }
    if (!workspace.logo) {
      return null;
    }
    return workspace.logo;
  }, [workspace]);

  const { updateWorkspace, queryResult } = useWorkspaceUpdate({
    workspace: props.workspace,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: props.workspace.name,
      workspaceUrl: props.workspace.workspaceUrl,
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    updateWorkspace({
      variables: {
        input: {
          id: props.workspace.id,
          name: values.name,
          workspaceUrl: values.workspaceUrl,
        },
      },
    });
  }

  async function onUpdateCompleted(updatedWorkspace: UpdatedWorkspace | undefined) {
    if (!updatedWorkspace) {
      return;
    }

    if (props.onUpdateComplete) {
      props.onUpdateComplete(updatedWorkspace);
    }

    toast({
      title: "Gespeichert",
      description: "Der Workspace wurde erfolgreich aktualisert.",
      variant: "success",
    });
  }

  async function onUpdateError(error: Error) {
    if (error.message === "WorkspaceUrl not available") {
      form.setError("workspaceUrl", { message: "Diese Workspace ID ist bereits reserviert oder bereits vergeben." });
      return;
    }

    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  async function getSignedUploadUrlForImage(): Promise<UploadUrl | null> {
    const { data } = await apolloClient.mutate({
      mutation: createSignedUploadUrlMutationDocument,
      fetchPolicy: "no-cache",
      variables: {
        input: {
          fileExtension: "png",
          type: "image/png",
        },
      },
    });

    if (!data) {
      return null;
    }

    return data.createSignedUploadUrl;
  }

  function handleOnImageUploadSuccess(file: UploadUrl) {
    const { permaUrl } = file;

    // 4. Save changes to user profile
    updateWorkspace({
      variables: {
        input: {
          id: props.workspace.id,
          logo: permaUrl,
        },
      },
    });
  }

  function handleOnImageError(_e: Error) {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <Form {...form}>
      <div className="mx-auto mb-5 mt-3 flex w-full flex-col">
        <InputImageUpload
          getSignedUploadUrl={getSignedUploadUrlForImage}
          onSuccess={handleOnImageUploadSuccess}
          onError={handleOnImageError}
          className="rounded-xl"
        >
          <div className="flex h-[118px] w-[118px] flex-row items-center justify-center">
            {!logo && (
              <div className="select-none">
                <IconUpload className="h-6 w-6" />
              </div>
            )}
            {logo && <img src={logo} alt="Workspace Logo" className="h-full w-full select-none" />}
          </div>
        </InputImageUpload>
        {!logo && (
          <div className="my-6 text-xs text-muted-foreground">Wählen sie ein Logo für Ihren Workspace aus.</div>
        )}
      </div>

      <form onSubmit={form.handleSubmit(onSubmit)} className={cn("space-y-6", className)}>
        <FormField
          control={form.control}
          name="name"
          disabled={readOnly}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Workspace Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="workspaceUrl"
          disabled={readOnly}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Workspace ID</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          disabled={queryResult.loading || readOnly}
          className={`${
            queryResult.loading
              ? "cursor-not-allowed bg-muted"
              : "bg-primary text-primary-foreground hover:bg-primary/90"
          } flex h-10 w-32 items-center justify-center rounded-md border text-sm transition-all focus:outline-none`}
        >
          {queryResult.loading ? <LoadingDots color="#808080" /> : <p>Speichern</p>}
        </Button>
      </form>
    </Form>
  );
};
