import { useEffect, useMemo } from "react";

import { apolloClient } from "@/services/apollo.service/apollo.service";

import { useQuery } from "@apollo/client";
import {
  getLabelGroupListForTeamQueryDocument,
  getLabelListForTeamQueryDocument,
  getPriorityListForTeamQueryDocument,
  getStatusListForTeamQueryDocument,
  getUnitListQueryDocument,
  teamMembershipsForTeamQueryDocument,
  teamsForUserInWorkspaceQueryDocument,
} from "@/graphql/common";

export interface UseGetTeamListProps {
  workspaceId?: string;
  userId?: string;
  shouldUpdateCache?: boolean;
}

export const useTeamListForUserInWorkspace = (props: UseGetTeamListProps) => {
  const queryResult = useQuery(teamsForUserInWorkspaceQueryDocument, {
    variables: { input: props.workspaceId ?? "" },
    skip: !props.workspaceId || !props.userId,
  });

  const teamList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }
    return queryResult.data.teamsForUserInWorkspace;
  }, [queryResult.data]);

  const activeTeamList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    return queryResult.data.teamsForUserInWorkspace.filter((t) => {
      return t.teamMembershipList.some((tm) => tm.status === "ACTIVE" && tm.userId === props.userId);
    });
  }, [queryResult.data]);

  useEffect(() => {
    if (!props.shouldUpdateCache) {
      return;
    }

    for (const team of teamList) {
      if (team.statusList) {
        apolloClient.cache.writeQuery({
          query: getStatusListForTeamQueryDocument,
          variables: { input: { teamId: team.id } },
          data: {
            statusListForTeam: team.statusList,
          },
        });
      }
      if (team.priorityList) {
        apolloClient.cache.writeQuery({
          query: getPriorityListForTeamQueryDocument,
          variables: { input: { teamId: team.id } },
          data: {
            priorityListForTeam: team.priorityList,
          },
        });
      }
      if (team.teamMembershipList) {
        apolloClient.cache.writeQuery({
          query: teamMembershipsForTeamQueryDocument,
          variables: { input: team.id },
          data: {
            teamMembershipsForTeam: team.teamMembershipList,
          },
        });
      }
      if (team.unitList) {
        apolloClient.cache.writeQuery({
          query: getUnitListQueryDocument,
          variables: { input: { teamId: team.id } },
          data: {
            units: team.unitList,
          },
        });
      }
      if (team.labelGroupList) {
        apolloClient.cache.writeQuery({
          query: getLabelGroupListForTeamQueryDocument,
          variables: { input: { teamId: team.id } },
          data: {
            labelGroupsForTeam: team.labelGroupList,
          },
        });
      }
      if (team.labelList) {
        apolloClient.cache.writeQuery({
          query: getLabelListForTeamQueryDocument,
          variables: { input: { teamId: team.id } },
          data: {
            labelsForTeam: team.labelList,
          },
        });
      }
    }
  }, [teamList]);

  return {
    queryResult,
    teamList,
    activeTeamList,
  };
};
