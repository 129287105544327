import { graphql } from "../../generated/gql";

export const createUserFavoriteMutationDocument = graphql(/* GraphQL */ `
  mutation CreateUserFavorite($userFavoriteCreateInput: UserFavoriteCreateInput!) {
    createUserFavorite(userFavoriteCreateInput: $userFavoriteCreateInput) {
      id
      pageUrl
      pageName
    }
  }
`);

export const deleteUserFavoriteMutationDocument = graphql(/* GraphQL */ `
  mutation DeleteUserFavorite($userFavoriteDeleteInput: UserFavoriteDeleteInput!) {
    deleteUserFavorite(userFavoriteDeleteInput: $userFavoriteDeleteInput) {
      id
    }
  }
`);

export const renameUserFavoriteMutationDocument = graphql(/* GraphQL */ `
  mutation RenameUserFavorite($userFavoriteRenameInput: UserFavoriteRenameInput!) {
    renameUserFavorite(userFavoriteRenameInput: $userFavoriteRenameInput) {
      id
      pageUrl
      pageName
    }
  }
`);
