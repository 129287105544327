import { apolloClient } from "@/services/apollo.service/apollo.service";

import { groupBy } from "@/lib/utils";

import {
  getAssigneeByOwnerQueryDocument,
  getLabelInstancesQueryDocument,
  getObjectiveQueryDocument,
  getObjectivesQueryDocument,
  getPriorityInstancebyOwnerQueryDocument,
  getStatusInstancebyOwnerQueryDocument,
} from "@/graphql/common";

import { Objective } from "@/components/_domain/objective/models";
import { ApolloCache } from "@apollo/client";

// Note:
//-----------------------------------------
// - Update writes to the cache seem to be expensive.
// - Thus they should be avoided, if retrieval via type policies is available
//
//-----------------------------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateCacheWithNewChildObjective = (cache: ApolloCache<any>, objective: Objective) => {
  if (!objective.parentId) {
    return;
  }

  const cachedQueryResult = cache.readQuery({
    query: getObjectiveQueryDocument,
    variables: { input: { id: objective.parentId } },
  });

  if (!cachedQueryResult) {
    return;
  }

  cache.writeQuery({
    query: getObjectiveQueryDocument,
    variables: { input: { id: objective.parentId } },
    data: {
      objective: { ...cachedQueryResult.objective, children: [...cachedQueryResult.objective.children, objective] },
    },
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateCacheWithNewObjective = (cache: ApolloCache<any>, objective: Objective) => {
  // make sure the parent is updated with the new child
  updateCacheWithNewChildObjective(cache, objective);

  const cachedQueryResult = cache.readQuery({
    query: getObjectivesQueryDocument,
    variables: { input: { teamIdList: [objective.teamId] } },
  });

  if (!cachedQueryResult) {
    return;
  }

  cache.writeQuery({
    query: getObjectivesQueryDocument,
    variables: { input: { teamIdList: [objective.teamId] } },
    data: {
      objectiveList: [...cachedQueryResult.objectiveList, objective],
    },
  });
};

const updateCacheWithDeletedChildObjective = (objective: Pick<Objective, "id" | "parentId">) => {
  if (!objective.parentId) {
    return;
  }

  const cachedQueryResult = apolloClient.cache.readQuery({
    query: getObjectiveQueryDocument,
    variables: { input: { id: objective.parentId } },
  });

  if (!cachedQueryResult) {
    return;
  }

  apolloClient.cache.writeQuery({
    query: getObjectiveQueryDocument,
    variables: { input: { id: objective.parentId } },
    data: {
      objective: {
        ...cachedQueryResult.objective,
        children: cachedQueryResult.objective.children.filter((c) => c.id !== objective.id),
      },
    },
  });
};

export const updateCacheWithDeletedObjective = (objective: Pick<Objective, "id" | "parentId" | "teamId">) => {
  const cachedQueryResult = apolloClient.cache.readQuery({
    query: getObjectivesQueryDocument,
    variables: { input: { teamIdList: [objective.teamId] } },
  });

  if (!cachedQueryResult) {
    return;
  }

  // remove the objective
  const filteredObjectiveList = cachedQueryResult.objectiveList.filter((o) => o.id !== objective.id);
  // remove it from all children
  const filteredObjectiveListWithoutChildren = filteredObjectiveList.map((o) => {
    const filteredChildren = o.children.filter((c) => c.id !== objective.id);
    return { ...o, children: filteredChildren };
  });

  apolloClient.cache.writeQuery({
    query: getObjectivesQueryDocument,
    variables: { input: { teamIdList: [objective.teamId] } },
    data: {
      objectiveList: filteredObjectiveListWithoutChildren,
    },
  });
  updateCacheWithDeletedChildObjective(objective);
};

export const updateCacheFromObjectiveForTeamsQueryResult = (objectiveList: Objective[], teamList: { id: string }[]) => {
  const objectivesByTeamIdMap = groupBy(objectiveList, (objective) => objective.teamId);

  for (const team of teamList) {
    const objectiveListForTeam = objectivesByTeamIdMap.get(team.id);
    if (!objectiveListForTeam) {
      continue;
    }
    apolloClient.cache.writeQuery({
      query: getObjectivesQueryDocument,
      variables: { input: { teamIdList: [team.id] } },
      data: {
        objectiveList: objectiveListForTeam,
      },
    });
  }

  for (const objective of objectiveList) {
    updateCacheFromObjectiveQueryResult(objective);
  }
};

export const updateCacheFromObjectiveQueryResult = (objective: Objective) => {
  apolloClient.cache.writeQuery({
    query: getObjectiveQueryDocument,
    variables: {
      input: { id: objective.id },
    },
    data: {
      objective: objective,
    },
  });

  if (objective.statusInstance) {
    apolloClient.cache.writeQuery({
      query: getStatusInstancebyOwnerQueryDocument,
      variables: {
        input: { ownerId: objective.id },
      },
      data: {
        statusInstanceByOwner: objective.statusInstance,
      },
    });
  }

  if (objective.priorityInstance) {
    apolloClient.cache.writeQuery({
      query: getPriorityInstancebyOwnerQueryDocument,
      variables: {
        input: { ownerId: objective.id },
      },
      data: {
        priorityInstanceByOwner: objective.priorityInstance,
      },
    });
  }

  if (objective.assignee) {
    apolloClient.cache.writeQuery({
      query: getAssigneeByOwnerQueryDocument,
      variables: {
        input: { ownerId: objective.id },
      },
      data: {
        assigneeByOwner: objective.assignee,
      },
    });
  }

  if (objective.labelInstanceList) {
    apolloClient.cache.writeQuery({
      query: getLabelInstancesQueryDocument,
      variables: {
        input: { ownerId: objective.id },
      },
      data: {
        labelInstances: objective.labelInstanceList,
      },
    });
  }
};
