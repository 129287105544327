export const HealthTypes = {
  onTrack: "On Track",
  atRisk: "At Risk",
  offTrack: "Off Track",
} as const;

export const ALLOWED_HEALTH_TYPES = Object.values(HealthTypes);
export type HealthType = (typeof ALLOWED_HEALTH_TYPES)[number];

export const healthTypefromString = (str: string): HealthType | undefined => {
  if (str === HealthTypes.onTrack) {
    return HealthTypes.onTrack;
  }
  if (str === HealthTypes.atRisk) {
    return HealthTypes.atRisk;
  }
  if (str === HealthTypes.offTrack) {
    return HealthTypes.offTrack;
  }
  return undefined;
};

export const sortHealth = (a?: string, b?: string): 0 | 1 | -1 => {
  const numberFromType = (s?: string) => {
    if (s === HealthTypes.offTrack) return 0;
    if (s === HealthTypes.atRisk) return 1;
    if (s === HealthTypes.onTrack) return 2;
    return -1;
  };

  const numA = numberFromType(a);
  const numB = numberFromType(b);

  if (numA - numB > 0) {
    return 1;
  }
  if (numA - numB < 0) {
    return -1;
  }

  return 0;
};
