import { FC, useEffect, useRef, useState } from "react";

import { toast } from "@/components/_ui/use-toast";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { ColorPicker } from "@/components/color-picker";

import { useUpdatePriority } from "@/hooks/api/common/usePriority/useUpdatePriority";

import { PriorityIcon } from "@/components/_domain/priority";

export interface PriorityFormEditProps {
  priority: {
    id: string;
    name: string;
    color: string;
    type: string;
  };
  className?: string;
  onSuccess?: () => void;
  onCanceled?: () => void;
}

export const PriorityFormEdit: FC<PriorityFormEditProps> = ({ priority, onSuccess, onCanceled }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [priorityName, setPriorityName] = useState(priority.name);
  const [priorityColor, setPriorityColor] = useState(priority.color);

  const { updatePriority } = useUpdatePriority({
    priorityId: priority.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleUpdatePriority() {
    if (priorityName === "" || priorityColor === "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Name und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await updatePriority({
      variables: {
        input: {
          id: priority.id,
          name: priorityName,
          color: priorityColor,
          type: priority.type,
        },
      },
    });
  }

  function onUpdateCompleted() {
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler bei der Aktualisierung der Priorität aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className="flex w-full flex-row items-center justify-between gap-2 p-1">
      <div className="w-9">
        <ColorPicker
          asChild
          value={priorityColor}
          onSelect={(color) => {
            setPriorityColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <PriorityIcon className="h-4 w-4" priority={{ type: priority.type, color: priorityColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={priorityName}
          onChange={(e) => setPriorityName(e.currentTarget.value)}
          placeholder="Name der Priorität"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={priorityName === ""}
          onClick={handleUpdatePriority}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default PriorityFormEdit;
