import { setWorkspaceSettings } from "@/services/workspace.service";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

import * as Sentry from "@sentry/react";

type WorkspaceContextType = {
  currentWorkspace: CurrentWorkspaceType;
  setCurrentWorkspace: (newState: CurrentWorkspaceType) => void;
  hasEditRights: boolean;
};

export type CurrentWorkspaceType = {
  id: string;
  name: string;
  workspaceUrl: string;
  logo?: string | null | undefined;
  workspaceMembership: {
    status: string;
    role: string;
  };
};

// This creates a potential for errors, but in the following
// children are only rendered if setting the currentWorkspace succeeds
const initialNullWorkspace = null as unknown as CurrentWorkspaceType;
const initialValue = { currentWorkspace: initialNullWorkspace, setCurrentWorkspace: () => {}, hasEditRights: false };

export interface WorkspaceContextProps extends PropsWithChildren {
  workspace: CurrentWorkspaceType;
}

export const WorkspaceContext = createContext<WorkspaceContextType>(initialValue);

export const WorkspaceProvider = ({ children, workspace }: WorkspaceContextProps) => {
  const [currentWorkspace, setCurrentWorkspace] = useState<CurrentWorkspaceType>(workspace);
  const [hasEditRights, setHasEditRights] = useState(false);

  useEffect(() => {
    setWorkspaceSettings(currentWorkspace);
    setHasEditRights(hasAdminRights());

    if (currentWorkspace) {
      Sentry.setUser({ workspace: currentWorkspace.workspaceUrl });
    }
  }, [currentWorkspace]);

  function hasAdminRights() {
    // make sure in case we have the unititialized workspace state
    if (!currentWorkspace) {
      return false;
    }
    if (!currentWorkspace.workspaceMembership) {
      return false;
    }

    const { status, role } = currentWorkspace.workspaceMembership;
    return status === "ACTIVE" && role === "ADMIN";
  }

  return (
    <WorkspaceContext.Provider value={{ currentWorkspace, setCurrentWorkspace, hasEditRights }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

// Define the useCurrentWorkspace hook
export const useCurrentWorkspace = (): WorkspaceContextType => {
  const context = useContext(WorkspaceContext);

  if (!context) {
    throw new Error("useCurrentWorkspace must be used within a WorkspaceProvider");
  }

  return context;
};

export default WorkspaceProvider;
