export const VisibilityTypes = {
  public: "PUBLIC",
  private: "PRIVATE",
  hidden: "HIDDEN",
} as const;

export const ALLOWED_TEAM_VISIBILITY_TYPES = Object.values(VisibilityTypes);
export type VisibilityType = (typeof ALLOWED_TEAM_VISIBILITY_TYPES)[number];

export const teamVisibilityTypefromString = (str: string): VisibilityType | undefined => {
  if (str === VisibilityTypes.public) {
    return VisibilityTypes.public;
  }
  if (str === VisibilityTypes.private) {
    return VisibilityTypes.private;
  }
  if (str === VisibilityTypes.hidden) {
    return VisibilityTypes.hidden;
  }

  return undefined;
};
