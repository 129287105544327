import { FC, useMemo } from "react";

import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/_ui/hover-card";
import { HealthView, healthTypefromString } from "@/components/_domain/progress-snapshot";

import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

import { cn } from "@/lib/utils";

import { Row } from "@tanstack/react-table";
import { Objective } from "@/components/_domain/objective/models";
import { ProgressSnapshot } from "@/components/_domain/progress-snapshot/progress-snapshots.schema";

interface ObjectiveListCellHealthProps {
  row: Row<Objective>;
  className?: string;
}

export const ObjectiveListCellHealth: FC<ObjectiveListCellHealthProps> = ({ row, className }) => {
  const progressSnapshotList: ProgressSnapshot[] = useMemo(() => {
    return row.original.progressSnapshotList.slice().sort((a, b) => {
      const dateA = new Date(a.snapshotDate);
      const dateB = new Date(b.snapshotDate);

      // Todo: sort them by createdA, if they where created on the same day
      return dateB.getTime() - dateA.getTime();
    });
  }, [row.original.progressSnapshotList]);

  const mostRecentSnapshot: ProgressSnapshot | null = useMemo(() => {
    if (progressSnapshotList.length === 0) {
      return null;
    }
    return progressSnapshotList[0];
  }, [progressSnapshotList]);

  const health = useMemo(() => {
    if (!mostRecentSnapshot) {
      return null;
    }
    return healthTypefromString(mostRecentSnapshot.health);
  }, [mostRecentSnapshot]);

  if (!mostRecentSnapshot) {
    return <></>;
  }

  if (!health) {
    return <></>;
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className={cn(["m-auto w-min"], className)}>
          <HealthView type={health} logoOnly={true} className="h-4 w-4 justify-self-center" />
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="top" className="w-auto p-2">
        <HealthView type={health} logoOnly={false} className="" />
        <div className="pt-1 text-xs text-muted-foreground">
          {`Zuletzt aktualisiert ${formatDistanceToNow(new Date(mostRecentSnapshot.snapshotDate), {
            locale: de,
            addSuffix: true,
          })}`}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};
