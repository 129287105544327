export const StatusTypes = {
  backlog: "Backlog",
  unstarted: "Unstarted",
  started: "Started",
  completed: "Completed",
  canceled: "Canceled",
} as const;

export const ALLOWED_STATUS_TYPES = Object.values(StatusTypes);
export type StatusType = (typeof ALLOWED_STATUS_TYPES)[number];

export const sortStatus = (a?: string, b?: string): 0 | 1 | -1 => {
  const numberFromType = (p?: string) => {
    if (p === StatusTypes.backlog) return 0;
    if (p === StatusTypes.unstarted) return 1;
    if (p === StatusTypes.started) return 2;
    if (p === StatusTypes.completed) return 3;
    if (p === StatusTypes.canceled) return 4;
    return -1;
  };

  const numA = numberFromType(a);
  const numB = numberFromType(b);

  if (numA - numB > 0) {
    return 1;
  }
  if (numA - numB < 0) {
    return -1;
  }

  return 0;
};
