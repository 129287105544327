import { FC, useMemo } from "react";

import { Button } from "@/components/_ui/button";
import IconPlus from "@/components/_icons/IconPlus";

import ObjectiveChildListItem from "./objective-child-list-item";

import { useGetObjective } from "@/hooks/api/common";
import { useObjectiveUIState } from "../../../hooks/useObjectiveUIState";

type ObjectiveProps = {
  id: string;
};

interface ObjectiveChildListProps {
  objective: ObjectiveProps;
}

export const ObjectiveChildList: FC<ObjectiveChildListProps> = (props) => {
  const { objective } = useGetObjective({ id: props.objective.id });
  const { setShowCreateChildObjectiveView } = useObjectiveUIState();

  function showCreateChildObjective() {
    setShowCreateChildObjectiveView(true);
  }

  const childrenObjectives = useMemo(() => {
    if (!objective) {
      return [];
    }
    if (!objective.children) {
      return [];
    }

    return objective.children.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [objective]);

  // if (!(childrenObjectives.length > 0)) {
  //   return <></>;
  // }

  return (
    <>
      <hr className="mx-3 py-3" />
      <div>
        <div className="mx-3 mb-1 flex flex-row items-center justify-between text-sm font-medium text-muted-foreground">
          <span>Teilziele</span>
          <span>
            <Button variant="ghost" size="xs" className="h-7 w-7" onClick={() => showCreateChildObjective()}>
              <IconPlus />
            </Button>
          </span>
        </div>
        <hr className="mx-3 py-3" />
        <div className="mx-3">
          <div className="flex flex-col">
            {childrenObjectives.map((objective) => {
              return <ObjectiveChildListItem key={objective.id} objective={objective} />;
            })}
            {childrenObjectives.length === 0 && (
              <div className="flex h-24 flex-row flex-wrap items-center justify-center text-xs text-muted-foreground">
                Keine Teilziele
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ObjectiveChildList;
