import { useCallback } from "react";
import { NavLink } from "react-router-dom";

import { useCurrentUser } from "@/hooks/api/common";

import { Sidebar, SidebarHeaderElement, SidebarMainContent, SidebarNavElement, SidebarTopContent } from "../sidebar";

import IconInvisible from "../_icons/IconInvisible";
import IconChevronLeft from "../_icons/IconChevronLeft";

export const AdminSidebar = () => {
  const { isSuperUser } = useCurrentUser();

  const makeAdminUrl = useCallback((path: string) => {
    return `/admin/${path}`;
  }, []);

  if (!isSuperUser) {
    return <></>;
  }

  return (
    <Sidebar offsetTop="0.75rem">
      {/* Top Section of the Sidebar */}
      <SidebarTopContent>
        <NavLink to="/" className="select-none text-xl">
          <span className="relative flex h-8 items-center overflow-hidden text-ellipsis rounded pb-0 pl-1.5 pr-0.5 pt-0">
            <IconChevronLeft className="mr-3 h-4 w-4" />
            Admin
          </span>
        </NavLink>
      </SidebarTopContent>

      {/* Middle Section of the Sidebar */}
      <SidebarMainContent>
        {isSuperUser && (
          <>
            <SidebarHeaderElement to={`${makeAdminUrl("")}`}>
              <span className="mr-2 h-4 w-4">⚠️</span>
              Super Admin
            </SidebarHeaderElement>
            <SidebarNavElement to={`${makeAdminUrl("workspaces")}`}>
              <IconInvisible className="mr-2 h-3 w-3" />
              Workspaces
            </SidebarNavElement>
            {/* <SidebarNavElement to={`${makeAdminUrl("users")}`}>
              <IconInvisible className="mr-2 h-3 w-3" />
              Users
            </SidebarNavElement> */}
            {/* <SidebarNavElement to={`${makeAdminUrl("events")}`}>
              <IconInvisible className="mr-2 h-3 w-3" />
              Event Log
            </SidebarNavElement> */}
            <div className="h-4 shrink-0"></div>
          </>
        )}
      </SidebarMainContent>
    </Sidebar>
  );
};
