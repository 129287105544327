import { FC, useCallback, useMemo, useState } from "react";

import { useGetProgressSnapshotList } from "@/hooks/api/common";
import { HealthType, HealthTypes } from "@/components/_domain/progress-snapshot/health-types";
import { HealthSelectDropdown } from "@/components/_domain/progress-snapshot/health-select-dropdown";
import { Button } from "@/components/_ui/button";
import { HealthView } from "@/components/_domain/progress-snapshot/health-view";

export interface ProgressSnapshotHealthInputInputProps {
  objective: {
    id: string;
  };
  value?: HealthType;
  onValueChange?: (value: HealthType | undefined) => void;
}

export const ProgressSnapshotHealthInput: FC<ProgressSnapshotHealthInputInputProps> = ({ onValueChange, ...props }) => {
  const [value, setValue] = useState<HealthType | undefined>(props.value);
  const { mostRecentSnapshot } = useGetProgressSnapshotList({ objectiveId: props.objective.id });

  const fieldWasModified = useCallback(() => {
    if (!mostRecentSnapshot && value === undefined) {
      return false;
    }

    if (!mostRecentSnapshot && value !== undefined) {
      return true;
    }

    if (mostRecentSnapshot && value === undefined) {
      return false;
    }

    return mostRecentSnapshot?.health !== value;
  }, [value]);

  const handleValueChanged = (value: HealthType) => {
    setValue(value);

    // notify the parent component about the change
    if (!onValueChange) {
      return;
    }
    if (!mostRecentSnapshot) {
      onValueChange(value);
      return;
    }

    if (mostRecentSnapshot.health !== value) {
      onValueChange(value);
      return;
    }

    onValueChange(undefined);
  };

  const defaultValue: HealthType = useMemo(() => {
    if (!mostRecentSnapshot) {
      return HealthTypes.onTrack;
    }
    if (mostRecentSnapshot.health === HealthTypes.offTrack) {
      return HealthTypes.offTrack;
    }
    if (mostRecentSnapshot.health === HealthTypes.atRisk) {
      return HealthTypes.atRisk;
    }
    return HealthTypes.onTrack;
  }, [mostRecentSnapshot]);

  const displayValue = useMemo(() => {
    if (value) {
      return value;
    }
    return defaultValue;
  }, [defaultValue, value]);

  return (
    <HealthSelectDropdown defaultValue={defaultValue} onSelect={handleValueChanged} asChild>
      <Button variant={"ghost"} size="xs" className="shadow-sm rounded-md border text-left text-xs font-normal">
        <HealthView type={displayValue} modified={fieldWasModified()} />
      </Button>
    </HealthSelectDropdown>
  );
};

export default ProgressSnapshotHealthInput;
