import { useMemo } from "react";

import { ProgressSnapshot } from "@/components/_domain/progress-snapshot/progress-snapshots.schema";

import { useGetObjective } from "../useObjective";

export interface UseGetProgressSnapshotListProps {
  objectiveId?: string;
}

export const useGetProgressSnapshotList = ({ objectiveId }: UseGetProgressSnapshotListProps) => {
  const { objective, queryResult } = useGetObjective({ id: objectiveId }); // caching optimization

  const progressSnapshotList: ProgressSnapshot[] = useMemo(() => {
    if (!objective) {
      return [];
    }
    return objective.progressSnapshotList.slice().sort((a, b) => {
      const dateA = new Date(a.snapshotDate);
      const dateB = new Date(b.snapshotDate);

      // Todo: sort them by createdA, if they where created on the same day
      return dateB.getTime() - dateA.getTime();
    });
  }, [objective]);

  const mostRecentSnapshot: ProgressSnapshot | null = useMemo(() => {
    if (progressSnapshotList.length === 0) {
      return null;
    }
    return progressSnapshotList[0];
  }, [progressSnapshotList]);

  return {
    queryResult,
    progressSnapshotList,
    mostRecentSnapshot,
  };
};
