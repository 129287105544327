import { FC, useMemo } from "react";

import { FormControl } from "@/components/_ui/form";
import InputMultiple, { Item } from "@/components/input-multiple";
import { Milestone } from "../models/key-result.form.schema";

export interface KeyResultMilestoneInputProps {
  onValueChange?: (value: Milestone[]) => void;
  defaultValue?: Milestone[];
}

export const KeyResultMilestoneInput: FC<KeyResultMilestoneInputProps> = ({ onValueChange, defaultValue }) => {
  const defaultItems = useMemo(() => {
    if (!defaultValue) {
      return undefined;
    }

    const items = defaultValue.map(({ id, ...m }) => {
      return {
        _id: id,
        ...m,
      };
    });

    return items;
  }, [defaultValue]);

  const getOriginalMilestone = (id: string): Milestone | undefined => {
    if (!defaultValue) {
      return undefined;
    }

    return defaultValue.find((m) => m.id === id);
  };

  const handleValueChange = (items: Item[]) => {
    if (onValueChange) {
      const milestones = items.map(({ _id, ...i }) => {
        const originalMilestone = getOriginalMilestone(_id);

        return {
          id: _id,
          completed: originalMilestone ? !!originalMilestone.completed : false,
          ...i,
        };
      });
      onValueChange(milestones);
    }
  };

  return (
    <FormControl>
      <InputMultiple
        newItemPlaceholder="Meilenstein hinzufügen"
        itemPlaceholder="Name des Meilensteins"
        onValueChange={handleValueChange}
        defaultValue={defaultItems}
      />
    </FormControl>
  );
};

export default KeyResultMilestoneInput;
