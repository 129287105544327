import { FC, useMemo } from "react";

import { OwnerType } from "@/components/_domain/labels/models/owner-types";
import { useGetLabelInstanceList } from "@/hooks/api/common";
import { ObjectiveLabelInstanceItem } from "./objective-label-instance-item";
import { CurrentWorkspaceType } from "@/hooks/useCurrentWorkspace";

interface ObjectiveLabelInstanceViewProps {
  owner: {
    id: string;
    type: OwnerType;
  };
  workspace: CurrentWorkspaceType;
  team: {
    id: string;
  };
  user: {
    id: string;
  };
}

export const ObjectiveLabelInstanceView: FC<ObjectiveLabelInstanceViewProps> = ({ workspace, team, owner, user }) => {
  const { labelInstanceList } = useGetLabelInstanceList({ ownerId: owner.id });

  const filteredLabelInstanceList = useMemo(() => {
    return labelInstanceList.filter((li) => !li.label?.labelGroupId);
  }, [labelInstanceList]);

  if (filteredLabelInstanceList.length === 0) {
    return <></>;
  }
  return (
    <div className="mb-5 flex flex-row items-start gap-2">
      <div className="w-24 shrink-0 overflow-hidden text-ellipsis font-medium"></div>
      <div className="flex grow flex-row flex-wrap gap-1">
        {filteredLabelInstanceList.map((labelInstance) => {
          return (
            <ObjectiveLabelInstanceItem
              key={labelInstance.id}
              workspace={workspace}
              team={team}
              user={user}
              owner={owner}
              labelInstance={labelInstance}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ObjectiveLabelInstanceView;
