import { FC, useMemo } from "react";

import { useGetProgressSnapshot } from "@/hooks/api/common";
import { UserHover } from "@/components/_domain/user";
import { HealthView, healthTypefromString } from "@/components/_domain/progress-snapshot";
import { DateHover } from "@/components/date-hover";
import { QuantValueUpdate } from "./quant-value-update";
import { MilestoneValueUpdate } from "./milestone-value-update";

interface ProgressSnapshotChangedPropertiesViewProps {
  workspace: {
    id: string;
  };
  progressSnapshot: {
    id: string;
  };
  hideDate?: boolean;
}

export const ProgressSnapshotChangedPropertiesView: FC<ProgressSnapshotChangedPropertiesViewProps> = ({
  workspace,
  hideDate,
  ...props
}) => {
  const { progressSnapshot, quantitativeProgressSnapshot, milestoneProgressSnapshot } = useGetProgressSnapshot({
    id: props.progressSnapshot.id,
  });

  const health = useMemo(() => {
    if (!progressSnapshot) {
      return null;
    }
    return healthTypefromString(progressSnapshot?.health);
  }, [progressSnapshot]);

  if (!progressSnapshot) {
    return <></>;
  }

  if (!health) {
    return <></>;
  }

  return (
    <div className="flex flex-row flex-wrap items-center gap-1 text-xs text-muted-foreground">
      <UserHover
        user={{ id: progressSnapshot.userId }}
        workspace={workspace}
        type="username"
        className="font-medium text-foreground"
      />
      <span>hat</span>
      {!!progressSnapshot.value && milestoneProgressSnapshot && (
        <>
          <MilestoneValueUpdate progressSnapshot={progressSnapshot} />
          <span className="">Der Projektfortschritt ist als</span>
          <span className="px-0.5 text-foreground">
            <HealthView type={health} className="h-3 w-3" />
          </span>
          <span>markiert.</span>
        </>
      )}

      {!!progressSnapshot.value && !milestoneProgressSnapshot && quantitativeProgressSnapshot && (
        <>
          <QuantValueUpdate progressSnapshot={progressSnapshot} />
          <>und</>

          <span className="">den Projektfortschritt mit</span>
          <span className="px-0.5 text-foreground">
            <HealthView type={health} className="h-3 w-3" />
          </span>
          <span>markiert.</span>
        </>
      )}

      {!progressSnapshot.value && (
        <>
          <span className="">den Projektfortschritt mit</span>
          <span className="px-0.5 text-foreground">
            <HealthView type={health} className="h-3 w-3" />
          </span>
          <span>markiert.</span>
        </>
      )}
      {!hideDate && (
        <>
          <span>·</span>
          <DateHover date={new Date(progressSnapshot.snapshotDate)} />
        </>
      )}
    </div>
  );
};
