import { FC, useMemo, useState } from "react";

import IconParentObjective from "@/components/_icons/IconParentObjective";

import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { Dialog, DialogContent } from "@/components/_ui/dialog";
import { DialogProps } from "@radix-ui/react-dialog";
import { ObjectiveBadge } from "./objective-badge";

import { useMutation } from "@apollo/client";
import {
  getObjectiveQueryDocument,
  getObjectivesQueryDocument,
  updateObjectiveMutationDocument,
} from "@/graphql/common";
import { useGetObjectiveListForTeams } from "@/hooks/api/common";

type ObjectiveProps = {
  id: string;
  teamId: string;
  parentId?: string | null | undefined;
};

interface ObjectiveSetParentDialogProps extends DialogProps {
  objective: ObjectiveProps;
}

export const ObjectiveSetParentDialog: FC<ObjectiveSetParentDialogProps> = ({ objective, ...props }) => {
  const [queryString, setQueryString] = useState("");
  const [parentObjectiveId, setParentObjectiveId] = useState<string | null>();

  const { objectiveList } = useGetObjectiveListForTeams({
    teamList: [{ id: objective.teamId }],
  });

  const filteredObjectiveList = useMemo(() => {
    return objectiveList.slice().filter((o) => {
      return (
        o.id !== objective.id && // not itself
        (!objective.parentId || o.id !== objective.parentId) && // not its current parent
        (queryString === "" || o.title.toLowerCase().indexOf(queryString.toLowerCase()) !== -1) // matching the querystring
        // Todo: we don't really check the tree of parents here. It could be that we end up with a cyclic dependency
      );
    });
  }, [objectiveList, queryString]);

  const [updateObjective] = useMutation(updateObjectiveMutationDocument, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
    refetchQueries: [
      {
        query: getObjectivesQueryDocument,
        variables: { input: { teamIdList: [objective.teamId] } },
      },
      {
        query: getObjectiveQueryDocument,
        variables: { input: { id: objective.id } },
      },
      {
        query: getObjectiveQueryDocument,
        variables: { input: { id: parentObjectiveId ?? objective.id } }, // hack to avoid sending id: null if no parent is set
      },
    ],
  });

  async function handleParentObjectiveSelected({ id }: { id: string }) {
    setParentObjectiveId(id);
    await updateObjective({
      variables: {
        input: {
          id: objective.id,
          parentId: id,
        },
      },
    });
  }

  async function handleRemoveParentObjectiveSelected() {
    setParentObjectiveId(objective.parentId);
    await updateObjective({
      variables: {
        input: {
          id: objective.id,
          parentId: null,
        },
      },
    });
  }

  async function onUpdateCompleted() {
    if (props.onOpenChange) {
      props.onOpenChange(false);
    }
    // toast({
    //     title: "Success",
    //     description:
    //       "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
    //     variant: "success",
    //   });
  }

  async function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  return (
    <Dialog {...props}>
      <DialogContent className="top-[1%] max-w-3xl translate-y-[0%] md:top-[20%]">
        <div className="flex flex-col gap-2">
          {objective.parentId && (
            <div>
              <ObjectiveBadge objective={{ id: objective.parentId }} />
            </div>
          )}
          <div className="mr-4">
            <Input
              value={queryString}
              onChange={(e) => setQueryString(e.currentTarget.value)}
              className="shadow-none border-none pl-0 text-xl font-light outline-none ring-0 focus-visible:border-none focus-visible:ring-0"
              placeholder="Übergeordnetes Ziel setzen..."
              autoFocus
            />
          </div>
          <div className="h-0 w-full border-b"></div>
          {objective.parentId && (
            <div className="flex flex-col">
              <div
                key={objective.id}
                onClick={() => handleRemoveParentObjectiveSelected()}
                className="flex cursor-pointer select-none flex-row items-center rounded-md px-1 py-2 text-sm hover:bg-muted"
              >
                <IconParentObjective className="ml-1 mr-2 h-4 w-4" />
                <span>Übergeordnetes Ziel entfernen</span>
              </div>
              <div>
                <span className="my-2 text-xs font-medium text-muted-foreground">Weitere Ziele: </span>
              </div>
            </div>
          )}
          <div className="flex max-h-[240px] flex-col overflow-y-scroll">
            {filteredObjectiveList.map((objective) => {
              return (
                <div
                  key={objective.id}
                  onClick={() => handleParentObjectiveSelected(objective)}
                  className="flex cursor-pointer select-none flex-row items-center rounded-md px-2 py-2 text-sm hover:bg-muted"
                >
                  <span>{objective.title}</span>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ObjectiveSetParentDialog;
