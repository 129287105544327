import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";

import { cn } from "@/lib/utils";

import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { toast } from "@/components/_ui/use-toast";
import { ColorPicker } from "@/components/color-picker";

import { PriorityType } from "@/components/_domain/priority";
import { PriorityIcon } from "@/components/_domain/priority";
import { useCreatePriority } from "@/hooks/api/common";

export interface PriorityFormCreateProps extends PropsWithChildren {
  team: {
    id: string;
  };
  priorityType: PriorityType;
  onSuccess?: () => void;
  onCanceled?: () => void;
  className?: string;
}

export const PriorityFormCreate: FC<PriorityFormCreateProps> = ({
  team,
  priorityType,
  onSuccess,
  onCanceled,
  className,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [newPriorityName, setNewPriorityName] = useState("");
  const [newPriorityColor, setNewPriorityColor] = useState("#95999F");

  const { createPriority } = useCreatePriority({
    teamId: team.id,
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });

  async function handleCreateNewPriority() {
    if (newPriorityName === "" || newPriorityColor === "") {
      toast({
        title: "Fehler",
        description: "Bitte wählen Sie einen Titel und eine Farbe aus.",
        variant: "error",
      });
      return;
    }

    await createPriority({
      variables: {
        input: {
          teamId: team.id,
          name: newPriorityName,
          color: newPriorityColor,
          type: priorityType,
        },
      },
    });
  }

  function reset() {
    // do not reset the color; the most likely case is that users want to create several objects of the same color
    // setNewPriorityColor("#95999F")
    setNewPriorityName("");
    ref.current?.focus();
  }

  function onUpdateCompleted() {
    reset();
    if (onSuccess) {
      onSuccess();
    }
  }

  function onUpdateError() {
    toast({
      title: "Fehler",
      description:
        "Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.",
      variant: "error",
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      className={cn(["my-1 flex flex-row items-center justify-between gap-2 rounded border bg-muted p-2", className])}
    >
      <div className="w-9">
        <ColorPicker
          asChild
          value={newPriorityColor}
          onSelect={(color) => {
            setNewPriorityColor(color);
          }}
        >
          <Button variant="outline" size="sm">
            <PriorityIcon className="h-4 w-4" priority={{ type: priorityType, color: newPriorityColor }} />
          </Button>
        </ColorPicker>
      </div>
      <div className="grow">
        <Input
          ref={ref}
          autoFocus
          value={newPriorityName}
          onChange={(e) => setNewPriorityName(e.currentTarget.value)}
          placeholder="Name der Priorität"
          className="bg-background ring-0 hover:ring-0 focus:ring-0 active:ring-0"
          style={{
            boxShadow: "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          }}
        />
      </div>
      <div>
        <Button variant="outline" size="sm" className="cursor-pointer" onClick={onCanceled}>
          Abbrechen
        </Button>
      </div>
      <div>
        <Button
          variant="default"
          size="sm"
          className="cursor-pointer"
          disabled={newPriorityName === ""}
          onClick={handleCreateNewPriority}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};

export default PriorityFormCreate;
