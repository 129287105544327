import { FC, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/_ui/popover";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/_ui/dropdown-menu";

import { Cross2Icon } from "@radix-ui/react-icons";

import { useObjectiveListState } from "../../../../hooks";
import {
  FilterByTextListMode,
  FilterByTextListPayload,
  FilterByTextListPayloadSchema,
} from "../../../filter-functions";

import { FilterByLabelConfigurationView } from "./filter-by-label-configuration-view";
import { FilterByLabelDisplayView } from "./filter-by-label-display-view";
import { LabelView } from "@/components/_domain/labels";
import { useGetLabelListForTeamsInWorkspace } from "@/hooks/api/common";

export interface FilterByLabelFilterPaneViewProps {
  column: {
    id: string;
  };
}

export const FilterByLabelFilterPaneView: FC<FilterByLabelFilterPaneViewProps> = ({ ...props }) => {
  const { config, table, columnFilters } = useObjectiveListState();
  const [showSelectItemPopover, setShowSelectItemPopover] = useState(false);

  const { labelListWithoutGroup, getLabelListWithDistinctTitle } = useGetLabelListForTeamsInWorkspace({
    workspaceId: config.workspace.id,
    userId: config.user.id,
    teamList: config.teamList,
  });

  const labelList = useMemo(() => {
    return getLabelListWithDistinctTitle(labelListWithoutGroup);
  }, [labelListWithoutGroup, getLabelListWithDistinctTitle]);

  const column = useMemo(() => {
    return table.getColumn(props.column.id);
  }, [table]);

  const filter = useMemo(() => {
    if (!column) {
      return null;
    }
    if (!column.getCanFilter()) {
      return null;
    }
    const filterValue = column.getFilterValue();
    if (!filterValue) {
      return null;
    }
    return filterValue;
  }, [column, columnFilters]);

  const filterByTextListFilter = useMemo(() => {
    if (!filter) {
      return null;
    }

    const safePayload = FilterByTextListPayloadSchema.safeParse(filter);
    if (!safePayload.success) {
      return null;
    }

    return safePayload.data;
  }, [filter]);

  const filterModes: FilterByTextListMode[] = useMemo(() => {
    if (!filterByTextListFilter) {
      return [];
    }
    return ["included", "not_included"];
  }, [filter]);

  const labelListForActiveFilter = useMemo(() => {
    if (!filterByTextListFilter) {
      return [];
    }

    return filterByTextListFilter.textList.flatMap((title) => {
      const label = labelList.find((l) => l.title === title);

      if (!label) {
        return [];
      }
      return label;
    });
  }, [filterByTextListFilter, labelList]);

  const setFilter = (payload: FilterByTextListPayload) => {
    column?.setFilterValue(payload);
  };

  const clearFilter = () => {
    if (!column) {
      return;
    }
    table.setColumnFilters(columnFilters.slice().filter((filterValue) => filterValue.id !== column.id));
  };

  const setFilterMode = (filterMode: FilterByTextListMode) => {
    if (!filterByTextListFilter) {
      return;
    }
    setFilter({
      ...filterByTextListFilter,
      mode: filterMode,
    });
  };

  if (!column) {
    return <></>;
  }

  if (!filter) {
    return <></>;
  }

  if (filterByTextListFilter) {
    return (
      <div className="flex flex-row flex-nowrap items-center gap-0 whitespace-nowrap rounded-md border p-0 text-xs font-light">
        <div className="flex flex-row items-center gap-1 pl-1 text-xs">
          <FilterByLabelDisplayView />
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
            >
              {filterByTextListFilter.mode === "included" && filterByTextListFilter.textList.length === 1 && <>ist</>}
              {filterByTextListFilter.mode === "included" && filterByTextListFilter.textList.length !== 1 && (
                <>ist Teil von</>
              )}
              {filterByTextListFilter.mode === "not_included" && filterByTextListFilter.textList.length === 1 && (
                <>ist nicht</>
              )}
              {filterByTextListFilter.mode === "not_included" && filterByTextListFilter.textList.length !== 1 && (
                <>ist nicht Teil von</>
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filterModes.map((mode) => {
              return (
                <DropdownMenuItem
                  key={mode}
                  onClick={() => {
                    setFilterMode(mode);
                  }}
                >
                  {mode === "included" && filterByTextListFilter.textList.length === 1 && <>ist</>}
                  {mode === "included" && filterByTextListFilter.textList.length !== 1 && <>ist Teil von</>}
                  {mode === "not_included" && filterByTextListFilter.textList.length === 1 && <>ist nicht</>}
                  {mode === "not_included" && filterByTextListFilter.textList.length !== 1 && <>ist nicht Teil von</>}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>

        <Popover open={showSelectItemPopover} onOpenChange={setShowSelectItemPopover}>
          <PopoverTrigger asChild={true}>
            <Button
              variant="ghost"
              size="xs"
              className="h-6 shrink-0 justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0  focus-visible:ring-offset-0"
            >
              {filterByTextListFilter.textList.length > 1 && (
                <>
                  <div className="mr-1 flex flex-row items-center">
                    {labelListForActiveFilter.map((label) => {
                      return (
                        <LabelView
                          key={label.id}
                          label={label}
                          logoOnly={true}
                          className="-m-0.5 h-3.5 w-3.5 rounded-full bg-background"
                        />
                      );
                    })}
                  </div>
                  {`${filterByTextListFilter.textList.length} Werten`}
                </>
              )}
              {filterByTextListFilter.textList.length === 1 && (
                <>
                  {labelListForActiveFilter.length === 0 && (
                    <>
                      <LabelView label={null} />;
                    </>
                  )}
                  {labelListForActiveFilter.length > 0 && (
                    <>
                      {labelListForActiveFilter.map((label) => {
                        return <LabelView key={label.id} label={label} />;
                      })}
                    </>
                  )}
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0" side={"bottom"} align={"start"}>
            <FilterByLabelConfigurationView
              workspace={config.workspace}
              column={column}
              onFinished={() => {
                setShowSelectItemPopover(false);
              }}
            />
          </PopoverContent>
        </Popover>
        <Button
          variant="ghost"
          size="xs"
          className="h-6 w-full justify-start overflow-x-hidden px-1.5 text-xs font-normal ring-offset-background focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          onClick={() => {
            clearFilter();
          }}
        >
          <Cross2Icon className="h-3.5 w-3.5 text-foreground/60" />
        </Button>
      </div>
    );
  }

  return <></>;
};
