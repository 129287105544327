export const ObjectiveListViewTypes = {
  LIST_VIEW: "list",
  TABLE_VIEW: "table",
  BOARD_VIEW: "board",
} as const;

export const ALLOWED_OBJECTIVE_LIST_VIEW_TYPES = Object.values(ObjectiveListViewTypes);
export type ObjectiveListViewType = (typeof ALLOWED_OBJECTIVE_LIST_VIEW_TYPES)[number];

export const objectiveListViewTypefromString = (str: string): ObjectiveListViewType | undefined => {
  if (str === ObjectiveListViewTypes.LIST_VIEW) {
    return ObjectiveListViewTypes.LIST_VIEW;
  }
  if (str === ObjectiveListViewTypes.TABLE_VIEW) {
    return ObjectiveListViewTypes.TABLE_VIEW;
  }
  if (str === ObjectiveListViewTypes.BOARD_VIEW) {
    return ObjectiveListViewTypes.BOARD_VIEW;
  }
  return undefined;
};
