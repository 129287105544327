import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { Button } from "@/components/_ui/button";
import IconArrowTinyRight from "@/components/_icons/IconArrowTinyRight";
import IconDots from "@/components/_icons/IconDots";
import WorkspaceLabelListItem from "../label/workspace-label-list-item";
import IconCircleGroup from "@/components/_icons/IconCircleGroup";

import WorkspaceLabelCreateForm from "../label/workspace-label-create-form";
import WorspaceLabelGroupActionButton from "./workspace-label-group-action-button";

import WorkspaceLabelGroupEditForm from "./workspace-label-group-edit-form";

import { useGetLabelGroup } from "@/hooks/api/common";
import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";

export interface WorkspaceLabelGroupListItemPropsWithChildren extends PropsWithChildren {
  labelGroup: {
    id: string;
  };
  workspace: {
    id: string;
  };
  className?: string;
}

export const WorkspaceLabelGroupListItem: FC<WorkspaceLabelGroupListItemPropsWithChildren> = ({
  workspace,
  ...props
}) => {
  const { currentWorkspace, hasEditRights } = useCurrentWorkspace();
  const { labelGroup } = useGetLabelGroup({ id: props.labelGroup.id });

  const [showChildren, setShowChildren] = useState(false);
  const [showCreateLabelForm, setShowCreateLabelForm] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const childLabels = useMemo(() => {
    if (!labelGroup) {
      return [];
    }
    return labelGroup.labels.slice().sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a.title.localeCompare(b.title);
    });
  }, [labelGroup]);

  function handleLabelHasBeenAdded() {
    // do nothing – quite likely the user wants to create more than one label per group
  }

  useEffect(() => {
    if (labelGroup?.labels.length === 0) {
      setShowCreateLabelForm(true);
      setShowChildren(true);
    }
  }, [labelGroup]);

  useEffect(() => {
    if (editMode) {
      setShowCreateLabelForm(false);
    }
  }, [editMode]);

  useEffect(() => {
    if (showCreateLabelForm) {
      setEditMode(false);
    }
  }, [showCreateLabelForm]);

  if (!labelGroup) {
    return <></>;
  }

  return (
    <div className="my-1 flex flex-col gap-1 rounded border p-1">
      {!editMode && (
        <div className="flex flex-row items-center justify-between">
          <div className="w-7">
            <Button variant="ghost" size="icon" onClick={() => setShowChildren(!showChildren)}>
              <IconArrowTinyRight className={showChildren ? "rotate-90" : "rotate-0"} />
            </Button>
          </div>
          <div className="w-7">
            <IconCircleGroup className="mx-auto h-3.5 w-3.5" style={{ color: labelGroup.color }} />
          </div>
          <div className="grow">{labelGroup.title}</div>
          <div className="w-8">
            {hasEditRights && (
              <WorspaceLabelGroupActionButton
                labelGroup={labelGroup}
                workspace={workspace}
                handleEditLabelGroup={() => setEditMode(true)}
                handleAddLabelToGroup={() => setShowCreateLabelForm(true)}
                asChild
              >
                <Button variant="ghost" size="icon">
                  <IconDots />
                </Button>
              </WorspaceLabelGroupActionButton>
            )}
          </div>
        </div>
      )}
      {editMode && (
        <WorkspaceLabelGroupEditForm
          labelGroup={labelGroup}
          onCanceled={() => {
            setEditMode(false);
          }}
          onSuccess={() => {
            setEditMode(false);
          }}
        />
      )}
      {showCreateLabelForm && (
        <WorkspaceLabelCreateForm
          workspace={currentWorkspace}
          labelGroupId={labelGroup.id}
          defaultLabelColor={labelGroup.color}
          onCanceled={() => {
            setShowCreateLabelForm(false);
          }}
          onSuccess={() => {
            handleLabelHasBeenAdded();
          }}
          className="pl-1"
        />
      )}
      {showChildren && (
        <div>
          {childLabels &&
            childLabels.map((label) => {
              return label ? (
                <WorkspaceLabelListItem
                  key={label.id}
                  label={label}
                  workspace={currentWorkspace}
                  className="border-none p-0 pr-1"
                />
              ) : (
                <></>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default WorkspaceLabelGroupListItem;
