import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { currentUserQueryDocument } from "@/graphql/common";

// Application Monitoring
import * as Sentry from "@sentry/react";
import { posthogService } from "@/services/posthog.service";

export const useCurrentUser = () => {
  const { data, loading, error, refetch } = useQuery(currentUserQueryDocument);

  const currentUser = useMemo(() => {
    if (!data) {
      return null;
    }

    Sentry.setUser({ email: data.currentUser.email });
    posthogService.identify(
      data.currentUser.id, // Replace 'distinct_id' with your user's unique identifier
      { email: data.currentUser.email, name: data.currentUser.fullname }, // optional: set additional user properties
    );

    return data.currentUser;
  }, [data]);

  const isSuperUser = useMemo(() => {
    if (!data) {
      return null;
    }

    return !!data.currentUser.superUser;
  }, [data]);

  return {
    loading,
    error,
    currentUser,
    isSuperUser,
    refetch,
  };
};
