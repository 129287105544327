import { FC, PropsWithChildren, useRef } from "react";
import { Portal } from "react-portal";

const PRODUCTLANE_ORG_ID = "f518f258-6c1f-425d-8965-f49ffe9bd182";

const style = {
  position: "fixed",
  bottom: "0",
  right: "0",
  height: "100%",
  width: "100%",
  border: "none",
  display: "none",
  zIndex: "9999",
  colorScheme: "auto",
} as React.CSSProperties;

type OpenFeedbackWidgetType = {
  project: {
    name: string;
  };
  email?: string;
};

type ProductlaneWidgetType = {
  openFeedbackWidget?: (options: OpenFeedbackWidgetType) => void;
};

declare global {
  interface Window {
    productlane: ProductlaneWidgetType;
  }
}

export interface ProduclanePropsWithChildren extends PropsWithChildren {
  className?: string;
}

export const ProductlaneWidget: FC<ProduclanePropsWithChildren> = () => {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);

  function iframeDidLoad() {
    window.productlane = new Proxy(
      {},
      {
        get(_, prop) {
          // eslint-disable-next-line
          return (data: any) => action(prop, data);
        },
      },
    );
    const iFrame = iFrameRef?.current;
    if (!iFrame) {
      return;
    }
    window.addEventListener("message", (event) => {
      if (event.source === iFrame.contentWindow) {
        const data = event.data;

        if (!data) {
          return;
        }

        switch (data.op) {
          case "frame:open":
            iFrame.style.width = "100%";
            iFrame.style.height = "100%";
            iFrame.style.display = "block";
            return;
          case "frame:unblock":
            iFrame.style.width = "416px";
            iFrame.style.height = "136px";
            iFrame.style.display = "none";
            return;
          case "frame:close":
            iFrame.style.display = "none";
            return;
        }
      }
    });

    action("init", { organizationId: PRODUCTLANE_ORG_ID });
  }

  // The frame messages sent to the app are called "actions"
  // The frame messages received from the app are called "ops" or "operations"
  // eslint-disable-next-line
  function action(name: any, data: any) {
    const iFrame = iFrameRef?.current;
    if (!iFrame) {
      return;
    }
    if (!iFrame.contentWindow) {
      return;
    }

    iFrame.contentWindow.postMessage({ action: name, data }, "*");
  }

  return (
    <Portal>
      <iframe src="https://cdn.productlane.com" style={style} ref={iFrameRef} onLoad={iframeDidLoad} />
    </Portal>
  );
};

export default ProductlaneWidget;
