import { FC, useEffect, useRef, useState } from "react";
import { Button } from "@/components/_ui/button";
import { Input } from "@/components/_ui/input";
import { Label } from "@/components/_ui/label";
import { Checkbox } from "@/components/_ui/checkbox";

import LoadingDots from "@/components/loading-dots";

import { apolloClient } from "@/services/apollo.service/apollo.service";
import { currentUserQueryDocument, teamDeletionCodeQueryDocument, removeTeamMutationDocument } from "@/graphql/common";
import { superuser_teamListForWorkspaceQueryDocument } from "@/graphql/superuser/queries/team.queries";

interface TeamDeleteFormWithEmail {
  team: {
    id: string;
    workspaceId: string;
    name: string;
  };
  onSuccess?: () => void;
}
export const TeamDeleteFormWithEmail: FC<TeamDeleteFormWithEmail> = ({ team, onSuccess }) => {
  const effectTriggeredRef = useRef(false); // make sure it only runs once

  const [hasRequestedCode, setHasRequestedCode] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [checkBoxIsChecked, setCheckBoxIsChecked] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!effectTriggeredRef.current) {
      effectTriggeredRef.current = true;
      requestTeamDeletionCode();
    }
  }, []);

  function confirmationCodeValueChange(e: React.FormEvent<HTMLInputElement>) {
    const newValue = e.currentTarget.value;
    setConfirmationCode(newValue);
  }

  function toggleCheckBox() {
    setCheckBoxIsChecked(!checkBoxIsChecked);
  }

  async function requestTeamDeletionCode() {
    if (hasRequestedCode) {
      return;
    }

    try {
      setHasRequestedCode(true);
      const { data } = await apolloClient.mutate({
        mutation: teamDeletionCodeQueryDocument,
        fetchPolicy: "no-cache",
        variables: {
          input: {
            id: team.id,
          },
        },
      });

      if (!data) {
        setEmail("Fehler. Etwas ist schiefgegangen.");
        throw new Error("Could not send confirmation code");
      }

      setEmail(data.requestTeamDeletionCode.email);
    } catch (e) {
      setError(
        "Es konnte kein Bestätigungs Code versandt werden. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal oder kontaktieren Sie den unseren Support.",
      );
    }
  }

  async function handleConfirmationButtonPressed() {
    try {
      await apolloClient.mutate({
        mutation: removeTeamMutationDocument,
        fetchPolicy: "no-cache",
        variables: {
          input: confirmationCode,
        },
        refetchQueries: [
          {
            query: superuser_teamListForWorkspaceQueryDocument,
            variables: { input: team.workspaceId },
          },
          {
            query: currentUserQueryDocument,
          },
        ],
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setError(
        "Die Löschung des Teams ist fehlgeschlagen. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal oder kontaktieren Sie den unseren Support.",
      );
    }
  }

  return (
    <div className="p-4 sm:max-w-lg">
      <div>
        <span className="text-base font-normal">Verifizieren Sie die Löschung des Teams</span>
      </div>
      <div className="my-6 h-1 w-full border-b border-solid" />
      <div className="relative max-w-full font-light">
        <p className="mb-4 text-sm text-muted-foreground">
          Wenn Sie sicher sind, dass die den Team <span className="font-mdium">{team.name}</span> löschen wollen, lesen
          Sie bitte unten weiter.
        </p>
        <p className="mb-4 text-sm text-muted-foreground">
          Bitte beachten Sie, dass diese Aktion unwiderruflich ist und in einer kompletten Löschung aller mit dem Team
          assoziierten Daten verbunden ist.
        </p>
        <div className="mb-4 text-sm font-medium text-muted-foreground">
          {email && (
            <div>
              Geben Sie den Bestätigungs-Code ein, den wir an <code className="font-base">{email}</code> gesendet haben.
            </div>
          )}
          {!email && (
            <div>
              <br />
              <LoadingDots />
              <br />
            </div>
          )}
        </div>
        <div>
          <Label htmlFor="confirmation-code" className="mb-10">
            Bestätigungs Code
          </Label>
          <Input
            id="confirmation-code"
            type="text"
            placeholder="Geben Sie den Bestätigungs Code ein"
            onChange={confirmationCodeValueChange}
          />
        </div>
        <div className="mt-6 flex items-start justify-start space-x-2">
          <Checkbox
            id="terms"
            onClick={() => {
              toggleCheckBox();
            }}
          />
          <label
            htmlFor="terms"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Ich verstehe und bestätige, dass die gesamten Daten des Teams unwiderruflich gelöscht werden und möchte
            fortfahren.
          </label>
        </div>
        {error && (
          <div className="mt-6 flex items-start justify-start text-sm font-normal text-destructive">{error}</div>
        )}
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="h-4 w-full border-b border-solid" />
        <div className="flex w-full flex-row justify-center pt-4">
          <Button
            type="button"
            variant="destructive"
            className="flex h-10 w-full items-center justify-center rounded-md border text-sm transition-all hover:bg-destructive/90 focus:outline-none"
            onClick={handleConfirmationButtonPressed}
            disabled={confirmationCode.length === 0 || !checkBoxIsChecked}
          >
            Team Löschen
          </Button>
        </div>
      </div>
    </div>
  );
};
