import { FC, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import Header from "@/components/header";
import { Loading } from "@/components/loading";
import NotFound from "@/routes/NotFound";

import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";
import { useCurrentUser, useGetObjectiveListForTeams, useWorkspaceGetById } from "@/hooks/api/common";

import {
  ObjectiveListStateProvider,
  ObjectiveCreateHandlerProvider,
  ObjectiveListDisplayOptionsStateProvider,
} from "@/components/_domain/objective/hooks";

import { ObjectiveCreateDialog } from "@/components/_domain/objective/objective-create-dialog/objective-create-dialog";
import { ObjectiveListConfigurationView } from "@/components/_domain/objective/objective-list-overview/objective-list-configuration-pane-view";
import { ObjectiveListFilterView } from "@/components/_domain/objective/objective-list-overview/objective-list-filter-pane-view";
import {
  ObjectiveListOverview,
  ObjectiveListQuickSearch,
} from "@/components/_domain/objective/objective-list-overview";
import { TeamView } from "@/components/_domain/team/team-view";
import { useLayout } from "@/hooks/useLayout";
import { useTeamObjectiveListColumnConfiguration } from "./useTeamObjectiveListColumnConfiguration";
import { SavedViewsMenu } from "@/components/SavedViews/SavedViewsMenu";
import { FavoriteButton } from "@/components/SavedViews/FavoriteButton";

interface InnerPageProps {
  workspace: {
    id: string;
  };
  team: {
    id: string;
  };
}

const InnerPage: FC<InnerPageProps> = ({ team, ...props }) => {
  const { sidebarState } = useLayout();
  const { workspace } = useWorkspaceGetById(props.workspace);

  const makeTeamUrl = useCallback(
    (path: string) => {
      if (!workspace) {
        return "";
      }
      if (!team) {
        return "";
      }

      return `/${workspace.workspaceUrl}/teams/${team.id}/${path}`;
    },
    [workspace, team],
  );

  if (!workspace) {
    return <></>;
  }

  return (
    <div className="flex h-screen max-h-screen flex-col overflow-y-hidden">
      <Header className={sidebarState?.isFixed ? "pl-6" : "pl-12"}>
        <div className="flex flex-row items-center gap-2">
          <Link to={makeTeamUrl("")}>
            <TeamView team={team} />
          </Link>
          <div>›</div>
          <div className="flex max-w-[8rem] flex-row items-center overflow-hidden truncate text-ellipsis whitespace-nowrap">
            Ziele
          </div>
          <FavoriteButton pageName="Ziele" />
        </div>
        <div className="flex flex-row items-center gap-2">
          <SavedViewsMenu />
          <ObjectiveCreateDialog workspace={workspace} team={team} />
          <ObjectiveListQuickSearch />
        </div>
      </Header>

      <ObjectiveListConfigurationView />
      <ObjectiveListFilterView />
      <ObjectiveListOverview />
    </div>
  );
};

function AuthenticatedPage({ currentUser }: { currentUser: { id: string } }) {
  const { workspaceUrl, teamId } = useParams();
  const teamList = teamId ? [{ id: teamId }] : [];
  const { currentWorkspace } = useCurrentWorkspace();
  const { objectiveList } = useGetObjectiveListForTeams({ teamList: teamList });
  const tableColumnConfiguration = useTeamObjectiveListColumnConfiguration({
    workspace: currentWorkspace,
    user: currentUser,
    teamList: teamList,
  });

  if (!workspaceUrl) {
    return <NotFound />;
  }
  if (!teamId) {
    return <NotFound />;
  }

  return (
    <ObjectiveListStateProvider
      workspace={currentWorkspace}
      user={currentUser}
      teamList={teamList}
      objectiveList={objectiveList}
      showChildren={false}
      enableExpanding={true}
      config={tableColumnConfiguration}
    >
      <ObjectiveListDisplayOptionsStateProvider>
        <ObjectiveCreateHandlerProvider>
          <InnerPage team={{ id: teamId }} workspace={currentWorkspace} />
        </ObjectiveCreateHandlerProvider>
      </ObjectiveListDisplayOptionsStateProvider>
    </ObjectiveListStateProvider>
  );
}

export default function Page() {
  const { currentUser, loading } = useCurrentUser();

  if (loading) {
    return <Loading />;
  }

  if (!currentUser) {
    return <NotFound />;
  }

  return <AuthenticatedPage currentUser={currentUser} />;
}
