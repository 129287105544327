import { useParams } from "react-router-dom";
import Header from "@/components/header";
import { PageHeader } from "@/components/page-header";

import NotFound from "@/routes/NotFound";
import { useTeamById } from "@/hooks/api/common";

import { StatusTable } from "./status-table";

export default function Page() {
  const { teamId } = useParams();
  const { team } = useTeamById({ id: teamId });

  if (!teamId) {
    return <NotFound />;
  }

  if (!team) {
    return <NotFound />;
  }

  return (
    <main className="relative max-h-screen place-items-stretch overflow-auto">
      <Header className="border-none"></Header>

      <div className="overflow-y-scroll pb-12">
        <div className="relative mx-10 mb-16 mt-0 flex grow items-stretch">
          <div className="box-border flex w-full touch-pan-x touch-pan-y flex-col">
            <PageHeader
              title={team.name}
              subtitle="Verwalten Sie die Liste an Status, die Objekten in Ihrem Team zugeordnet werden können"
            />

            <div className="my-6 w-full border-t border-solid" />

            <div className="max-w-xl text-sm">
              <div>
                <div>
                  <p className="font-light text-muted-foreground">
                    Ordnen Sie Zielen in Ihrem Team einen Status zu, um sie zu organisieren und zu filtern. Die in
                    diesem Abschnitt erstellten Stati sind für alle Mitglieder des Teams verfügbar.
                  </p>
                  <div className="my-8" />
                  <StatusTable team={team} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
