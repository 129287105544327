import { useMemo } from "react";

import { groupBy } from "@/lib/utils";

import { useTeamListForUserInWorkspace } from "../index";

export interface UseGetPriorityListForTeamsInWorkspaceProps {
  workspaceId: string;
  userId: string;
  teamList: { id: string }[];
}

export const useGetPriorityListForTeamsInWorkspace = ({
  workspaceId,
  userId,
  teamList,
}: UseGetPriorityListForTeamsInWorkspaceProps) => {
  const { activeTeamList } = useTeamListForUserInWorkspace({ workspaceId, userId });

  const filteredTeamPriorityList = useMemo(() => {
    return activeTeamList.flatMap((activeTeam) => {
      if (!teamList.find((t) => t.id === activeTeam.id)) {
        return [];
      }
      return activeTeam.priorityList;
    });
  }, [activeTeamList, teamList]);

  const priorityList = useMemo(() => {
    return [...filteredTeamPriorityList];
  }, [filteredTeamPriorityList]);

  const priorityListGroupedByType = useMemo(() => {
    return groupBy(priorityList, (priority) => priority.type);
  }, [priorityList]);

  const priorityListGroupedByName = useMemo(() => {
    return groupBy(priorityList, (priority) => priority.name);
  }, [priorityList]);

  const priorityListOfUniqueNames = useMemo(() => {
    // make sure there is only one priority per unqiue name
    // basically this dedupes the list, if there are multiple priorities with the same name across teams
    return Array.from(priorityListGroupedByName.keys()).flatMap((key) => {
      const priorityGroupList = priorityListGroupedByName.get(key);
      if (!priorityGroupList) {
        return [];
      }
      if (priorityGroupList.length === 0) {
        return [];
      }
      return priorityGroupList[0];
    });
  }, [priorityListGroupedByName]);

  return {
    priorityList,
    priorityListOfUniqueNames,
    priorityListGroupedByType,
    priorityListGroupedByName,
  };
};
